import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Cuantia_Pension extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Cuantía Pensión de Alimentos | Garlaw Abogados</title>
				<meta name="description" content="Cuantía mínima de la pensión de alimentos a favor de los hijos" />
				<link rel="canonical" href="https://www.garlawabogados.com//blog/post-pension-alimentos" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Cuantía Pensión de Alimentos | Garlaw Abogados" />
				<meta property="og:description" content="Cuantía mínima de la pensión de alimentos a favor de los hijos" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-pension-alimentos" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Cuantía mínima de la pensión de alimentos a favor de los hijos" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Cuantía mínima de la pensión de alimentos a favor de los hijos</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Septiembre 26, 2022 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Son muchos los progenitores que acuden a nuestro despacho preguntando cuál es la cuantía mínima de la pensión de alimentos a favor de los hijos. Esta cuestión no es sencilla de responder, pues no existe un mínimo fijado por la ley, sino que son los Tribunales los que vienen marcando las pautas a tener en cuenta a la hora de fijar la misma.</p>
                        <img className="img-fluid img-post" src="/assets/images/post_cuantia_pension.webp" width="100%" height="100%" alt="Post Cuantía Pensión de Alimentos"/>
                        <span className="caption text-muted">Garlaw Abogados. Cuantía Pensión de Alimentos</span>
                        <p>
						La cuantía mínima de la pensión de alimentos a favor de los hijos se considera como la cantidad mínima vital para cubrir los gastos más imprescindibles para la atención y cuidado de los hijos menores. Pero
                        <br/><br/>
                        <span className = "p-bold">¿Qué engloba la pensión de alimentos?</span>
                        <br/><br/>
                        El artículo 142 del Código Civil define los alimentos de la forma siguiente:<span className = "p-curs"> <br/><br/>
                        “Se entiende por alimentos todo lo que es indispensable para el sustento, habitación, vestido y asistencia médica.
                        <br/>
                        Los alimentos comprenden también la educación e instrucción del alimentista mientras sea menor de edad y aún después cuando no haya terminado su formación por causa que no le sea imputable.
                        <br/>
                        Entre los alimentos se incluirán los gastos de embarazo y parto, en cuanto no estén cubiertos de otro modo.”
                        </span>
                        <br/><br/>
                        <span className = "p-bold">¿Cómo se calcula la pensión de alimentos en favor de los hijos?</span>
                        <br/><br/>
                        El artículo 146 del Código Civil establece:<span className = "p-curs"> <br/><br/>
                        “La cuantía de los alimentos será proporcionada al caudal o medios de quien los da y a las necesidades de quien los recibe.”
                        <br/><br/>
                        <span className = "p-bold">Como vemos la ley no fija una cuantía mínima en la pensión de alimentos, sino determina los parámetros a tener en cuenta a la hora de establecer la misma. Por eso son los Tribunales los que se han pronunciado sobre esta cuestión.</span>
                        </span>
                        <br/><br/>
                        El Tribunal Supremo, mediante <span className = "p-bold">SENTENCIA de 22 de julio 2015 </span> respecto del <span className = "p-bold_su">“mínimo vital” de la pensión de alimentos  declara:</span>
                        <br/><br/>
                        <span className = "p-curs">
                        “En torno al mínimo vital esta Sala ha declarado recientemente en sentencia de 2 de marzo de 2015, que lo normal será fijar siempre en supuestos de esta naturaleza un mínimo que contribuya a cubrir los gastos repercutibles más imprescindibles para la atención y cuidado del menor, y <span className = "p-bold_su">admitir sólo con carácter muy excepcional, con criterio restrictivo y temporal, la suspensión de la obligación, pues ante la más mínima presunción de ingresos, cualquiera que sea su origen y circunstancias, se habría de acudir a la solución que se predica como normal, aún a costa de una gran sacrificio del progenitor alimentante.”
						</span></span>
                        <br/><br/>
                        Como vemos la jurisprudencia sólo admite la suspensión de la obligación de pago de la pensión de alimentos a favor de los hijos, con carácter excepcional, restrictivo y temporal. Es por eso por lo que se viene fijando la misma, aunque el progenitor no custodio no tenga trabajo, salvo que se encuentra en una situación de insolvencia o pobreza absoluta. 
                        <br/><br/>
                        Por lo tanto, ante la pregunta insistente de muchos clientes de que actualmente tienen una mala situación económica, falta de trabajo o una situación precaria si se les exonera de pagar la pensión de alimentos, la respuesta es NO, cuestión distinta es que pueda reducirse o mordicarse la cuantía conforme a esa nueva situación. 
                        <br/><br/>
                        En este sentido se ha pronunciado la <span className = "p-bold">Audiencia Provincial de Madrid en SENTENCIA de 10 de enero de 2018: </span>
                        <br/><br/>
                        <span className = "p-curs">“En este caso, el juzgador de instancia establece la suma de 300 euros, es decir a razón de 100 euros por hijo, por considerar que constituye un mínimo para atender a las necesidades básicas, como una obligación inexcusable derivada de la patria potestad, que no es cuestionable, <span className = "p-bold_su">en tanto que el padre tiene capacidad para acceder al mercado laboral, de hecho lleva a cabo trabajos, si bien no de una forma permanente, lo que no le impide atender a las necesidades de los menores</span>, suma con la que muestra su plena conformidad el Ministerio Fiscal, procediendo, al no existir desproporción manifiesta de los indicados parámetros, su confirmación.”</span>
                        <br/><br/>
                        O la <span className = "p-bold">SENTECIA de la Audiencia Provincial de La Coruña de fecha 21 de febrero de 2018:</span>
                        <br/><br/>
                        <span className = "p-curs">
                        “Actualmente existe una jurisprudencia estable en el sentido de que aunque se carezcan de ingresos debe de cubrirse un “mínimo vital” del menor que actualmente cifra una reiterada jurisprudencia de la Audiencia Provincial de A Coruña en la suma de 100 euros.”
                        </span>
                        <br/><br/>
                        En consecuencia, para poder determinar la cuantía mínima habrá que estar a las circunstancias particulares del progenitor que está obligado a su abono y a las necesidades del hijo. <span className = "p-bold_su">No obstante, los Tribunales viene fijándola en torno a una cuantía mínima mensual de 100 o 150€, pero como decimos no hay un mínimo como tal, de forma tasada.
                        </span>
                        <br/><br/>
                        Si todavía te quedan dudas o requieres de un asesoramiento especializado, ponte en contacto con nosotros. Garlaw Abogados te pone las cosas fáciles. Te estamos esperando.
                        </p>
						
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/Tan-KTf9wpw"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:6980186777181433856/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1574422586695589889"><i className="fa-brands fa-x-twitter"></i></a></li>  
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Cuantia_Pension);