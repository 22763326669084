import React from 'react';
import {withRouter}  from "react-router-dom";
import {Link} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs, { init } from 'emailjs-com';
import swal from 'sweetalert';
import MetaTags from 'react-meta-tags';





import '../componentes.css'; 
import 'font-awesome/css/font-awesome.min.css';
 
 
init("user_eXQJueeLmBaDAQ5ixwWKr");
function sendEmail(e){
	e.preventDefault();
emailjs.sendForm('service_j38v419', 'template_6nrsb6n', e.target, 'user_eXQJueeLmBaDAQ5ixwWKr')
.then((result) => {
	console.log(result.text);
  }, (error) => {
	console.log(error.text);
  });
e.target.reset()
swal({
	title: "Gracias por confiar en nosotros",
	text: "Nos pondremos en contacto en un plazo máximo de 48 horas",
	icon: "success",
	button: "Equipo Garlaw",
  });
}


class Contacto extends React.Component {
	
	  componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	  

	render() {
 
		return(
			/* Welcome area*/
			<>
			<MetaTags>
				<title>Contacto | Garlaw Abogados</title>
				<meta name="description" content="En Garlaw Abogados nos adaptamos a ti. Descubre todos los canales que ponemos a tu alcance." />
				<link rel="canonical" href="https://www.garlawabogados.com/contacto" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Contacto | Garlaw Abogados" />
				<meta property="og:description" content="En Garlaw Abogados nos adaptamos a ti. Descubre todos los canales que ponemos a tu alcance." />
				<meta property="og:url" content="https://www.garlawabogados.com/contacto" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="En Garlaw Abogados nos adaptamos a ti. Descubre todos los canales que ponemos a tu alcance." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>
				
				<div className="pcontacta" data-aos="zoom-in" data-aos-delay="100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 welcomeconoce">
								<div className = "welco-conoce">
									<h1>Tu Abogado a un <span>Paso</span></h1>
									<h2> Queremos escucharte<span>&#160;</span></h2>
									<p>En Garlaw Abogados <span>nos adaptamos a ti</span> <br/>Descubre las facilidades que ponemos a tu alcance<br/> Elige la que más se adapte a tus <span>necesidades</span></p>
								</div>
							</div>
							<div className="d-none d-lg-block col-lg-4 welcomeimagenes">
							<img src="/assets/images/Garlaw_laptop.webp" width="100%" height="100%" className="img-fluid-4 mb-4" alt="Garlaw Abogados Ordenador" />
							</div>
						</div>
					</div>		
				</div> 

				<section id="contact_2" className="contact_2">
					<div className="container" data-aos="fade-up">

						<div className="section-title">
						<h2>Reserva tu cita</h2>
						<h3>Nuestras Opciones</h3>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="100">
						<div className="col-lg-3">
							<div className="info-box mb-4">
							<i className="fa fa-phone" aria-hidden="true"></i>
							<h3><a href="tel:621003374"> 621 00 33 74</a></h3>
							<p>Pulsa y llamanos</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="info-box mb-4">
							<i className="fa fa-volume-control-phone" aria-hidden="true" style={{fontSize: "26px",  transform: 'rotate(-40deg)'}}></i>
							<form className="form_group" onSubmit={sendEmail}>
							<input className= "form_field" type="tel" id="telefono" name = "telefono" placeholder="Teléfono" pattern="[0-9]{9}" required/>
							<input className= "form_field" type="text" id="nombre" name="nombre"  placeholder="Nombre"  pattern="[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,48}" required /><br/>
							<input className= "form_field2" type="checkbox" id="defaultCheck1"  required onInvalid={(e)=>{e.target.setCustomValidity('Por favor, lea y acepte la política de privacidad')}} onChange={(e)=>{e.target.setCustomValidity('')}}/><label htmlFor="defaultCheck1" className="form_check_label"><Link to="/politicaprivacidad" style={{color: "#17203D"}} title = "Política de Privacidad">He leído y acepto la Política de Privacidad</Link></label>
							<input className= "enviar" type="submit" value="Enviar" style={{fontSize: "11.35px", padding: '2px 6px'}}/>
							</form>
							<p>¿Te llamamos?</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="info-box mb-4">
							<i className="fa fa-envelope" aria-hidden="true"></i>
							<h3><a href="mailto:info@garlawabogados.com">info@garlawabogados.com</a></h3>
							<p>Mandanos un E-mail</p>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="info-box mb-4">
							<i className="fa fa-whatsapp" aria-hidden="true"></i>
							<h3><a href="https://wa.me/34621003374?text=Hola,%20necesito%20un%20abogado">Escribenos un Whatsapp</a></h3>
							<p>Pulsa y abre chat</p>
							</div>
						</div>
						</div>
					</div>
					<div className="container" data-aos="fade-up">
						<div className="row" data-aos="fade-up" data-aos-delay="100">
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-clock-o" aria-hidden="true"></i>
							<h4>Lunes a Viernes 9:00 a 20:00</h4>
							<p>Horario de Atención</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-check-circle" aria-hidden="true"></i>
							<h4>24 horas</h4>
							<p>Tendrás respuesta en menos de un día</p>
							</div>
						</div>
						</div>
					</div>
    			</section>
				<section className="showcase">
					<div className="container-fluid p-0" data-aos="fade-up">
						<div className="row g-0" data-aos="zoom-out" data-aos-delay="100">
							<div className="col-lg-6 order-lg-2 text-white showcase-img" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/Garlaw_Oficina.webp')"}}></div>
							<div className="col-lg-6 order-lg-1 my-auto showcase-text">
								<h2>¿Nos vemos?</h2> <br/>
								<p className="lead mb-0">Descubre nuestra red de oficinas físicas en <span>Madrid, Segovia y Avila</span>. Contacta y descubre tus posibilidades, nosotros te indicamos como llegar. </p>
								<p className="lead mb-2"><span>Cobertura nacional</span>.</p>
							</div>
						</div>
						<div className="row g-0" data-aos="zoom-out" data-aos-delay="100">
							<div className="col-lg-6 text-white showcase-img" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundImage: "url('/assets/images/Garlaw_Conferencia.webp')"}}></div>
							<div className="col-lg-6  my-auto showcase-text">
								<h2>¿Lo simplificamos?</h2> <br/>
								<p className="lead mb-0">Contamos con herramientas digitales para que en todo momento estes conectado con tu abogado. Resolvemos tus dudas desde cualquier lugar.</p>
								<p className="lead mb-2"><span> Primera Consulta Gratuita</span></p> 
								<p className="lead mb-0">Su primera consulta será <span>gratuita</span> si elige realizarla por videollamada. Desde Garlaw estamos comprometidos con el planeta y fomentamos la <span>sostenibilidad</span>, ayudando a reducir la huella de carbono.</p>
							</div>
						</div>
						<div className="row g-0" data-aos="zoom-out" data-aos-delay="100">
							<div className="col-lg-6 order-lg-2 text-white showcase-img" style={{backgroundRepeat: 'no-repeat', backgroundPosition: 'center',backgroundImage: "url('/assets/images/Garlaw_Redes.webp')"}}></div>
							<div className="col-lg-6 order-lg-1 my-auto showcase-text">
								<h2>¿Conectamos?</h2> <br/>
								<p className="lead mb-1">Siguenos en redes sociales y no te pierdas ninguna novedad en el ambito del derecho.</p>
									<div className="center-text-content">
											<ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>  
											</ul>
									</div>
								<p className="lead mb-2"><span> No olvides subscribirte a nuestro canal de Youtube</span></p> 
							</div>
						</div>
					</div>
        		</section>
			</>
		)
 
	}
 
}
export default withRouter(Contacto);