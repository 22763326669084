import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Smart extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>
			
			<MetaTags>
				<title>Post Smart Contract | Garlaw Abogados</title>
				<meta name="description" content="Los Smart Contract han llegado para quedarse. Descúbrelos en este post." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-smart-contract" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Smart Contract | Garlaw Abogados" />
				<meta property="og:description" content="Los Smart Contract han llegado para quedarse. Descúbrelos en este post." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-smart-contract" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Los Smart Contract han llegado para quedarse. Descúbrelos en este post." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Smart Contract</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Julio 3, 2020 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Una nueva forma de intercambiar valor entre particulares, negocios o instituciones sin intermediarios</p>
                        <img className="img-fluid img-post" src="/assets/images/post_smart.webp" width="100%" height="100%" alt="Post Smart Contract"/>
                        <span className="caption text-muted">Garlaw Abogados. Smart Contract</span>
                        <p>
						La revolución digital al mundo jurídico ha llegado para quedarse. Hoy día nos encontramos sumergido en un proceso de transformación digital contante, en el que el mundo del Derecho no puede ser ajeno al mismo, a pesar del inicial recelo y desconfianza ante un cambio tan abismal.
						<br/><br/>
						Si echamos la vista atrás podemos darnos cuenta de la profunda brecha tecnológica que ha sufrido nuestras vidas, hasta el punto de que hoy día no podemos concebirlas sin estos cambios.
						<br/><br/>
						Esto mismo se ha producido en el mundo del derecho y, en especial, al derecho contractual. Debemos tener en cuenta, que diariamente nos movemos en el ámbito del derecho, en el que se incluyen acciones tan nimias como comprar el pan, y que, en consecuencia, se ha visto igualmente azotado por la ola tecnológica, a pesar de que para nosotros pase de forma imperceptible.
						<br/><br/>
						En este ámbito, y como un ejemplo más de esa transformación tecnológica que estamos viviendo, se encuentra los llamados Smart contract o contratos inteligentes. Pero …
						</p>
						<p className = "p-cur-os">
						¿Qué es un Smart contract?
						</p>
						<p>
						Un Smart contract no es más que un código o protocolo informático que ejecuta y hace cumplir, de forma automática, un contrato, sin necesidad de un intermediario físico.
						<br/><br/>
						Para entender adecuadamente el mismo y las implicaciones que ello supone, debemos de partir de lo que entendemos como un contrato. Un contrato no es más que un acuerdo de voluntades entre dos o más partes por el que se crean o transmiten derechos y obligaciones, pudiendo realizarse de forma escrita y de forma verbal.
						<br/><br/>
						La novedad que introducen los Smart contract es que son capaces de ejecutarse y cumplirse de forma automática y autónoma, sin necesidad de intermediario alguno. Es decir, y a modo sencillo para entenderlo, el código informático del cual depende se basa en reglas lógicas, de manera que, si ocurre una determinada circunstancia, llamémosla x, el propio código la atribuye una determinada acción, llamémosla y, sin necesidad de intermediario de por medio, a diferencia de los contratos tradicionales, los cuales son susceptibles de interpretación. Los Smart contract al tener una dependencia directa del lenguaje de programación no pueden ser susceptibles de interpretación, lo cual implicara una serie de ventajas, pero también de desventajas respecto de los contratos tradiciones, que posteriormente analizaremos.
						</p>
						<p className = "p-cur-os">
						¿Cuál es la tecnología que los hace posible?
						</p>
						<p>
						La llamada tecnología blockchain o cadena de bloques. Esto no es más que un registro único, consensuado y distribuido en varios nodos de una red. De forma sencilla para poder entenderlo, en cada bloque se almacena una cantidad de registros o transacciones válidas con la información referente del bloque, así como la vinculación al anterior y posterior bloque, lo que garantiza la disponibilidad de la información, así, como su carácter inmutable y perpetua y la autenticidad de la misma. 
						<br/><br/>
						Imaginemos un libro contable mayor donde se van registrando todas las entradas y salidas de dinero, cada vez que se produce una entrada nueva, ésta lleva toda la información de todo lo anterior que no se puede modificar y borrar, de manera que esa nueva anotación pasa a formar parte de la cadena, convirtiéndose ellas misma en perpetua, inmutable y veraz, estando cada bloque relacionado los unos con los otros.
						<br/><br/>
						Esta tecnología fue creada para hacer posible la moneda digital, el Bitcoin, no obstante, hoy día se ha visto el potencial que tiene para todo tipo de transacciones, pues permite realizar intercambios de valor de forma segura, ágil y sencilla sin necesidad de intermediario. Pensemos por ejemplo en el Registro de la Propiedad, el blockchain funcionaría de la misma manera, pero en este caso sin necesidad de la figura del Registrador, pues con la propia tecnología ya se estaría garantizando la autenticidad, veracidad de la información que en ella figura. Como pioneros de implantación de esta tecnología en el ámbito registral, nos encontramos el Registro de la Propiedad de Suecia. 
						<br/><br/>
						En un futuro y una vez desarrollado en todo su potencial dicha tecnología podría suponer la desaparición de figuras como los notarios o Registradores de la Propiedad, pues con ello se conseguiría que en poco tiempo se garantice la autenticidad, veracidad de las transacciones.
						</p>
						<p className = "p-cur-os">
						¿Qué usos tiene este tipo de contratos? 
						</p>
						<p>
						Los usos de este tipo de contratos son inimaginables, así por ejemplo podemos mencionar entre otros sectores, el de los seguros. Pensemos en los seguros de cancelación o de retraso en un billete de avión, este tipo de contratos podría ayudar a mejorar el proceso de las reclamaciones, pues el propio contrato si verifica que ha habido un retraso o una cancelación, procedería a ingresar su importe al cliente de forma automática e instantánea. Esto es extrapolable a cualquier póliza de seguros.
						<br/><br/>
						Otro uso que podemos encontrar como ya hemos dicho antes es en el Registro de la Propiedad, o en el de las transacciones.
						<br/><br/>
						Respecto de este último se creó Ethereum, con ello las partes programan las condiciones, firman en conformidad y la tecnología blockchain se encarga de que no se modifique, así como de su ejecución.
						</p>
						<p className = "p-cur-os">
						¿Cuáles son las ventajas de este tipo de contratos?
						</p>
						<p>
						Las principales ventajas que ofrecen estos contratos son la precisión, velocidad y transparencia. Efectivamente, este tipo de contratos recogen con todo detalle cuales son los términos y condiciones del mismo, que es perfectamente conocido por las partes, de manera que no deja margen a la interpretación, y se ejecuta de forma automática. Al utilizar la tecnología blockchain se garantiza una máxima seguridad al respecto y se garantiza una mayor eficiencia, pues no existe errores, una vez que un nuevo bloque accede a esta tecnología es perpetuo e inalterable.
						</p>
						<p className = "p-cur-os">
						¿Y las desventajas? 
						</p>
						<p>
						Las desventajas que encontramos son de considerable importancia, tenemos que recordar que los Smart contract aún se encuentran en una fase de desarrollo, y poco a poco tendrán que ir solventando los problemas que van surgiendo en su aplicación.
						<br/><br/>
						Considero que una de las principales desventajas es la falta de privacidad de la información contenida en los mismos y como adecuamos eso al cumplimiento de la normativa de Protección de datos. Recordemos que en la tecnología blockchain los datos son conocidos, esto choca frontalmente con privacidad de muchos datos en ellos contenidos.
						<br/><br/>
						Pero no sólo existe esta desventaja, sino también en el caso que exista un error en cuanto a su código informático, ¿qué ocurre en ese caso?, ¿cómo solventamos una laguna en lenguaje informático? Recordemos que si existe una información incorrecta que forma parte de los bloques, automáticamente todos ellos serán erróneos. Y peor, aún, nos podemos encontrar con que un hacker legalice una actividad ilegal al conseguir acceder a la misma.
						<br/><br/>
						En consecuencia, estamos en una fase aun muy prematura de este tipo de contratos, que requerirán nuevos desarrollos y una adecuación normativa a este respecto.
						</p>
						<p className = "p-cur-os">
						¿Y que pasa con el futuro?
						</p>
						<p>
						Desde mi opinión, considero que cada vez será mayor la incidencia de este tipo de contratos en el ámbito jurídico, a medida que vayan desarrollándose y adecuándose a la realidad jurídica. <br/> 
						Y ya no sólo en el ámbito jurídico, sino en el político y social, por ejemplo, podemos llegar a encontrarnos que la figura del político como tal desaparezca y sea sustituida por un Smart contract con la distribución de los presupuestos, de manera que la votación de la ciudadanía se limitaría únicamente a qué tipo de Smart contract se aplicaría.
						<br/><br/>
						Esto supone todo un reto para el mundo de la abogacía, que le obliga a dotarse de una formación extraordinaria en materia de lenguaje informático y a colaborar con profesionales tecnológicos, con la finalidad de solventar cuantos problemas surjan en relación a los mimos, así como su articulación en el ámbito normativo.
						</p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>   
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Smart);