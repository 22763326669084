import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Medidas_Discapacidad extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Medidas de Apoyo a las Personas con Discapacidad | Garlaw Abogados</title>
				<meta name="description" content="Medidas de Apoyo a las Personas con Discapacidad" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-medidas-de-apoyo-a-personas-con-discapacidad" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Medidas de Apoyo a las Personas con Discapacidad | Garlaw Abogados" />
				<meta property="og:description" content="Medidas de Apoyo a las Personas con Discapacidad" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-medidas-de-apoyo-a-personas-con-discapacidad" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Medidas de Apoyo a las Personas con Discapacidad" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Medidas de Apoyo a las Personas con Discapacidad</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Octubre 21, 2022 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Con la Ley 8/2021, de 2 de junio, se introduce una importante reforma en la legislación civil y procesal para el apoyo a las personas con discapacidad en el ejercicio de su capacidad jurídica. Esta reforma se introduce con la finalidad de adecuar nuestro ordenamiento jurídico a la Convención Internacional sobre los Derechos de las Personas con Discapacidad de 13 de diciembre de 2006.</p>
                        <img className="img-fluid img-post" src="/assets/images/post_medidas_discapacidad.webp" width="100%" height="100%" alt="Post Medidas de Apoyo a las Personas con Discapacidad"/>
                        <span className="caption text-muted">Garlaw Abogados. Medidas de Apoyo a las Personas con Discapacidad</span>
                        <p>
                        Frente a la sustitución en la toma de decisiones que afectaban a las personas con discapacidad en nuestro ordenamiento jurídico antes de la reforma, se impone ahora el respeto a la voluntad de las mismas, quienes tomarán sus propias decisiones y se les brindará apoyo únicamente cuando de verdad lo necesiten. <span className = "p-bold_su">Por tanto, la finalidad de la reforma es garantizar que las personas con discapacidad puedan ejercer su capacidad jurídica en condiciones de igualdad en todos los aspectos de su vida.</span>
                        <br/><br/>
                        <span className = "p-bold">¿Cuáles son las medidas de apoyo introducidas?</span>
                        <br/><br/>
                        Podemos clasificarlas de la manera siguiente:
                        <br/><br/>
                        <span className = "p-bold">1 Medidas de Apoyo Voluntarias:</span> son las designadas por el propio discapacitado, el elegirá la persona que deberá prestarle apoyo y en que ámbitos. Ejemplo de esta media serían la autotutela o los poderes y mandatos preventivos. La autotutela no es más que la designación de un tutor por uno mismo, se trata de una medida preventiva en previsión de una futura discapacidad o imposibilidad de gobernarse así mismo.
                        <br/><br/>
                        <span className = "p-bold">2 Guarda de Hecho:</span> se trata de una medida informal cuando no existan medidas voluntarias o judiciales. El ejemplo más típico es cuando un familiar se encarga de facto del cuidado del discapacitado. En estos casos, únicamente se necesitará autorización judicial mediante expediente de jurisdicción voluntaria en casos muy concretos, como lo puede ser la venta de un bien del discapacitado que tenga un coste económico elevado. 
                        <br/><br/>
                        <span className = "p-bold">3 Curatela:</span> esta medida únicamente puede establecerse mediante resolución judicial en casos en que la misma sea necesaria de forma continuada. Esta medida será de naturaleza asistencial y solo excepcionalmente se le atribuirán funciones representativas. Podrán establecerse por la autoridad judicial medidas de control en el ejercicio de la misma.
                        <br/><br/>
                        <span className = "p-bold">4 Defensor judicial:</span> esta medida se prevé para aquellas situaciones en las que la medida de apoyo habitual no pueda ejercerse, o cuando exista un conflicto de intereses. 
                        <br/><br/>
                        Esta reforma elimina la tutela, la patria potestad prorrogada y la rehabilitada. Se prima como hemos dicho ante todo, la voluntad y preferencias del discapacitado, por eso la representación del mismo se limita actos muy concretos para los que no tenga capacidad. Igualmente, también se elimina la figura de la prodigalidad (personas que malgastan su caudal con ligereza), pues la misma tiene encaje en la adopción de alguna de las medidas mencionada.
                        <br/><br/>
                        Todas las medidas de apoyo adoptadas judicialmente serán revisadas periódicamente en el plazo de 3 años, o excepcionalmente en el plazo de 6 años.  
                        <br/><br/>
                        <span className = "p-bold"> Pero ¿cuáles son los procedimientos para adoptar estas medidas?</span>
                        <br/><br/>
                        Estas medidas se adoptaran mediante un expediente de jurisdicción voluntaria, siendo competente el Juzgado de Primera Instancia del lugar en que resida la persona que necesite las mismas. Únicamente acudiéremos a la jurisdicción voluntaria cuando se haya formulado oposición en el expediente de jurisdicción voluntaria o este no haya podido resolverse. 
                        <br/><br/>
                        <span className = "p-bold">¿Qué ocurre con las personas que fueron incapacitadas judicialmente bajo la normativa anterior?</span>
                        <br/><br/>
                        Establece la Disposición transitoria quinta de la Ley 8/2021, la revisión de las medidas ya acordadas:
                        <br/><br/>
                        <span className = "p-curs">
                        “Las personas con capacidad modificada judicialmente, los declarados pródigos, los progenitores que ostenten la patria potestad prorrogada o rehabilitada, los tutores, los curadores, los defensores judiciales y los apoderados preventivos podrán solicitar en cualquier momento de la autoridad judicial la revisión de las medidas que se hubiesen establecido con anterioridad a la entrada en vigor de la presente Ley, para adaptarlas a esta.
                        <br/><br/>
                        La revisión de las medidas deberá producirse en el plazo máximo de un año desde dicha solicitud.
                        <br/><br/>
                        Para aquellos casos donde no haya existido la solicitud mencionada en el párrafo anterior, la revisión se realizará por parte de la autoridad judicial de oficio o a instancia del Ministerio Fiscal en un plazo máximo de tres años.”</span>
                        <br/><br/>
                        Como la figura de la incapacitación judicial ha desparecido, resulta lógico que cuando hubiere sido adoptada conforme a la normativa anterior se revise y se adapte a las nuevas medidas, pues de lo contrario no se respetaría la voluntad y preferencias de la personas con discapacidad.
                        <br/><br/>
                        Desde Garlaw Abogados te ofrecemos asesoramiento especializado en todo este proceso, por lo que si todavía tienes dudas o requieres de un asesoramiento especializado, ponte en contacto con nosotros. Te estamos esperando.
                        </p>
						
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            <li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/xHBarBXrCZM"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:6989130415198375936/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1583366567244374016"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Medidas_Discapacidad);