import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Contratos_Arras extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Contrartos de Arras | Garlaw Abogados</title>
				<meta name="description" content="Contrato de Arras: ¿qué es y qué debemos saber?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-contratos-arras" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Contrartos de Arras | Garlaw Abogados" />
				<meta property="og:description" content="Contrato de Arras: ¿qué es y qué debemos saber?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-contratos-arras" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Contrato de Arras: ¿qué es y qué debemos saber?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Contrato de Arras: ¿qué es y qué debemos saber?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Junio 12, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Seis de cada diez propietarios desconocen las cuestiones más importantes de este contrato pese a la importancia del mismo. Si estás pensando en comprar o vender una vivienda, no te pierdas este artículo donde aclararemos todas tus dudas al respecto.
                        <br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_contratos_arras.webp" width="100%" height="100%" alt="Post Contratos de Arras"/>
                        <span className="caption text-muted">Garlaw Abogados. Contratos de Arras</span>
                        <p>
                        Desde Garlaw Abogados siempre aconsejamos contar con un buen asesoramiento legal a la hora de la compraventa de una vivienda, tanto si eres la parte vendedora, como si eres la parte compradora. Conocer las cuestiones principales del mismo y su alcance legal es de suma importancia para evitar problemas futuros. 
                        <br/><br/>
                        <span className = "p-bold">¿Qué es un contrato de arras?</span>
                        <br/><br/>
                        Es un acuerdo privado entre el comprador y el vendedor donde se formaliza el compromiso previo de adquisición de un bien o un inmueble. 
                        <br/><br/>
                        <span className = "p-bold">¿Qué información debe incluir?</span>
                        <br/><br/>
                        En el mismo se debe indicar:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Lo datos personales de cada una de las partes (nombre, apellidos, DNI, domicilio, etc)
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Identificación del inmueble. Es importante que si el mismo tiene cargas, se especifique en el contrato de arras.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	El precio por el que se firmará la compraventa y su forma y plazos de abono.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	El importe de la señal del contrato de arras y el tipo de la misma.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	El plazo máximo en que el contrato privado o la escritura pública debe firmarse.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Firmas de ambas partes.
                        <br/><br/>
                        <span className = "p-bold">¿Qué clase de contrato de arras existen?</span>
                        <br/><br/>
                        Podemos distinguir tres tipos:
                        <br/><br/>
                        - <span className = "p-subr">Arras Confirmatorias:</span> la entrega de dinero se efectúa como un pago anticipado del precio de la compraventa, entendiéndose ésta última realizada. Si una de ellas incumple, la otra podrá exigir su cumplimiento o solicitar la resolución del contrato con indemnización de daños y perjuicios.
                        <br/><br/>
                        - <span className = "p-subr">Arras Penitenciales:</span> Suelen ser las más habituales. La entrega de dinero se realiza como garantía del cumplimiento, pero puede desistirse del contrato, a diferencia de las anteriores. Si es el comprador quién no cumple, pierde el dinero dado en garantía, si es el vendedor, deberá devolver duplicado el mismo.
                        <br/><br/>
                        Debemos tener en cuenta a este respecto, lo que dice el Tribunal Supremo:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Este tipo de arras tiene carácter excepcional
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Y es necesario que conste expresamente en el contrato que este es el tipo de arras y la voluntad inequívoca de las partes, así como la consecuencia de la pérdida o restitución doblada.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	En el caso de que existan dudas y no se cumplan estos requisitos se entienden que son confirmatorias, con las consecuencias que ello implica tal y como hemos comentado.
                        <br/><br/>
                        - <span className = "p-subr">Arras Penales:</span> No suelen ser muy comunes y se equiparan a las obligaciones con cláusula penal. De manera que las arras se configuran como un importe de la pena, así si la parte compradora no cumple, perderá las arras, si es la parte vendedora la que no cumple, deberá devolverlas junto con la cantidad que se acuerde en el contrato.
                        <br/><br/>
                        <span className = "p-bold">¿Qué plazo tiene el contrato de arras?</span>
                        <br/><br/>
                        El plazo máximo de validez será el que libremente determinen las partes, salvo en Cataluña, que su plazo máximo es de 6 meses. 
                        <br/><br/>
                        Si vence el contrato, las consecuencias dependerán del tipo de arras que se hayan pactado, tal y como hemos indicado anteriormente. No obstante, cabe la posibilidad de prorrogar el mismo, siempre que ambas partes estén de acuerdo, para ello deberá firmarse un anexo al documento principal.
                        <br/><br/>
                        Normalmente, el plazo que se suele indicar es de tres a seis meses, si el plazo es excesivamente largo, se puede declarar nulo por dejar la decisión de la celebración del contrato al comprador. Por lo tanto, desde Garlaw Abogados aconsejamos establecer un plazo prudencial de 3 a 6 meses, que permita realizar todas las gestiones necesarias para una compraventa, como puede ser la solicitud de hipoteca, pero tampoco se alargue demasiado en el tiempo.
                        <br/><br/>
                        <span className = "p-bold">¿Cuál es el importe de las arras?</span>
                        <br/><br/>
                        De nuevo será el que libremente determinen las partes. Normalmente suele oscilar entorno a un 10 por ciento del precio de la compraventa. 
                        <br/><br/>
                        <span className = "p-bold">¿Qué pasa si a la parte compradora no la conceden la hipoteca?</span>
                        <br/><br/>
                        En el caso de que al comprador no le concedan la hipoteca, se entiende que ha incumplido el contrato de arras, salvo que las mismas estuvieran condicionadas a esta circunstancia. Es decir, salvo que en el contrato de arras se hubiere pactado expresamente que el mismo está condicionado a la concesión de la hipoteca a la parte compradora. De manera que, si no se concede esta, el contrato queda sin efecto, no pudiendo la parte vendedora reclamar nada y deberá devolver su importe. 
                        <br/><br/>
                        Como se puede observar el contrato de arras adquiere suma importancia en el  proceso de compraventa de una vivienda, pudiendo producirse muchos problemas si el mismo no está bien redactado conforme a las circunstancias de la operación. En Garlaw Abogados somos especialistas en derecho inmobiliario y estamos a tu disposición para resolver cualquier duda al respecto, no dudes en ponerte en contacto con nosotros.
                        </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/-fzbkSq_Uvs"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7073992238153818113/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/648914700612346"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1668227340894105602"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Contratos_Arras);