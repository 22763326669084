import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Precario extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>¿Pueden mis padres echar a mi expareja de su piso?</title>
				<meta name="description" content="¿Pueden mis padres echar a mi expareja de su piso?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-precario" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="¿Pueden mis padres echar a mi expareja de su piso?" />
				<meta property="og:description" content="¿Pueden mis padres echar a mi expareja de su piso?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-precario" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Pueden mis padres echar a mi expareja de su piso?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Pueden mis padres echar a mi expareja de su piso?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Mayo 22, 2024 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Es muy frecuente en la práctica que la vivienda familiar sea propiedad de los padres de unos de los cónyuges, pero ¿qué pasa cuando en el divorcio se atribuye el uso de la vivienda familiar al otro cónyuge y los hijos? ¿pueden los padres del cónyuge al que no se le ha atribuido el uso, propietarios de la vivienda, echarla? 
                        <br/><br/><br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_precario.webp" width="100%" height="100%" alt="¿Pueden mis padres echar a mi expareja de su piso?"/>
                        <span className="caption text-muted">Garlaw Abogados ¿Pueden mis padres echar a mi expareja de su piso?</span>
                        <p>
                        En este artículo vamos a abordar el problema con el que pueden encontrarse muchas personas, que, siendo titulares del derecho de uso sobre la vivienda familiar en virtud de Sentencia, tengan que abandonar el domicilio por pertenecer a los padres de su expareja.
                        <br/><br/>
                        Ante esta circunstancia, que como decimos es más que frecuente, <span className = "p-bold_su">los titulares de la vivienda pueden ejercer la acción de desahucio por precario</span>. Pero
                        <br/><br/>
                        <span className = "p-bold">¿qué es un precario?</span>
                        <br/><br/>
                        Se puede definir como la situación de hecho que implica la utilización gratuita de un bien ajeno, sin título para ello, basado en la mera tolerancia. Estaremos frente a un precario, cuando el dueño, beneficiario o cualquier otra persona con derecho a poseer dicha propiedad, cede el uso de la vivienda a otra persona para que la use sin más, sin contrato alguno y sin pagar renta.
                        <br/><br/>
                        No existe una definición como tal en nuestro ordenamiento jurídico del precario, ha sido la jurisprudencia quien ha ido conformando la misma. 
                        <br/><br/>
                        <span className = "p-bold">¿qué requisitos deben reunirse para poder ejercer la acción de desahucio por precario?</span>
                        <br/><br/>
                        -	Que el demandante tenga la posesión mediata de la finca como propietario, usufructuario o por cualquier otro título que le dé derecho a disfrutarla.
                        <br/>
                        -	Que el demandado tenga la posesión inmediata del inmueble sin título legitimador y sin pagar renta o merced.
                        <br/><br/>
                        El <span className = "p-bold_su">Tribunal Supremo mediante Sentencia de 18 de enero de 2010 </span>ha establecido que las consecuencias del divorcio de una pareja no pueden afectar a los derechos de terceros sobre los bienes inmuebles, en este caso, los padres como propietarios de la vivienda:
                        <br/><br/>
                        <span className = "p-curs">“Cuando se trate de terceros propietarios que han cedido el inmueble por razón del matrimonio, salvo que exista un contrato que legitime el uso de la vivienda, la relación entre los cónyuges y el propietario es la de un precario. Debe enfocarse el tema desde el punto de vista del derecho de propiedad y no del derecho de familia, porque las consecuencias del divorcio/separación no tienen que ver con los terceros propietarios.
                        <br/><br/>
                        Cuando el tercero propietario haya cedido el uso de forma totalmente gratuita y de favor al usuario de la vivienda, producida la crisis matrimonial y atribuido el uso al otro cónyuge, el propietario ostenta la acción de desahucio porque existe un precario.”</span>
                        <br/><br/>
                        En este mismo sentido ha resuelto la <span className = "p-bold_su">Audiencia Provincial de Madrid, mediante Sentencia del 13 de diciembre de 2018</span>, literalmente:
                        <br/><br/>
                        <span className = "p-curs">
                        “En cuanto a este tipo de situaciones, como es la cesión de una vivienda por un tercero, bien para que constituya el domicilio conyugal del matrimonio, es doctrina legal, que es aplicable a los supuestos en los que exista una convivencia «more uxorio» (pareja de hecho), que cuando se trate de terceros propietarios que han cedido el inmueble por razón del matrimonio, salvo que exista un contrato que legitime el uso de la vivienda, la relación entre los cónyuges (o pareja de hecho) y el propietario es la de un precario”.
                        </span>
                        <br/><br/>
                        En consecuencia, salvo que exista un contrato que lo legitime, como puede ser de comodato o arrendamiento, nos encontramos ante un precario, basado en la mera tolerancia del titular del inmueble, el cual podrá instar el desahucio aunque existe una sentencia que le atribuya el uso de la mencionada vivienda, ya que la mencionada sentencia no puede dar más derechos de los que existían antes en relación con terceras personas. 
                        <br/><br/>
                        Si aún te quedan dudas al respecto o requieres de un asesoramiento especializado, no dudes en ponerte en contacto con nosotros,  estaremos encantado de ayudarte. Y recuerda  Garlaw Abogados te pone las cosas fáciles. Te estamos esperando.

                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/poQgx1p-6rQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7199046604434108416/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos?video_upload_notif=1&notif_id=1716387674142892&notif_t=video_processed&ref=notif"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://x.com/garlawabogados/status/1793287695327715723"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Precario);