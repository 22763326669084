import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Herederos_Menores extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Heredero Menor de Edad | Garlaw Abogados</title>
				<meta name="description" content="¿Qué ocurre cuando los herederos son menores de edad?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-heredero-menor-de-edad" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Heredero Menor de Edad | Garlaw Abogados" />
				<meta property="og:description" content="¿Qué ocurre cuando los herederos son menores de edad?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-heredero-menor-de-edad" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Qué ocurre cuando los herederos son menores de edad?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Qué ocurre cuando los herederos son menores de edad?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Julio 3, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Cuando se produce el fallecimiento de una persona, puede ocurrir que concurran a su herencia menores de edad. Pero ¿qué debemos saber en estos casos? 
                        <br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_heredero_menor.webp" width="100%" height="100%" alt="Post Heredero Menor de Edad"/>
                        <span className="caption text-muted">Garlaw Abogados. Heredero Menor de Edad</span>
                        <p>
                        En primer lugar, es necesario poner de manifiesto que siempre es recomendable que exista un testamento para evitar que el menor quede desprotegido, pues en el mismo no sólo podrá indicarse el reparto de los bienes sino también la designación de un tutor para el menor. Por eso desde Garlaw Abogados siempre aconsejamos realizar testamento, pese a la reticencia de muchas personas, es un trámite rápido y poco costoso que evita muchos quebraderos de cabeza a sus futuros herederos. 
                        <br/><br/>
                        Una vez apuntado esto, procedemos a explicar que ocurre cuando en la herencia concurren menores de edad. En este caso, será necesario que el menor sea representado por otra persona que supla su falta de capacidad. 
                        <br/><br/>
                        Pero <span className = "p-bold">¿quién representa al menor?
                        <br/>
                        La regla general es que serán los padres quienes representen a su hijo menor de edad, siempre que vivan ambos. </span>
                        <br/>
                        Esta es la regla general, pero ahora veremos las distintas situación y vicisitudes que pueden darse y cuál es el cauce legal a ellas. 
                        <br/><br/>
                        <span className = "p-bold">¿Quién acepta la herencia en nombre del menor?</span>
                        <br/>
                        Corresponde realizarla a sus padres y SIEMPRE A BENEFICIO DE INVENTARIO. Se entiende que la aceptación de la herencia es un acto menos lesivo que su renuncia por eso no exige autorización judicial, pero solo puede hacerse a beneficio de inventario para no perjudicar al menor.
                        <br/><br/>
                        Pero <span className = "p-bold">¿qué es el beneficio de inventario?</span>
                        <br/>
                        La aceptación a beneficio de inventario se trata de una figura especialmente concebida para en el caso de que existan deudas u obligaciones en la herencia, impidiendo que esto pueda impactar negativamente en el patrimonio del heredero, pues solo responderá con los bienes de la herencia y no con su propio patrimonio.
                        <br/><br/>
                        <span className = "p-bold">¿Qué pasa con la renuncia a la herencia?</span>
                        <br/>
                        En este caso los padres deberán solicitar autorización judicial según dispone el artículo 166 del Código Civil. Para tramitar dicha autorización judicial será necesario contar con abogado y procurador siempre que la cuantía del haber hereditario sea superior a 6.000€. Será necesaria la intervención del Ministerio Fiscal tal y como exige el artículo 94 de la Ley de Jurisdicción Voluntaria 15/2015.
                        <br/>
                        No obstante, no será necesario dicha autorización si el menor hubiese cumplido 16 años y consintiere en documento público.
                        <br/><br/>
                        <span className = "p-bold">¿Qué ocurre si fallecen ambos progenitores?</span>
                        <br/>
                        En este caso será necesario nombrar un tutor legal del menor. Como ya hemos indicado si los padres hubieren realizado testamento, en el mismo podrán haber designado la persona que asuma el cargo de tutor, en su defecto será la que establezca el orden del Código Civil. En este último caso, el tutor deberá tramitar la declaración de herederos ab intestato, de ahí la importancia de tener testamento.
                        <br/><br/>
                        <span className = "p-bold">¿Y si fallece un progenitor y estos estaban divorciados?</span>
                        <br/>
                        En este caso la representación recaerá en el otro progenitor, salvo que en el testamento se hubiere designado como tutor a otra persona, en cuyo caso será la misma.
                        <br/><br/>
                        <span className = "p-bold">¿Y si fallece un progenitor y el otro progenitor también está llamado a la herencia?</span>
                        <br/>
                        En estos casos existe un conflicto de intereses, ante esta circunstancia el legislador prevé la figura del defensor judicial. En este caso no será preceptiva la intervención de abogado y procurador para su nombramiento (artículo 28 de la Ley de Jurisdicción Voluntaria).
                        <br/><br/>
                        <span className = "p-bold">¿Y qué ocurre cuando el menor alcanza la mayoría de edad?</span>
                        <br/>
                        El mismo ya podrá tomar sus propias decisiones y exigir una rendición de cuentas si estima que el representante no actuó de forma correcta.
                        <br/><br/>
                        Como podemos observar son numeras las casuísticas que nos podemos plantear ante la concurrencia de un menor de edad en la herencia de una persona fallecida. Por eso, desde Garlaw Abogados siempre recomendamos contar con un asesoramiento legal especializado en sucesiones y herencias desde le principio. Si tienes estas u otras dudas acerca de la herencia de algún familiar o incluso de la tuya propia, no dudes en ponerte en contacto con nosotros, te estamos esperando.
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/j12d7UFsVgs"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7081913940485505025/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/227067570219299"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1676148410519257088"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Herederos_Menores);