import React  from 'react';
import { withRouter } from 'react-router-dom';

import '../componentes.css'; 
 
 
class PoliticaPrivacidad extends React.Component {
    
	render() {
		return(
                    <section className="section" id="about2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="left-text-content">
                                        <div className="section-heading">
                                            <h6> <strong>Política de Privacidad y Protección de Datos</strong></h6>
                                        </div>
                                        <br/><br/>
                                        <h6> <strong>¿Quién es el Responsable del tratamiento de sus datos?</strong></h6>
                                        <p><br/>
                                        Identidad: <strong>Sandra García García</strong><br/>
                                        CIF:<strong> 70259214X</strong><br/>
                                        Dirección postal:<strong> Plaza Ribadeo Nº 2,2-1., CP 28029 Madrid</strong><br/>
                                        Teléfono:<strong>  621 00 33 74</strong> <br/>
                                        Correo electrónico:<strong> info@garlawabogados.com</strong><br/>
                                        Responsable de Seguridad o Delegado de Protección de Datos en su caso:<strong> Sandra García García </strong><br/>
                                        </p>
                                        <h6> <strong>¿Con qué finalidad tratamos sus datos personales?</strong></h6>
                                        <p><br/>
                                        En cumplimiento con lo dispuesto en la normativa vigente en materia de protección de datos, Sandra García García, te informa de que únicamente tratará tus datos personales en la medida en que sean adecuados, pertinentes y limitados a lo estrictamente necesario para los fines para los que son recabados.
                                        <br/>
                                        En este sentido, podrás facilitar libremente tus datos personales a Sandra García García a través de los distintos procedimientos establecidos para ello, que los tratará para las siguientes finalidades siempre y cuando el Usuario haya prestado su consentimiento de forma específica, informada e inequívoca:
                                        <br/>
                                        <strong>1.</strong>    Cumplir con el deber de información y obtención de consentimientos requeridos por la nueva normativa europea de protección de datos de carácter personal.
                                        <br/>
                                        <strong>2.</strong>    Comunicaciones entre la empresa y sus clientes y gestiones derivadas de los servicios de asesoramiento legal y tramitación de procedimiento judiciales contratados y/o realizados, así como para la tramitación de recursos sobre infracciones administrativas de toda índole, así como de facturación.
                                        <br/>
                                        <strong>3.</strong>    El envío de comunicaciones comerciales de nuestros productos o servicios, salvo que usted manifieste su voluntad en contra por cualquier medio. En cualquier caso, la autorización para tratar sus datos con este fin es voluntaria y su negativa sólo tendría como consecuencia el hecho de que usted no recibiría ofertas comerciales de nuestros productos o servicios.
                                        <br/>
                                        <strong>4.</strong>     Valoración de candidatos de para la cobertura de puestos: tras habernos enviado su candidatura espontánea, o bien, haberse suscrito a una oferta de empleo.
                                        <br/>
                                        <strong>5.</strong>     Elaboración de estadísticas internas: de forma anónima, recopilaremos la información relativa a la forma en que los usuarios nos envían sus consultas (fecha, hora, medio de adquisición), así como sobre la naturaleza de la misma.
                                        <br/>
                                        <strong>6.</strong>    Cumplimiento de las obligaciones legales.
                                        <br/>
                                        </p>
                                        <h6> <strong>Legitimación para el tratamiento de sus datos:</strong></h6>
                                        <p><br/>
                                        La base de legitimación para el tratamiento de tus datos podrá consistir en:
                                        <br/>
                                        -    La existencia de una relación contractual, que tratará tus datos personales para la ejecución del encargo profesional.
                                        <br/>
                                        -    El cumplimiento de una obligación legal aplicable al despacho.
                                        <br/>
                                        -    Tu consentimiento prestado de forma libre, específica, informada e inequívoca. Podrás retirar el consentimiento en cualquier momento, a través de los medios que dispone el despacho para ello.
                                        <br/><br/>
                                        Los usuarios deberán garantizar que los datos personales aportados sean verdaderos, exactos, completos y actualizados, ya que son los únicos responsables de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.
                                        <br/>
                                        Así mismo, deberá comunicarse cualquier modificación de los mismos.
                                        <br/>
                                        </p>
                                        <h6> <strong>Tipología de datos obtenidos:</strong></h6>
                                        <p><br/>
                                        En el ámbito de la prestación de los servicios que ofrece el despacho y de acuerdo a las finalidades consentidas por el usuario, únicamente se tratarán los datos estrictamente necesarios para la correcta gestión de la relación contractual y/o prestación de los servicios, y será exclusivamente los que usted nos haya facilitado.
                                        <br/>
                                        Con su consentimiento, podemos obtener las siguientes informaciones:
                                        <br/>
                                        -    Nombre, domicilio, dirección de correo electrónico, número de teléfono, número e información del pasaporte o de otro documento de identificación personal reconocido.
                                        <br/>
                                        -    Nombres propios de las personas implicadas, datos técnicos, números de expediente, fechas relevantes, información relacionada con la problemática en cuestión y documentación e informes relacionados con su consulta.
                                        <br/>
                                        -    Datos económicos o financieros, cuando el asunto en cuestión lo requiera; o bien, cuando sea preciso el pago de los servicios jurídicos.
                                        <br/>
                                        -    Datos académicos y profesionales, títulos y comprobantes de formación, experiencia profesional y expedientes académicos, en el caso de recibir solicitudes de empleo o candidaturas espontáneas.
                                        <br/><br/>
                                        Sandra García García queda eximido de cualquier responsabilidad derivada de este aspecto, pudiendo llevar a cabo las comprobaciones pertinentes para constatar que los datos ofrecidos son verdaderos, siempre siguiendo los principios reglados por el RGPD.
                                        <br/>
                                        </p>
                                        <h6> <strong>¿Durante cuánto tiempo trataremos sus datos?</strong></h6>
                                        <p><br/>
                                        Los datos personales que nos proporciones serán conservados mientras se mantenga la relación contractual / mercantil vigente, o bien durante un plazo de cuatro años a partir de la última relación negocial siguiendo la normativa a efectos fiscales. No obstante, el despacho seguirá conservando su información para el envío de comunicaciones comerciales que consideremos sea de su interés, mientras no manifieste su oposición a este respecto.
                                        </p>
                                        <h6> <strong>¿A qué destinatarios se comunicarán sus datos?</strong></h6>
                                        <p><br/>
                                        Con excepción de los supuestos de cesión o comunicación de tus datos, bien en los casos requeridos por ley, bien en cumplimiento con el encargo profesional encomendado, no comunicaremos tus datos personales a terceros sin tu consentimiento libre, específico, informado e inequívoco.
                                        </p>
                                        <h6> <strong>¿Cuáles son sus derechos cuando nos facilita sus datos?</strong></h6>
                                        <p><br/>
                                        <strong>-    Acceso:</strong> El interesado tendrá derecho a acceder a ellos.
                                        <br/>
                                        <strong>-    Rectificación:</strong> El interesado tendrá derecho a obtener la rectificación de los datos personales inexactos que le conciernan.
                                        <br/>
                                        <strong>-    Supresión:</strong> El interesado tendrá derecho a solicitar la supresión de sus datos personales, en todo caso la supresión estará sujeta a los límites establecidos en la norma reguladora.
                                        <br/>
                                        <strong>-    Limitación de su tratamiento:</strong> El interesado tendrá derecho a solicitar la limitación respecto al tratamiento de sus datos de carácter personal.
                                        <br/>
                                        <strong>-    Oposición al tratamiento:</strong> En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. La entidad dejará de tratar los datos, salvo por motivos legítimos, imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
                                        <br/>
                                        <strong>-    Derecho a la portabilidad de sus datos:</strong> Es decir, tendrá derecho a recibir los datos personales que le incumban, que haya facilitado a un responsable del tratamiento, en un formato estructurado, de uso común y lectura mecánica y a transmitirlos a otro responsable del tratamiento.
                                        <br/><br/>
                                        Podrá ejercer los referidos derechos, dirigiéndose al responsable. Para ello, puede utilizar los datos de contacto facilitados al inicio del presente documento. Si desea obtener información adicional respecto al ejercicio de sus derechos, también puede dirigirse a la Agencia Española de Protección de Datos.
                                        <br/>
                                        Asimismo le informamos que, si lo cree oportuno, tiene el derecho a retirar, en cualquier momento, el consentimiento otorgado para alguna finalidad específica, sin que ello afecte a la licitud del tratamiento, basado en el consentimiento previo a su retirada. En caso que entendiera que no se han atendido adecuadamente sus derechos, podrá presentar una reclamación ante la Agencia Española de Protección de Datos. C/ Jorge Juan, 6. 28001 – Madrid. www.agpd.es.
                                        <br/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>	
		)
	}
}
export default withRouter(PoliticaPrivacidad);