import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Mascotas extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Divorcio Mascotas | Garlaw Abogados</title>
				<meta name="description" content="¿Quién se queda con la mascota en un divorcio o separación? Descubrelo en este post." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-divorcio-mascotas" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Divorcio Mascotas | Garlaw Abogados" />
				<meta property="og:description" content="¿Quién se queda con la mascota en un divorcio o separación? Descubrelo en este post." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-divorcio-mascotas" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Quién se queda con la mascota en un divorcio o separación? Descubrelo en este post." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Divorcio o Separación y ahora… ¿quién se queda con la mascota?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Septiembre 1, 2022 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Para entender la problemática que existe a este respecto debemos tener en cuenta que España es el tercer país de la Unión Europea con más divorcios por bodas celebradas. Si a este dato le sumamos que actualmente más de la mitad de los hogares españoles convive con mascotas, la problemática está servida.  </p>
                        <img className="img-fluid img-post" src="/assets/images/post_divorcio_mascota.webp" width="100%" height="100%" alt="Post Divorcio Mascota"/>
                        <span className="caption text-muted">Garlaw Abogados. Macotas en los divorcios o separaciones</span>
                        <p>
						La separación o el divorcio de una pareja no es una cuestión sencilla, por eso, los desacuerdos sobre todas las cuestiones que competen al matrimonio suelen ser muy frecuentes, y el cuidado y atención de la mascota como parte de la familia no es una excepción. Pero <span className = "p-bold">¿qué debemos saber a este respecto?</span>
                        <br/><br/>
						Somos conscientes que cada vez más nuestras mascotas ocupan un lugar muy importante en nuestras vidas, por eso la regulación ha cambiado en los últimos años, con la finalidad de adaptarse a la realidad social en la que nos encontramos y dar solución a los nuevos conflictos que están surgiendo en torno a ellas.
						<br/><br/>
						Así la <span className = "p-bold">Ley 17/2021, de 15 de diciembre</span>, introduce una serie de modificaciones en nuestro ordenamiento jurídico en cuanto a los animales de compañía se refiere, siendo la más importante de todas, la consideración de los mismos como <span className = "p-cur-os">seres vivos dotados de sensibilidad y miembros de la familia</span>. Esta nueva regulación deja atrás la anterior consideración por parte del Código Civil como bienes muebles o cosas. 
						<br/><br/>						
						Con anterioridad a la nueva Ley, y al ser considerado bienes muebles o cosas, la mayoría de los Jueces, en caso de divorcio contencioso, lo primero que valoraba era la propiedad de la mascota, sin entrar a valorar más circunstancias, de manera que el cónyuge no propietario tenía poco que hacer al respecto. Para acreditar la propiedad de unos de los cónyuges era suficiente cualquier documento donde apareciera inscrito a su nombre, aunque el régimen matrimonial fuera el de ganancias, o donde constará haber sido adquirido por él.
						<br/><br/>
						Otro sector de la jurisprudencia, en cambio, aunque de forma minoritaria, venía a trascender más allá de su consideración de bienes muebles y entraba a valorar otras circunstancias a parte de la propiedad, como podían ser:
						<br/><br/>
						<span className = "p-bold">-</span>	Sobre todo, y principalmente, si los cónyuges tenían hijos, en cuyo caso y como siempre hay que mirar por el interés y bienestar de los mismos, se atribuía el cuidado de la mascota al progenitor al que se la había atribuido la custodia de los hijos. 
						<br/><br/>						
						<span className = "p-bold">-</span>	Tenían en cuenta la situación económica de cada uno de los cónyuges y cuál de ellos tenía una vivienda adecuada para las necesidades de la misma.
						<br/><br/>						
						<span className = "p-bold">-</span>	O cual era el cuidador real de la mascota o quién de ellos tenía mayor disponibilidad de tiempo para las atenciones o cuidados que requería la misma.
						<br/><br/>
						Por todo ello, y ante la falta de regulación que existía hasta el momento, debiendo resolverse la problemática a golpe de doctrina jurisprudencial, es por lo que el legislador ha introducido una importante modificación a este respecto, adecuándose a la realidad existente en este momento, e intentando dar un marco legal en el que ampararse estas nuevas problemáticas. 
						<br/><br/>
						Así, a partir del 5 de enero de 2022 con la entrada en vigor de la Ley 17/2021, de 15 de diciembre, se ha producido una importante modificación, cuyo cambio fundamental, como ya hemos indicado es la consideración de las mascotas como seres vivos dotados de sensibilidad y miembros de la familia, y a cuyo mayor interés habrá de atenerse. 
						<br/><br/>
						<span className = "p-bold">¿pero qué novedades introduce esta ley respecto de las mascotas en caso de separación o divorcio?</span>
						<br/><br/>
						La principal novedad a este respecto es la introducción de una especie de “custodia compartida”, en la que debe estarse al interés de todos los miembros de la familia, priorizando el bienestar de los animales en cuestión, debiendo distinguirse si la separación o divorcio son de mutuo acuerdo o contenciosos.
						<br/><br/>
						<span className = "p-bold">En caso de separaciones y divorcios de mutuo acuerdo. </span>
						<br/><br/>
						Se introduce la posibilidad de incluir en el convenio regulador que se acompaña en la demanda de mutuo acuerdo, una cláusula relativa al destino de la mascota y al tiempo de disfrute de cada cónyuge. Es decir, los cónyuges al igual que con cualquier cuestión referente al matrimonio podrán llegar a acuerdos relativos a la custodia, cuidado y visitas de la mascota.
						<br/><br/>
						<span className = "p-bold">En caso de separaciones y divorcios de contenciosos. </span>
						<br/><br/>
						En estos casos, y a diferencia de lo que ocurría antes, el juez podrá atribuir la custodia de la mascota a uno u otro cónyuge <span className = "p-bold">con independencia de quién sea el propietario</span>. Por tanto, la propiedad ya no es el principal factor a tener en cuenta, pasando a un segundo plano (ex art. 94 bis del Código Civil). 
						<br/><br/>
						No obstante, atribuyéndose a un cónyuge el cuidado de la mascota, el Juez deberá fijar un régimen de visitas con respecto al cónyuge no custodio, de manera similar a cuando existieran hijos menores.
						<br/><br/>
						Pero las novedades no terminan aquí, también se introduce la posibilidad de que el Juez decida en el caso de medidas provisionales de divorcio, a quién se atribuye su cuidado, el régimen de visitas del cónyuge que no tiene atribuida su custodia, y las medidas cautelares necesarias para conservar los derechos de cada cónyuge.
						<br/><br/>
						Además, y como establece el mencionado artículo 94 bis del Código Civil, las circunstancias anteriormente mencionadas adoptadas por el Juez relativo al cuidado de la mascota se harán constar en el registro de identificación del animal.
						<br/><br/>
						No cabe duda, que con la nueva legislación se ha dado un paso al frente en lo que a la protección de los animales de compañía se refiere, no obstante, desde Garlaw Abogados siempre aconsejamos llegar a un acuerdo, por el bienestar ya no solo de nuestra mascota sino también del otro cónyuge, pues su amor y cariño es exactamente igual al tuyo, intentando reducir al mínimo el sufrimiento de todas las partes en este conflicto.
						</p>
						
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/WILhjE9yL0I"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:6971020427926093825/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1565253934301814784"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Mascotas);