import React  from 'react';
import {NavLink, withRouter}  from "react-router-dom";

import '../componentes.css'; 
 
import AOS from 'aos';
import 'aos/dist/aos.css';
	
import $ from 'jquery'; 
import MetaTags from 'react-meta-tags'; 

class Hipotecas extends React.Component {

	componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }
	  
  
    
	render() {
		return(

			<>

			<MetaTags>	
				<title>Hipotecas | Garlaw Abogados</title>
				<meta name="description" content="Somos especialistas en derecho hipotecario.  Que no te engañen. Consulta con nosotros todos los detalles de tu hipoteca." />
				<link rel="canonical" href="https://www.garlawabogados.com/areas-legales/hipotecas" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Hipotecas | Garlaw Abogados" />
				<meta property="og:description" content="Somos especialistas en derecho hipotecario.  Que no te engañen. Consulta con nosotros todos los detalles de tu hipoteca." />
				<meta property="og:url" content="https://www.garlawabogados.com/areas-legales/hipotecas" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Somos especialistas en derecho hipotecario.  Que no te engañen. Consulta con nosotros todos los detalles de tu hipoteca." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea" data-aos="zoom-in" data-aos-delay="100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 welcomeconoce">
								<div className = "welco-conoce">
									<h1>Hipotecas</h1>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="areaas_legales">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7" data-aos="zoom-out" data-aos-delay="100">
                        <p>¿No sabes si tu escritura de préstamo hipotecario contiene cláusulas abusivas? Desde Garlaw Abogados te sacamos de dudas, te decimos gratuitamente si tu hipoteca posee cláusulas abusivas y lo reclamamos por ti. Confía en nosotros.</p>
                        <img className="img-fluid" src="/assets/images/Garlaw_Hipotecas.webp" width="100%" height="100%" alt="Garlaw Abogados Hipotecas"/>
                        <span className="caption text-muted">Garlaw Abogados. Hipotecas</span>
                        <p>Te ofrecemos asesoramiento integral sobre las siguientes materias:</p>
						<ul className="list-bullets">
                        	<li className="mb-lar">Cláusulas Abusivas: Clausula Suelo, IRPH, Gastos Hipotecarios.</li>
							<li className="mb-lar">Hipotecas Mobiliarias e Inmobiliarias.</li>
							<li className="mb-lar">Hipoteca Multidivisa.</li>
							<li className="mb-lar">Ejecuciones Hipotecarias.</li>
                    	</ul>
                    </div>
                </div>
            </div>
        </article>

		<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Tu Abogado a un <span>Paso</span></h2>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="100">
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-map-marker"></i>
							<h3>Visita Nuestras Oficinas</h3>
							<p>Madrid, Segovia, Avila</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-whatsapp"></i>
							<h3>Nuestros Canales Digitales</h3>
							<p>Llamada, Email, Videollamada, Whatsapp</p>
							</div>
						</div>
							<div className="text-center">
							<NavLink to="/contacto" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = "Contacto">
								<button>Contacta</button>
							</NavLink>
							</div>
						</div>
					</div>
    			</section>


		</>
		)
	}
}
export default withRouter(Hipotecas);