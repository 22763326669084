import React  from 'react';
import {withRouter}  from "react-router-dom";
import '../componentes.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
 
class Post_Ertes extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post ERTEs | Garlaw Abogados</title>
				<meta name="description" content="Respuestas a las principales preguntas sobre los ERTE. Descúbrelo en este post." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-erte" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post ERTEs | Garlaw Abogados" />
				<meta property="og:description" content="Respuestas a las principales preguntas sobre los ERTE. Descúbrelo en este post." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-erte" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Respuestas a las principales preguntas sobre los ERTE. Descúbrelo en este post." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Guía sobre los ERTE</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Abril 21, 2020 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> 
							El Covid 19 ha provocado un enorme impacto en la realidad no sólo nacional, sino mundial, en el que el mundo del Derecho no podía ser ajeno a ello. Nos encontramos ante un nuevo desafío a nivel mundial, en el que el Derecho, como en otros muchos sectores, jugará un importante papel al respecto, dada las problemáticas que han surgido y van a ir surgiendo como consecuencia de la declaración de la pandemia originada por el COVID 19.
							<br/>
							Dentro de dicha problemática, y dado la importancia y transcendida que está adquiriendo a raíz de la declaración por parte del Gobierno del estado de alarma en virtud de Real Decreto 463/2020, de 14 de marzo, dicha guía, se va a centrar en los expedientes de regulación temporal de empleo (ERTE).
							</p>
                        <img className="img-fluid img-post" src="/assets/images/post_erte2.webp" width="100%" height="100%" alt="Post Smart Contract"/>
                        <span className="caption text-muted">Garlaw Abogados. ERTE</span>
                        <p>
						En los últimos días, se está produciendo una avalancha de expedientes de regulación temporal de empleo por parte de las empresas, que se han visto afectadas por el parón de su actividad económica, ante la ruptura de su cadena productiva y el confinamiento de la población. De manera que ciento de miles de trabajadores se encuentran actualmente afectados por los referidos expedientes, sin saber exactamente que son y qué derecho ostentan en los mismos.
						<br/><br/>
						Dicha avalancha ha provocado que por parte del Consejo General del Poder Judicial se esté estudiando un plan de choque para evitar el colapso de los juzgados una vez que poco a poco se vaya volviendo paulatinamente a la normalidad. Dicho plan de choque afecta entre otras jurisdicciones a la social, dada la transcendencia que está adquiriendo actualmente y el inmenso volumen de ERTEs presentados.
						<br/><br/>
						La presente guía pretende aclarar las principales dudas acerca de los referidos expedientes de regulación de empleo. Para ello, comenzaré explicando que son y cuáles son los tipos que existen, los procedimientos para llevarlos a cabo, y finalmente, intentaré responder algunas de las dudas que muchos trabajadores se están planteando actualmente.
						<br/><br/>
						<span className = "p-subr"> ¿QUÉ ES UN EXPEDIENTE DE REGULACIÓN TEMPORAL DE EMPLEO?</span>
						<br/><br/>
						Un expediente de regulación temporal de empleo, los ya conocido ERTE, es una medida de flexibilización laboral que permite a las empresas reducir o suspender los contratos de trabajo de sus empleados.
						<br/><br/>
						Es necesario tener en cuenta que el ERTE no tiene por qué afectar a toda la plantilla, es decir, no existe un mínimo o máximo a este respecto. Así las cosas, un ERTE puede afectar a unos trabajadores en concreto y a otros no, o, por ejemplo, en un mismo ERTE reducir la jornada laboral a unos empleados y suspender el contrato a otros.
						<br/><br/>
						De manera que dos son las medidas que se pueden aplicar a raíz de un ERTE:
						<br/><br/>
						<span className = "p-bold">1. Reducción de jornada:</span> Se podrá reducir la jornada laboral a los trabajadores. En este supuesto si existen mínimos y máximos, se podrá reducir como mínimo un 10% de la jornada y como máximo un 70%. El contrato sigue vigente y el trabajador cobrará por parte de la empresa las horas efectivamente realizadas, correspondiendo al SEPE el abono de la reducción de la jornada adoptada.
						<br/><br/>
						<span className = "p-bold">2. Suspensión del contrato:</span> La otra medida por la que la empresa puede optar, es suspender nuestro contrato de trabajo. ¿Esto qué significa? Que mientras dure el hecho que ha detonado la solicitud del ERTE, nuestro contrato se encuentra por decirlo de alguna manera más informal en “stand by”, no obstante, seguimos manteniendo nuestro puesto de trabajo.
						<br/><br/>
						<span className = "p-subr"> ¿QÚE TIPOS DE ERTE HAY Y CUALES SON LOS PROCEDIMIENTOS A SEGUIR EN CADA UNO DE ELLOS?</span>
						<br/><br/>
						Es necesario distinguir entre los ERTE convencionales, los llamados ETOP, que se adoptan por causas económicas, técnicas, organizativas o de producción, y los ERTE de fuerza mayor.
						<br/><br/>
						<span className = "p-bold">1 . ERTE por causas económicas, técnicas, organizativas o de producción (ETOP):</span> la empresa en virtud del mismo podrá suspender el contrato o reducir la jornada laboral del trabajador cuando concurran las ya mencionadas causas económicas, técnicas, organizativas y de producción que tenga relación directa con el COVID 19.
						<br/><br/>
						Para adoptar dicho ERTE, será necesario que la empresa comunique su intención a los trabajadores, siendo preceptivo que estos últimos constituyan la llamada comisión negociadora.
						<br/><br/>
						La Comisión negociadora representará a los trabajadores, de manera que puede estar constituida por los sindicatos más representativos del sector al que pertenezca la empresa o, en su defecto, por tres trabajadores elegidos conforme al artículo 41.4 del Estatuto de los Trabajadores.
						<br/><br/>
						El plazo para comunicar a los trabajadores la intención de realizar un ERTE es el de 5 días hábiles, debiendo comunicarse “simultáneamente”, y lo ponemos entre comillas, toda vez, que es difícil hacerlo de manera simultánea, a la autoridad laboral.
						<br/><br/>
						Una vez realizado esto, se abrirá el periodo de consultas entre la empresa y la representación de los trabajadores, por un plazo que no podrá exceder los 7 días hábiles. Trascurrido el cual nos podemos encontrar:
						<br/><br/>
						<span className = "p-subr">Se haya llegado a un acuerdo.</span> Esta es la mejor solución puesto que lo trabajadores reconocen la causa del ERTE, y la misma no se podrá posteriormente impugnar. Deberá ser comunicada posteriormente a la Autoridad laboral.
						<br/><br/>
						<span className = "p-subr">No se llegue a acuerdo alguno.</span> En este caso se podrá impugnar la causa por la cual se ha adoptado el ERTE.
						<br/><br/>
						Respecto de la duración de estos ERTE, debemos de decir que no tienen periodo de duración estipulada y duran el tiempo en el que se produzcan las circunstancias de reducción de periodo de trabajo; cuando termine la causa, termina el ERTE y se reanuda la actividad.
						<br/><br/>
						<span className = "p-bold"> 2. ERTE de fuerza mayor:</span>a empresa en virtud del mismo podrá suspender el contrato o reducir la jornada laboral del trabajador cuando concurra fuerza mayor que tenga relación directa con el COVID 19. Para poder solicitar este ERTE, la empresa deberá acreditar debidamente que existen pérdidas de la actividad cuya causa directa sea el COVID-19. Entre dichas causas se encuentran:
						<br/><br/>
						- La declaración del estado de alarma.
						<br/><br/>
						- Cierre temporal de locales a la afluencia pública.
						<br/><br/>
						- Restricciones del transporte.
						<br/><br/>
						- Restricciones de movilidad de las personas.
						<br/><br/>
						- Falta de suministros.
						<br/><br/>
						- Contagio de la plantilla o aislamiento.
						<br/><br/>
						Sin duda alguna, es este tipo de ERTE el que más se está adoptando en la situación en la que nos encontramos.
						<br/>
						De manera, que se aplicará durante el tiempo que dure el estado de alarma, incluidas, por supuesto, las prórrogas del mismo.
						<br/><br/>
						Este ERTE se inicia por solicitud de la empresa a la Autoridad laboral. En dicha solicitud, la empresa deberá aportar:
						<br/><br/>
						- Informe especifico relativo a la perdida de actividad por el COVID 19. Dicho informe deberá ser mucho más exhaustivo cuando la empresa se dedique a alguna actividad que pueda desarrollarse durante el estado de alarma.
						<br/><br/>
						- Documentación acreditativa de dicho informe.
						<br/><br/>
						- Comunicación a los trabajadores de esta solicitud con la documentación que se acompaña.
						<br/><br/>
						Una vez comunicado a la Autoridad laboral, ésta dispondrá de un período de 5 días hábiles para resolver. Si resuelve en dicho plazo no se planteará problema alguno, pero ¿qué ocurre si la Administración no responde?. Ante la avalancha de ERTEs presentados ante la Autoridad Laboral, se está produciendo, que la misma, sobrepasada ante la situación que nos encontramos, no puede revisar todos y cada uno de ellos. En este caso, se entenderá admitida la solicitud de ERTE por el llamado silencio positivo (Artículo 24.1 LPAC). <span className = "p-subr">Lo que no implica que después, la Autoridad laboral pueda revisarlo, pudiendo revocar el mismo y anularlo, al entender que no concurren las causas requeridas para ello.</span>
						<br/><br/>
						Si se admite por la Autoridad laboral, el empresario puede a su entera voluntad poder aplicarlo o no, debiendo comunicárselo oportunamente a los trabajadores.
						<br/><br/>
						Una vez, aclarado todo esto, pasamos a resolver las principales dudas que se plantean al respecto.
						<br/><br/>
						<span className = "p-subr">CONSECUENCIAS PARA EL TRABAJADOR POR LA ADOPCIÓN DE UN ERTE</span>
						<br/><br/>
						A diferencia de los ERE, en el ERTE la empresa no tiene que indemnizar a ninguno de los trabajadores afectados por el mismo. Y esto es del todo claro, puesto que como decimos, en el caso que opte por la suspensión de los contratos, los mismos no finalizan, sino que están, como hemos indicado en “stand by”, manteniéndose el puesto de trabajo. No debemos olvidar que se trata de una situación temporal, que no produce la finalización del contrato, sino únicamente su suspensión mientras se mantenga dicha circunstancia, manteniéndose la relación contractual entre la empresa y el empleado. En consecuencia, debe mantenerse su antigüedad, la cual permanecerá intacta, y, además, el empleador está obligado a reincorporar al trabajador en su mismo puesto una vez que las causas legales hayan cesado.
						<br/><br/>
						Los trabajadores que se hayan visto afectados podrán cobrar automáticamente la prestación por desempleo independientemente de lo que hayan cotizado, y, muy importante, sin que el período consumido se descuente a futuro. Como decimos, podrán cobrar el desempleo independientemente de lo que hubieran cotizado con anterioridad, por lo tanto, no hay requisito de acceso.
						<br/><br/>
						<span className = "p-bold">¿Pero cuánto percibirán los trabajadores por el desempleo?</span> En los supuestos de suspensión del contrato, en los primeros 180 días, el trabajador recibirá el 70% de su base reguladora, pasados esos primeros 180 días, percibirá el 50%.
						<br/><br/>
						Para el caso en que se haya adoptado una reducción de jornada, el empleador satisfará al trabajador las horas efectivamente trabajadas, correspondiendo al SEPE el abono de las horas reducidas o pérdidas.
						<br/><br/>
						Respeto de la fecha de efecto de estas prestaciones, en los ERTE de fuerza mayor habrá que estar al hecho causante de la misma, es decir la declaración del estado de alarma, para el caso de los ETOP, habrá que estar a la fecha que el empleador haya comunicado a la autoridad laboral la decisión adoptada.
						<br/><br/>
						Otra pregunta que sin duda surge al trabajador afectado por un ERTE, es si el empleador puede despedirle mientras se encuentra en el mismo. Pues bien, a este respecto el Gobierno ha establecido que para el caso de que se haya adoptado un ERTE, ya sea por fuerza mayor o por causas económicas (ETOP), no se podrá justificar la extinción del contrato de trabajo, ni el despido durante la vigencia del estado de alarma. Ahora bien, si se trata de causas distintas al COVID 19, entonces si puede producirse el despido. Además, tratándose de un ERTE por fuerza mayor, el empleador adquiere el compromiso de mantener al empleado durante 6 meses después de reanudar la actividad. 
						<br/><br/>
						Es necesario hacer un breve referencia a las medidas adoptadas por el Gobierno en cuanto a la cuota empresarial de cotización a la Seguridad Social por el trabajador que tiene empleado. Únicamente para el caso del ERTE de fuerza mayor, se concede al empleador una exoneración en el pago de la cuota empresarial de cotización por el trabajador. Dicha exoneración será del 75%, cuando la empresa tenga más de 50 trabajadores, y del 100%, cuando sea menor de 50. Pero el hecho de que se le exonere al empleador no afecta en nada al trabajador, para el cual se entiende efectivamente cotizado. También se prevé por el Gobierno una moratoria de dichas cuotas para el caso de los llamados ETOP, o en el caso de los ERTES por fuerza mayor si opta el empleador por esta opción.
						<br/><br/>
						<span className = "p-subr">PREGUNTAS MÁS FRECUENTES AL RESPECTO</span>
						<br/><br/>
						<span className = "p-bold">¿Tiene el trabajador que hacer algo en caso de ERTE adoptado por la empresa para cobrar su prestación por parte del SEPE? </span><br/>
						No. El trabajador no tendrá que hacer, será la empresa la que se encargue de proporcionar los datos necesarios al SEPE para que pueda cobrar su oportuna prestación.
						<br/><br/>
						<span className = "p-bold">¿Puede mi empresa obligarme a coger vacaciones ante la situación en la que estamos? </span><br/>
						La respuesta es no. La empresa no puede obligar al trabajador a coger vacaciones. Se entienden que las mismas son para el disfrute de los trabajadores, y no por causas que afecten al desarrollo de la actividad laboral. Además, no debemos olvidar que las vacaciones deben ser comunicadas con dos meses de antelación.
						<br/><br/>
						<span className = "p-bold">¿Y si me despiden dentro del período de 6 meses después de reanudar la actividad en el caso de los ERTEs de fuerza mayor? </span><br/>
						Como hemos dicho, el empleador asume el compromiso de mantener al empleado durante los 6 meses siguientes a reanudar la actividad. En este período no podrá despedirle, y en caso de que lo hago el mismo será improcedente, incluso nulo. Lo que ocurre es que al empresario le puede ser más beneficioso pagar la correspondiente indemnización por despido improcedente que mantener al trabajador en su puesto de trabajo. Es necesario añadir que, en este caso, además, tendría que abonar las cuotas de la Seguridad Social que se hubiere exonerado.
						<br/><br/>
						<span className = "p-bold">¿Durante el período que se encuentra suspendido mi contrato por ERTE devengo vacaciones? </span><br/>
						No. Durante dicho periodo no devengaras días de vacaciones, ya que como es lógico el contrato está suspendido.
						<br/><br/>
						<span className = "p-bold">¿Podrán revisarse las solicitudes de ERTE una vez que finalice el estado de alarma? </span><br/>
						Sí. Dichas solicitudes podrán ser revisadas, a pesar, de que se entiendan aceptadas por silencio positivo. En el caso de que revisada se entienda que no se cumple los requisitos, se impondrán las oportunas sanciones al respecto. Hay que tener en cuenta que también puede ser revisadas durante la vigencia del estado de alarma.
						<br/><br/>
						<span className = "p-bold">¿Y qué pasaría con las prestaciones recibidas por los trabajadores del SEPE? </span><br/>
						Para el caso de que, revisado un ERTE, se entienda que no cumple las causas y que, por lo tanto, no puede aplicarse, será el empleador el que deberá devolver las percepciones recibidas por los trabajadores a través del SEPE, debiendo abonar a estos, además, la diferencia que restara de percibir respecto a su salario normal.
						<br/><br/>
						<span className = "p-bold">¿Se puede incluir en un ERTE a un trabajador que se encuentra en una situación de incapacidad temporal? </span><br/>
						Sí, se podrá incluir. Si se produce el alta médica en ese periodo pasará a encontrarse en situación de desempleo por ERTE.
						<br/><br/>
						<span className = "p-bold">¿Qué pasa si tengo un contrato temporal y mi empresa decide realizar un ERTE? </span><br/>
						En este caso la normativa únicamente se refiere a la suspensión de los contratos temporales por ERTE. En ellos se entiende que la duración de los mismos se encontraría interrumpida por el tiempo equivalente al periodo suspensivo. Es decir, el contrato queda igualmente suspendido, de manera que, si el mismo tendría que acabar en pleno estado de alarma, al interrumpirse la duración, terminará después de reanudarse la actividad, por el tiempo que restase desde su suspensión.
						<br/><br/>
						<span className = "p-bold">¿Un trabajador afectado por un ERTE puede hacer efectivo sus planes de pensiones? </span><br/>
						Sí, ya que se encuentran legalmente en situación de desempleo.
						<br/><br/>
						<span className = "p-bold">¿Los períodos cotizados durante el ERTE computan para la jubilación? </span><br/>
						Sí, como ya he dicho para el trabajador se entiende como efectivamente cotizados, de manera que, computan para su jubilación.
						<br/><br/>
						<span className = "p-bold">¿Y que pasan con las pagas extras? </span><br/>
						Si el contrato está suspendido no se devengarán pagas extras. Si se reduce la jornada laboral, las mismas se reducirán proporcionalmente.
						<br/><br/>
						<span className = "p-bold">¿Y si mi empresa nos hace un ERTE y nos obliga a seguir trabajando? </span><br/>
						Como es lógico, mientras se encuentra en una situación de ERTE no se puede realizar actividad laboral alguna. En el caso de que se realicé, conllevarán sanciones tanto para el empleador como para el trabajador.
						<br/><br/>
						<span className = "p-subr">CONCLUSIÓN</span>
						<br/><br/>
						Con la presente guía práctica pretendo poner de manifiesto de forma clara, concisa y entendible los principales aspectos a tener en cuenta respecto los llamados ERTE, intentando resolver algunas de las innumerables dudas que se están planteando en relación a los mismos. Con ello pretendo que los trabajadores afectados por alguno de ellos, sepan cuáles son los derechos que ostentan, y, que desgraciadamente, en muchos casos, les tocara defender ante los Tribunales, dada la situación en la que nos encontramos.
						</p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Ertes);