import React  from 'react';
import {withRouter}  from "react-router-dom";
import '../componentes.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css';
import MetaTags from 'react-meta-tags';
import { Icon } from '@iconify/react';
	
 
class Post_Covid extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Covid y Arrendamientos | Garlaw Abogados</title>
				<meta name="description" content="Informe Jurídico sobre el impacto del COVID-19 en los contratos de arrendamiento." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-covid-arrendamientos" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Covid y Arrendamientos | Garlaw Abogados" />
				<meta property="og:description" content="Informe Jurídico sobre el impacto del COVID-19 en los contratos de arrendamiento." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-covid-arrendamientos" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Informe Jurídico sobre el impacto del COVID-19 en los contratos de arrendamiento." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Informe Jurídico sobre el impacto del COVID-19 en los contratos de arrendamiento</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Abril 16, 2020 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> 
							El Covid 19 ha provocado un enorme impacto en la realidad no sólo nacional, sino mundial, en el que el mundo del Derecho no podía ser ajeno a ello. Nos encontramos ante un nuevo desafío a nivel mundial, en el que el Derecho, como en otros muchos sectores, jugará un importante papel al respecto, dada las problemáticas que han surgido y van a ir surgiendo como consecuencia de la declaración de la pandemia originada por el COVID 19.
							<br/>
							Dentro de dicha problemática, y dado la importancia y transcendida que está adquiriendo a raíz de las medidas adoptadas por el Gobierno, dicho informe jurídico, se va a centrar en los contratos de arrendamiento de bienes inmuebles.
							</p>
                        <img className="img-fluid img-post" src="/assets/images/post_covid.webp" width="100%" height="100%" alt="Post Covid y arrendamientos"/>
                        <span className="caption text-muted">Garlaw Abogados. Impacto del COVID-19 en los contratos de arrendamientos</span>
                        <p>
						Como ya he indicado, el Gobierno tras declarar el estado de alarma en virtud de Real Decreto 463/2020, de 14 de marzo, ante la grave crisis sanitaria ocasionada por el COVID-19, ha ido adoptando una serie de medidas sociales y económicas, entre las que se encuentra las recogidas en el Real Decreto-ley 11/2020, de 31 de marzo, por el que se adoptan medidas urgentes complementarias en el ámbito social y económico para hacer frente al COVID-19. En dicho Decreto, se hace referencia al arrendamiento para el uso de vivienda habitual, por lo que será muy tenido en cuenta en el presente informe jurídico, con el fin de resumir, aclarar y orientar las nuevas novedades introducidas al efecto.
						<br/><br/>
						Para abordar la cuestión de forma correcta, es necesario partir de una distinción:
						<br/><br/>
						<span className = "p-bold">1. Arrendamiento de vivienda.</span>
						<br/><br/>
						<span className = "p-bold">2. Arrendamiento para uso distinto del de vivienda (locales comerciales).</span>
						</p>
						<p>
						<span className = "p-subr"> ARRENDAMIENTO DE VIVIENDA</span>
						<br/><br/>
						Con independencia de las medidas adoptadas a este respecto, y que pasaré a resumir posteriormente, es necesario, tener en cuenta y partir del oportuno contrato de arrendamiento al respecto. Es posible, aunque suele ser improbable, que en alguna de sus cláusulas se hubiere previsto las consecuencias en caso de encontrarnos ante una situación de fuerza mayor como la que atravesamos.
						<br/><br/>
						No obstante, como digo, esta es una realidad muy improbable que nos aboca a acudir al Código Civil y a la legislación arrendaticia al respecto, ante la omisión de pronunciamiento alguno sobre esta circunstancia. El Gobierno, conocedor de esta situación, y ante la previsión de una oleada de desahucios por impago de la renta, ha adoptado una serie de medidas a tener en cuenta sobre esta materia, y que el presente informe jurídico pretende ayudar a clarificar.
						<br/><br/>
						Antes de exponer todas y cada una de las medidas adoptadas al respecto, es necesario poner de manifiesto, que dichas medidas requieren como presupuesto básico para su aplicación la situación de vulnerabilidad del arrendatario. Dicha vulnerabilidad aparece definida en el artículo 5 del citado Decreto:
						</p>
						<p className = "p-curs">
						1. Los supuestos de vulnerabilidad económica a consecuencia de la emergencia sanitaria ocasionada por el COVID-19 requerirán la concurrencia conjunta, a los efectos de obtener moratorias o ayudas en relación con la renta arrendaticia de la vivienda habitual, de los siguientes requisitos: 
						</p>
						<p className = "p-cur-os">
						a) Que la persona que esté obligada a pagar la renta de alquiler pase a estar en situación de desempleo, Expediente Temporal de Regulación de Empleo (ERTE), o haya reducido su jornada por motivo de cuidados, en caso de ser empresario, u otras circunstancias similares que supongan una pérdida sustancial de ingresos, no alcanzando por ello el conjunto de los ingresos de los miembros de la unidad familiar, en el mes anterior a la solicitud de la moratoria:
						</p>
						<p className = "p-curs">
						i. Con carácter general, el límite de tres veces el Indicador Público de Renta de Efectos Múltiples mensual (en adelante IPREM). ii. Este límite se incrementará en 0,1 veces el IPREM por cada hijo a cargo en la unidad familiar. El incremento aplicable por hijo a cargo será de 0,15 veces el IPREM por cada hijo en el caso de unidad familiar monoparental. iii. Este límite se incrementará en 0,1 veces el IPREM por cada persona mayor de 65 años miembro de la unidad familiar. iv. En caso de que alguno de los miembros de la unidad familiar tenga declarada discapacidad superior al 33 por ciento, situación de dependencia o enfermedad que le incapacite acreditadamente de forma permanente para realizar una actividad laboral, el límite previsto en el subapartado i) será de cuatro veces el IPREM, sin perjuicio de los incrementos acumulados por hijo a cargo. v. En el caso de que la persona obligada a pagar la renta arrendaticia sea persona con parálisis cerebral, con enfermedad mental, o con discapacidad intelectual, con un grado de discapacidad reconocido igual o superior al 33 por ciento, o persona con discapacidad física o sensorial, con un grado de discapacidad reconocida igual o superior al 65 por ciento, así como en los casos de enfermedad grave que incapacite acreditadamente, a la persona o a su cuidador, para realizar una actividad laboral, el límite previsto en el subapartado i) será de cinco veces el IPREM. 
						</p>
						<p className = "p-cur-os">
						b) Que la renta arrendaticia, más los gastos y suministros básicos, resulte superior o igual al 35 por cien de los ingresos netos que perciba el conjunto de los miembros de la unidad familiar. A estos efectos, se entenderá por «gastos y suministros básicos» el importe del coste de los suministros de electricidad, gas, gasoil para calefacción, agua corriente, de los servicios de telecomunicación fija y móvil, y las posibles contribuciones a la comunidad de propietarios, todos ellos de la vivienda habitual que corresponda satisfacer al arrendatario. 
						</p>
						<p className = "p-curs">
						A los efectos de lo previsto en este artículo se entenderá por unidad familiar la compuesta por la persona que adeuda la renta arrendaticia, su cónyuge no separado legalmente o pareja de hecho inscrita y los hijos, con independencia de su edad, que residan en la vivienda, incluyendo los vinculados por una relación de tutela, guarda o acogimiento familiar y su cónyuge no separado legalmente o pareja de hecho inscrita, que residan en la vivienda. 3. No se entenderá que concurren los supuestos de vulnerabilidad económica a consecuencia de la emergencia sanitaria ocasionada por el COVID-19 a los efectos de obtener moratorias o ayudas en relación con la renta arrendaticia de la vivienda habitual cuando la persona arrendataria o cualquiera de las personas que componen la unidad familiar que habita aquella sea propietaria o usufructuaria de alguna vivienda en España. Se considerará que no concurren estas circunstancias cuando el derecho recaiga únicamente sobre una parte alícuota de la misma y se haya obtenido por herencia o mediante transmisión mortis causa sin testamento. Se exceptuará de este requisito también a quienes, siendo titulares de una vivienda, acrediten la no disponibilidad de la misma por causa de separación o divorcio, por cualquier otra causa ajena a su voluntad o cuando la vivienda resulte inaccesible por razón de discapacidad de su titular o de alguna de las personas que conforman la unidad de convivencia.
						</p>
						<p>
						Dicho artículo ostenta una enorme importancia, pues determina los presupuestos básicos de aplicación de las medidas que pasamos a exponer a continuación:
						<br/><br/>
						<span className = "p-bold">Suspensión del procedimiento de desahucio y de los lanzamientos para hogares vulnerables sin alternativa habitacional.</span>En dicha medida, como decimos, la persona arrendataria deberá acreditar ante el Juzgado encontrarse en una situación de vulnerabilidad social o económica sobrevenida como consecuencia de los efectos de la expansión del COVID-19, que le imposibilite encontrar una alternativa habitacional para sí y para las personas con las que conviva.
						<br/><br/>
						<span className = "p-bold">Prórroga extraordinaria de los contratos de arrendamiento de vivienda habitual.</span>En aquellos contratos en los que finalice el período de prórroga obligatoria, podrá aplicarse, previa solicitud del arrendatario, una prórroga extraordinaria del plazo del contrato de arrendamiento por un periodo máximo de seis meses, durante los cuales se seguirán aplicando los términos y condiciones establecidos para el contrato en vigor. Esta medida es independiente a la situación de vulnerabilidad o no del arrendatario.
						<br/><br/>
						<span className = "p-bold">Moratoria de deuda arrendaticia.</span>Dicha moratoria únicamente será aplicable de forma automática para el caso en que el propietario y arrendador sea una empresa o entidad pública de vivienda o un gran tenedor, entendiendo por tal, la persona física o jurídica que sea titular de más de diez inmuebles urbanos, excluyendo garajes y trasteros, o una superficie construida de más de 1.500 m2.
						<br/><br/>
						Cuando el propietario-arrendador cumpla estos requisitos <span className = "p-bold">el arrendatario podrá</span>, artículo 4:
						</p>
						<p className = "p-curs">
						1. La persona arrendataria de un contrato de vivienda habitual suscrito al amparo de la Ley 29/1994, de 24 de noviembre, de Arrendamientos Urbanos, que se encuentre en situación de vulnerabilidad económica, tal y como se define en el artículo siguiente, podrá solicitar de la persona arrendadora cuando esta sea una empresa o entidad pública de vivienda o un gran tenedor, entendiendo por tal la persona física o jurídica que sea titular de más de diez inmuebles urbanos, excluyendo garajes y trasteros, o una superficie construida de más de 1.500 m2, en el plazo de un mes desde la entrada en vigor de este real decreto-ley, el aplazamiento temporal y extraordinario en el pago de la renta, siempre que dicho aplazamiento o la condonación total o parcial de la misma no se hubiera conseguido ya con carácter voluntario por acuerdo entre ambas partes. 
						<br/><br/>
						2. En el caso de que el acuerdo no se hubiese producido, el arrendador comunicará expresamente al arrendatario, en el plazo máximo de 7 días laborables, su decisión, escogida entre las siguientes alternativas: 
						<br/><br/>
						a) Una reducción del 50% de la renta arrendaticia durante el tiempo que dure el estado de alarma decretado por el Gobierno y las mensualidades siguientes si aquel plazo fuera insuficiente en relación con la situación de vulnerabilidad provocada a causa del COVID-19, con un máximo en todo caso de cuatro meses.
						<br/><br/>
 						b) Una moratoria en el pago de la renta arrendaticia que se aplicará de manera automática y que afectará al periodo de tiempo que dure el estado de alarma decretado por el Gobierno y a las mensualidades siguientes, prorrogables una a una, si aquel plazo fuera insuficiente en relación con la situación de vulnerabilidad provocada a causa del COVID-19, sin que puedan superarse, en ningún caso, los cuatro meses. Dicha renta se aplazará, a partir de la siguiente mensualidad de renta arrendaticia, mediante el fraccionamiento de las cuotas durante al menos tres años, que se contarán a partir del momento en el que se supere la situación aludida anteriormente, o a partir de la finalización del plazo de los cuatro meses antes citado, y siempre dentro del plazo a lo largo del cual continúe la vigencia del contrato de arrendamiento o cualquiera de sus prórrogas. La persona arrendataria no tendrá ningún tipo de penalización y las cantidades aplazadas serán devueltas a la persona arrendadora sin intereses. 
						 <br/><br/>
						3. Lo dispuesto en los dos apartados anteriores resultará de aplicación a todos los arrendamientos correspondientes al Fondo Social de Vivienda derivado del Real Decreto ley 27/2012 de 15 de noviembre, de medidas urgentes para reforzar la protección a los deudores hipotecarios.
						<br/><br/>
						4. La persona arrendataria podrá tener acceso al programa de ayudas transitorias de financiación reguladas por el artículo 9, levantándose la moratoria en el pago de la renta arrendaticia regulada por este artículo y el consiguiente fraccionamiento de las cuotas preestablecido, en la primera mensualidad de renta en la que dicha financiación esté a disposición de la persona obligada a su pago.	
						</p>
						<p>
						Ahora bien, es muy importante tener en cuenta que <span className = "p-bold">cuando el propietario, sea una persona jurídica o física no considerada como gran tenedor, no tendrá obligación de concederle una moratoria o aplazamiento, de hacerlo, quedaría en su exclusiva voluntad</span>. De manera que, si se negara concederlo en este último supuesto, y el arrendatario fuere de especial vulnerabilidad, se facilitara por el Estado al arrendatario una línea de ayudas transitorias para hacer frente al pago de las rentas. Así el artículo 8:
						</p>
						<p className = "p-curs">
						1. La persona arrendataria de un contrato de vivienda habitual suscrito al amparo de la Ley 29/1994, de 24 de noviembre, de Arrendamientos Urbanos que se encuentre en situación de vulnerabilidad económica, tal y como se define en el artículo 5, podrá solicitar de la persona arrendadora, cuando esta no sea ninguna de las comprendidas en el artículo 4, en el plazo de un mes desde la entrada en vigor de este Real decreto Ley y en los términos recogidos en los apartados 2 a 4 siguientes, el aplazamiento temporal y extraordinario en el pago de la renta, siempre que dicho aplazamiento o la condonación total o parcial de la misma no se hubiera acordado previamente entre ambas partes con carácter voluntario. 
						<br/><br/>
						2. Una vez recibida la solicitud, la persona arrendadora comunicará a la arrendataria, en el plazo máximo de 7 días laborables, las condiciones de aplazamiento o de fraccionamiento aplazado de la deuda que acepta o, en su defecto, las posibles alternativas que plantea en relación con las mismas. 
						</p>
						<p className = "p-cur-os">
						3. Si la persona física arrendadora no aceptare ningún acuerdo sobre el aplazamiento y, en cualquier caso, cuando la persona arrendataria se encuentre en la situación de vulnerabilidad sobrevenida referida en el artículo 5, esta podrá tener acceso al programa de ayudas transitorias de financiación reguladas por el artículo siguiente.
						</p>
						<p>
						Con estas medidas, se pretende proteger no sólo al arrendatario en una situación vulnerable, sino también a los propietarios de viviendas, que no son considerados como grandes tenedores, y a los que les resultaría imposible asumir los impagos de sus arrendamientos. Y esto tiene su sentido, pues no olvidemos que el arrendatario sigue disfrutando y poseyendo la vivienda mientras nos encontramos en esta situación, por lo que una condonación total de la misma no tendría sentido, más si cabe cuando el inmenso volumen de viviendas arrendadas provienen de pequeños propietarios, los cuales se encuentran igualmente afectados por la situación. 
						<br/><br/>
						Con esta medida, se pretende penalizar a los grandes tenedores, pensando especialmente en los fondos buitre, a los que se les establece de forma automática la moratoria si el arrendatario se encuentra en situación de vulnerabilidad, ya que aquí, el equilibrio de derechos, se encuentra muy mermado.
						</p>
						<p>
						<span className = "p-subr"> ARRENDAMIENTO PARA USO DISTINTO DE VIVIENDA (LOCALES COMERCIALES) </span>
						<br/><br/>
						Poniendo nuestro énfasis en este apartado, en los arrendamientos para usos distintos de vivienda, es necesario poner de manifiesto que el Gobierno no ha introducido medida alguna al respecto, a pesar de que es uno de los sectores más afectados.
						<br/><br/>
						Así las cosas, habrá que estar a lo estipulado por las partes en el oportuno contrato de arrendamiento al respecto, siendo indiferente, que tanto el arrendatario, como el arrendador, sea persona física o jurídica. El problema, como ya he apuntado anteriormente respecto de los contratos de arrendamiento de vivienda, es que no se suele incluir entre su clausulado, estipulación alguna respecto. De manera, que se deja a la libre negociación de las partes la circunstancia que estamos atravesando actualmente. 
						<br/><br/>
						No obstante, y para el caso en que no se llegue a un acuerdo, habrá que estar a la llamada cláusula rebus sic stantibus, <span className = "p-bold">que posibilita la suspensión, modificación o resolución del contrato por la alteración sobrevenida de las circunstancias concurrentes o la rotura del equilibrio económico del contrato, cuando a una de las partes le resulte imposible o muy gravoso su cumplimiento.</span>
						<br/><br/>
						A este respeto, <span className = "p-bold">el Tribunal Supremo mediante Sentencia de fecha 30 de junio de 2014</span>, respecto de la referida clausula, dijo: 
						“La actual crisis económica, de efectos profundos y prolongados de recesión económica, puede ser considerada abiertamente como un fenómeno de la economía capaz de generar un grave trastorno o mutación de las circunstancias y, por tanto, alterar las bases sobre las cuales la iniciación y el desarrollo de las relaciones contractuales se habían establecido”.
						<br/><br/>
						Para poder aplicar la misma, como se puede observar, es necesario que se cumplan una serie de requisitos que la doctrina jurisprudencial ha venido a fijar en los siguientes:
						<br/><br/>
						- Alteración sobrevenida de las circunstancias de cumplimiento
						<br/><br/>
 						- Onerosidad excesiva para una de las partes como consecuencia de dicha alteración
						 <br/><br/>
						- Imprevisibilidad del acontecimiento
						<br/><br/>
						- Duración del mismo, no puede ser meramente transitorio.
						<br/><br/>
						Los Tribunales, a la hora de aplicar la referida cláusula, suelen optar por la modificación del contrato, y no por su suspensión o terminación.
						<br/><br/>
						Esta cláusula, no cabe duda que tendrá su aplicación en aquellos locales que se han visto obligados a cerrar como consecuencia de las medidas adoptadas por el Gobierno. No obstante, para aquellos otros, que se les ha permitido permanecer abiertos, será necesario argumentos adicionales para demostrar que se ha producido una modificación sustancial de las circunstancias, y que en consecuencia se cumplen los requisitos de aplicación de la referida cláusula.
						<br/><br/>
						En definitiva, se deja en la libre autonomía de las partes las soluciones para el caso de los arrendamientos para uso distinto del de vivienda, pudiendo pactarse moratorias, condonaciones parciales de la renta, la suspensión o incluso la resolución del contrato. Y sólo para el caso, en que sea imposible el acuerdo entre las partes, el arrendatario podrá intentar judicialmente dicha modificación del contrato, suspensión o resolución, en virtud de la llamada cláusula rebus sic stantibus.
						<br/><br/>
						<span className = "p-subr"> CONCLUSIÓN</span>
						<br/><br/>
						Con el presente informe jurídico pretendo poner de manifiesto de forma clara, concisa y entendible las principales medidas adoptadas por el Gobierno respecto de los contratos de arrendamiento de vivienda, pero también el impacto jurídico que el COVID 19 tiene sobre los arrendamientos de uso distinto del de vivienda.
						<br/><br/>
						No obstante, también apelo a la responsabilidad, empatía y solidaridad de las personas, para resolver de la mejor forma posible para ambas partes, cuantas problemáticas surjan o pudieran surgir respecto de los contratos de arrendamiento, ante la grave situación que está atravesando no sólo España, sino el mundo entero. Es tiempo, de apelar a la negociación y concordia entre las distintas partes del contrato.
						</p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>  
						    </ul>
                        </div>
					</div>
    			</section>
		</>
		)
	}
}
export default withRouter(Post_Covid);