import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Reclamacion_Factura extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Reclamación de Factura | Garlaw Abogados</title>
				<meta name="description" content="¿Tienes que reclamar una factura de luz o gas?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-reclamacion-factura" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Reclamación de Factura | Garlaw Abogados" />
				<meta property="og:description" content="¿Tienes que reclamar una factura de luz o gas?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-reclamacion-factura" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Tienes que reclamar una factura de luz o gas?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Tienes que reclamar una factura de luz o gas?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Enero 30, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> El 24,4% de los hogares manifiesta estar poco o nada satisfechos con los servicios de electricidad o gas. Este descontento se ha visto aumentado desde el año 2022, entre otras razones, por su elevado precio. Pero ¿cómo podemos reclamar una factura de luz o gas?</p>
                        <img className="img-fluid img-post" src="/assets/images/post_reclamacion_factura.webp" width="100%" height="100%" alt="Post Reclamación Factura"/>
                        <span className="caption text-muted">Garlaw Abogados. Reclamación de una Factura</span>
                        <p>
                        La reclamación puede estar motivada por diversas razones, entre las que podemos mencionar:
                        <br/><br/>
                        -	Lectura estimada excesiva
                        <br/><br/>
                        -	Desacuerdo con el importe facturado
                        <br/><br/>
                        -	Consumo excesivo
                        <br/><br/>
                        -	Falta de aplicación de los descuentos por la compañía
                        <br/><br/>
                        -	No se aplica el descuento del bono social
                        <br/><br/>
                        -	Cortes de suministros no programados
                        <br/><br/>
                        -	Cobro de servicios adicionales no contratados
                        <br/><br/>
                        -	Deuda de un anterior propietario que se carga al actual.
                        <br/><br/>
                        La casuística puede ser muy amplia como se puede observar. Pero ¿qué debemos hacer si nos ocurre alguna de las anteriores circunstancias?
                        <br/><br/>
                        <span className = "p-bold">1-	Para el caso en que el consumo o le importe sea excesivo, o no nos apliquen los descuentos o el bono social, la lectura sea incorrecta, o nos cobren servicios adicionales no contratados debemos seguir los siguientes pasos:
                        </span>
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Ponerse en contacto con el servicio de atención al cliente de nuestra empresa comercializadora. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Presentar una reclamación. Aconsejamos que la misma se haga por escrito, no obstante, la comercializadora tiene obligación de ponerla por teléfono si así quieres hacerlo, pero no es lo más recomendable. Los canales que aconsejamos para dejar constancia de la reclamación son los siguientes: 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	En el área de clientes de la web de la empresa comercializadora, suele incluirse un apartado donde escribir y poner una reclamación. Suelen facilitar formularios al respecto.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Enviar un burofax al departamento de atención al cliente de la empresa comercializadora.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	Existen compañías que indican un email donde poder remitir nuestra reclamación.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Una vez presentada, nos deberán remitir el número de referencia de la reclamación. En la práctica nos encontramos como muchas veces no contestan a los emails, o no nos facilitan dicho número después de presentarla vía web por el formulario. Por eso aconsejamos que en estos últimos supuestos, procedamos de nuevo a presentarla hasta que nos faciliten el correspondiente número, pues sin ese número no podemos hacer seguimiento, y muchas compañías se escudan en que no ha sido presentada al no existir número de referencia. 
                        <br/><br/>
                        La compañía está obligada a enviar un justificante por escrito de dicha reclamación. Por regla general, suelen enviar una carta o email justificativo al respecto.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	A partir de la presentación de dicha reclamación la compañía dispone de un plazo de 30 días para contestar a la misma. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Pasado dicho plazo, nos podemos encontrar con que la compañía nos conteste dándonos la razón, no nos conteste o no estemos de acuerdo con la contestación que nos remita. ¿Qué hacemos ahora?
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Debemos de acudir a la Oficina Municipal de Información al Consumidor. En la misma nos ofrecen ayuda, asesoramiento y tramitaran nuestra reclamación contra la empresa comercializadora. Se trata de presentar la misma reclamación por medio de este organismo, el cual mediara en el conflicto. Dispondrá un plazo máximo de 6 meses para resolver.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Si el resultado no es el esperado habrá que acudir al Sistema Arbitral de Consumo. Se trata de un servicio gratuito, alternativo a la reclamación judicial y siempre que ambas partes estén de acuerdo. El plazo de resolución es de 90 días. El arbitraje de consumo es el arbitraje institucional de resolución extrajudicial, por eso decimos que es una alternativa a la reclamación judicial, de carácter vinculante para las partes. Las partes estarán obligadas a cumplir  con el laudo que se dicte, ya que la decisión arbitral produce los mismos efectos de cosa juzgada que una sentencia judicial. El arbitraje podrá ser de equidad o de derecho.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Si el consumidor o la empresa no quieren someterse al Sistema Arbitral de Consumo, únicamente nos quedaría la vía judicial. Son numerosas las Sentencias existentes contra compañías eléctricas o de gas, donde se declara abusiva muchas de las cláusulas existentes en los contratos de suministro. 
                        <br/><br/>
                        <span className = "p-bold">2-	Para el caso en que se quiera reclamar una factura por cortes del suministro deberemos dirigirnos a la empresa distribuidora y no a la empresa comercializadora. Los pasos a seguir son los siguientes:
                        </span>
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Ponerse en contacto con el servicio de atención al cliente de nuestra empresa distribuidora. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Presentar una reclamación. No remitimos a lo indicado en el supuesto anterior, sería igualmente aplicable a esta circunstancia. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Nos deben indicar igualmente el número de referencia de la reclamación. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	A partir de la presentación de dicha reclamación la compañía dispone de un plazo de 30 días para contestar a la misma. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Pasado dicho plazo, nos podemos encontrar con que la compañía nos conteste dándonos la razón, no nos conteste o no estemos de acuerdo con la contestación que nos remita. ¿Qué hacemos ahora?
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;•	Debemos de acudir a la Conserjería de Industria y Energía en el plazo máximo de un mes. En la misma nos ofrecen ayuda, asesoramiento y tramitaran nuestra reclamación contra la empresa distribuidora. Se trata de presentar la misma reclamación por medio de este organismo, el cual mediara en el conflicto. La resolución por parte de este organismo debe realizarse dentro de los tres meses siguientes a la solicitud.
                        <br/><br/>
                        De acuerdo con lo expuesto, desde Garlaw Abogados aconsejamos revisar minuciosamente las facturas recibidas por nuestra compañía de luz y gas, leer y examinar determinadamente las condiciones de nuestro contrato de suministro antes de proceder a firmarlo, y sobre todo, conservar los documentos creditativos de estos extremos. Si tienes dudas sobre tu factura de luz o gas no dudes en ponerte en contacto con nosotros. 
                        </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            <li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/qstOnN9Ezys"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7026149195145195521/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1620385559855398912"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Reclamacion_Factura);