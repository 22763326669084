import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Inteligencia_Artificial extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post la Inteligencia Artificial en el Mundo del Derecho | Garlaw Abogados</title>
				<meta name="description" content="La Inteligencia Artificial en el Mundo del Derecho" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-inteligencia-artificial" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post la Inteligencia Artificial en el Mundo del Derecho | Garlaw Abogados" />
				<meta property="og:description" content="La Inteligencia Artificial en el Mundo del Derecho" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-incumplimiento-regimen-visitas" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="La Inteligencia Artificial en el Mundo del Derecho" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>La Inteligencia Artificial en el mundo del Derecho</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Marzo 21, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> La Inteligencia Artificial ha llegado para quedarse, y el mundo del derecho no puede ser ajena a ella. Hoy en día, se generan una enorme cantidad de datos, ya no solo por parte de los humanos sino también de las máquinas, siendo la inteligencia artificial la base de aprendizaje automático y el futuro de todos los procesos complejos basados en esos datos. Pero </p>
                        <img className="img-fluid img-post" src="/assets/images/post_inteligencia_artificial.webp" width="100%" height="100%" alt="Post Inteligencia Artificial en el mundo del Derecho"/>
                        <span className="caption text-muted">Garlaw Abogados. La Inteligencia Artificial en el mundo del Derecho</span>
                        <p>
                        <span className = "p-bold">¿Qué es la inteligencia artificial?</span>	    
                        <br/><br/>
                        La misma se puede definir como maquinas que imitan la inteligencia humana, para ello se utiliza una combinación de algoritmos que permite la toma de decisiones.
                        <br/><br/>
                        Como hemos apuntado anteriormente, la IA es una realidad cada vez más implementada en todos los sectores, incluido el judicial. Uno de los países pionero y con más innovación en este ámbito es Estonia, donde se ha desarrollado un “juez robot”, un algoritmo capaz de tomar decisiones en los ámbitos más cotidianos, con la finalidad de eliminar retrasos en la administración de justicia, centrándose los jueces en asuntos de mayor dificultad o complejidad. 
                        <br/><br/>
                        En España, más tardía en cuanto a innovación judicial se refiere, se está utilizando la IA para la cancelación de oficio de los antecedentes penales desde marzo de 2021, con ello se ahorran más de 7.000 horas de trabajo por parte de los funcionarios. Además, cada vez es más normal la celebración de juicios telemáticos, algo impensable hace unos años y que la pandemia vino a acelerar.
                        <br/><br/>
                        Por lo tanto, no cabe duda que la inteligencia artificial tiene indudable practica en el mundo del derecho, y en el judicial, pues en definitiva permite abaratar costes, ganar celeridad en la resolución de los problemas. Pero
                        <br/><br/>
                        <span className = "p-bold">¿Qué riesgos tiene?</span>
                        <br/><br/>
                        No todo lo que ofrece la IA son ventajas, también existen riesgos muy a tener en cuenta en el mundo del derecho:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">1.</span>	El lenguaje jurídico posee algunas características propias, como puede ser la vaguedad, la ambigüedad, que pueda dar lugar a problemas de comprensión. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">2.</span>	La privacidad. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">3.</span>	La falta de precedentes en determinados asuntos que sean de especial dificultad, donde el algoritmo no tenga datos suficientes donde poder fundamentar una decisión.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">4.</span>	Casos cuya respuesta jurídica correcta implica resultados injustos o discriminatorios.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">5.</span>    Sesgos incluidos en los algoritmos por parte de los programadores que puedan incurrir en un perjucio para determinados grupos de personas. 
                        <br/><br/>
                        Es por ello, que ante estos y otros muchos más riesgos, la aplicación de la IA se haya centralizado en casos fáciles y casi mecánicos, que no den lugar a la interpretación, con la finalidad de poder ir avanzando sin riesgo para los derechos de los ciudadanos. En principio, y a día de hoy, el temor de que las máquinas sustituyan el papel del juez o abogado queda descartado, ya que muchos de los criterios de decisión son subjetivos, y, en consecuencia, difícilmente sustituibles.
                        <br/><br/>
                        Desde Garlaw Abogados somos firmes partidarios de la Inteligencia Artificial, pero es necesario un mayor desarrollo para garantizar la protección de los derechos de los ciudadanos, entre otros y especialmente, el de su intimidad y privacidad.
                        </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/jcWYdJR0q00"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7044252882077892609/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://fb.watch/jqBluNFO6Y/"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1638488240436006912"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Inteligencia_Artificial);