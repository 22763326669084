import React  from 'react';
import {withRouter}  from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Conflicto extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Novedades Arrendamientos | Garlaw Abogados</title>
				<meta name="description" content="Límite a la subida de los alquileres. Principales novedades introducidas por el real decreto-ley 11/2022, de 25 de junio, en materia de arrendamientos." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-novedades-arrendamientos" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Novedades Arrendamientos | Garlaw Abogados" />
				<meta property="og:description" content="Límite a la subida de los alquileres. Principales novedades introducidas por el real decreto-ley 11/2022, de 25 de junio, en materia de arrendamientos." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-novedades-arrendamientos" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Límite a la subida de los alquileres. Principales novedades introducidas por el real decreto-ley 11/2022, de 25 de junio, en materia de arrendamientos." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Límite a la subida de los alquileres. Principales novedades introducidas por el Real Decreto-Ley 11/2022, de 25 de junio, en materia de arrendamientos.</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Julio 7, 2022 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> El Gobierno ha adoptado mediante Real Decreto-Ley 11/2022, de 25 de junio, un nuevo paquete de medidas para hacer frente al alza de la inflación de las viviendas, entre la que se encuentra la limitación del 2% en la subida de las rentas anuales.</p>
                        <img className="img-fluid img-post" src="/assets/images/post_arrendamientos.webp" width="100%" height="100%" alt="Post Novedades Arrendamientos"/>
                        <span className="caption text-muted">Garlaw Abogados. Novedades Arrendamientos</span>
                        <p>
						Está medida adoptada en su artículo 46, prórroga la limitación extraordinaria de la actualización anual de la renta en los contratos de arrendamiento de vivienda adoptaba por el anterior Real Decreto-Ley 6/2022, de 29 de marzo, <span className = "p-bold_su"> hasta el 31 de diciembre de 2022</span>.
						<br/><br/>
						La medida distingue según el arrendador se trate de un gran tenedor o no, aunque la limitación a este respecto sigue siendo la misma, un 2%. Pero, en primer lugar,
						<br/><br/>
						<span className = "p-bold">¿qué es un gran tenedor?</span>
						<br/><br/>
						De acuerdo a la normativa española, un gran tenedor de una vivienda se define como aquella persona física o jurídica propietaria de más de diez inmuebles de carácter urbano, exceptuando garajes y trasteros, o una superficie construida de más de 1500 metros cuadrados.
						<br/><br/>
						Partiendo de esta premisa y una vez aclarada, vamos a distinguir lo que establece el referido Real Decreto respecto al mismo:
						<br/><br/>
						<span className = "p-bold">-</span>	El mencionado artículo 46, establece que para el caso de que el arrendador sea un gran tenedor, la subida de la renta será la que resulte del pacto entre las partes sin que pueda exceder el mismo la variación anual del Índice de Garantía de Competitividad a la fecha de actualización, es decir el 2%. En ausencia de pacto, el incremento quedará sujeto a esta misma limitación. 
						<br/><br/>
						<span className = "p-bold">-</span>	Para el caso de que el arrendador no sea un gran tenedor, de nuevo el incremento de la renta será el que resulte del pacto entre las partes. En ausencia de pacto habrá que estar a la variación anual del Índice de Garantía de Competitividad a la fecha de actualización, es decir el 2%.
						<br/><br/>
						Por tanto, <span className = "p-bold_su">el Gobierno fija como límite máximo el 2% para la subida de la renta anual en los contratos de arrendamiento hasta el 31 de diciembre de 2022</span>.  Esta limitación se refiere a la revisión anual de los contratos de arrendamiento, pero no así a los nuevos contratos que se celebren. Es decir, los nuevos contratos no tienes límite en cuanto a la cantidad de renta a convenir, siendo el libremente pactado por las partes. No obstante, tres de cada cuatro contratos de arrendamientos existentes en España quedarán afectados por esta medida, constando a los propietarios y, por tanto, arrendadores, más de 1700 millones de euros.
						<br/><br/>


						Pero estas no son las únicas novedades en materia de arrendamientos introducidas por el mencionado Real Decreto, <span className = "p-bold_su">así en su artículo 29 amplía hasta el 31 de diciembre de 2022 la suspensión de los desahucios y lanzamientos de vivienda habitual para personas y familias vulnerables, así como la posibilidad de solicitar, la compensación por parte del arrendador o propietario, recogida en el Real Decreto-ley 37/2020, de 22 de diciembre </span>.
						<br/><br/>
						En los casos de suspensión por situación de vulnerabilidad, será el arrendatario quién deberá acreditar tal circunstancia, debiendo emitir los servicios sociales un informe al respecto y las medidas necesarias a adoptar por la administración competente para garantizarles el acceso a una vivienda digna.  
						<br/><br/>
						Pero <span className = "p-bold">¿qué es la compensación a la que tiene derecho el arrendador y cuáles son los requisitos?</span>
						<br/><br/>
						Los arrendadores podrán pedir la compensación cuando en los tres meses siguientes a la fecha de emisión del referido informe de los servicios sociales, la administración competente no hubiese adoptado las medidas adecuadas para atender el acceso a una vivienda digna. 
						<br/><br/>
						<span className = "p-bold">¿a cuánto asciende esta compensación?</span>
						<br/><br/>
						La compensación consistirá en el valor medio que correspondería a un alquiler de vivienda en el entorno en el que se encuentre el inmueble, determinado a partir de los índices de referencia del precio de alquiler de vivienda u otras referencias objetivas representativas del mercado de arrendamiento, más los gastos corrientes de la vivienda que acredite haber asumido el arrendador. Si el valor de la renta fuere superior, la compensación será la renta dejada de percibir más los gastos corrientes.
						<br/><br/>
						<span className = "p-bold">¿cuál es el plazo?</span>
						<br/><br/>
						Será de un mes desde la finalización de la vigencia de las medidas, es decir, hasta el 31 de enero de 2023.
						<br/><br/>
						A todo lo expuesto, debe de añadirse también <span className = "p-bold_su">la suspensión hasta el 31 de diciembre de 2022 de los desahucios y lanzamientos para personas económicamente vulnerables sin alternativa habitacional en los supuestos de los apartados 2º, 4º Y 7º (precario, tutela sumaria de tenencia o posesión, titulares de los derechos reales inscritos en el Registro de la propiedad) del artículo 250.1 de la Ley 1/2000 , de 7 de enero, de Enjuiciamiento Civil, y en aquellos otros en los que el desahucio traiga causa de un procedimiento penal, como puede ser el delito de usurpación de vivienda por ocupación ilegal.</span>
						<br/><br/>
						En consecuencia, el Gobierno ha prorrogado unas medidas en materia de arrendamiento que pretenden ser la antesala de una futura Ley de la Vivienda, y que se pronostican que encarecerán más aun el mercado del arrendamiento en áreas especialmente tensionadas como lo son Madrid o Barcelona.
						<br/><br/>
						<span className = "p-bold">Si todavía te quedan dudas o requieres de un asesoramiento especializado, ponte en contacto con nosotros. Te estamos esperando.</span>
						</p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/watch?v=nzixQUdFOvs"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:activity:6950880134237204480"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1545114576659914754"><i className="fa-brands fa-x-twitter"></i></a></li>  
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Conflicto);