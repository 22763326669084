import React  from 'react';
import {NavLink, withRouter}  from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

import $ from 'jquery'; 
import MetaTags from 'react-meta-tags';
	
 
class Civil extends React.Component {

	componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }
	  
  
    
	render() {
		return(

			<>

			<MetaTags>
				<title>Derecho Civil | Garlaw Abogados</title>
				<meta name="description" content="Garlaw Abogados te ofrece un asesoramiento integral y especializado en todos tus problemas legales en materia de derecho civil." />
				<link rel="canonical" href="https://www.garlawabogados.com/areas-legales/derecho-civil" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Derecho Civil | Garlaw Abogados" />
				<meta property="og:description" content="Garlaw Abogados te ofrece un asesoramiento integral y especializado en todos tus problemas legales en materia de derecho civil." />
				<meta property="og:url" content="https://www.garlawabogados.com/areas-legales/derecho-civil" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Garlaw Abogados te ofrece un asesoramiento integral y especializado en todos tus problemas legales en materia de derecho civil." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea" data-aos="zoom-in" data-aos-delay="100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 welcomeconoce">
								<div className = "welco-conoce">
									<h1>Derecho Civil</h1>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="areaas_legales">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7" data-aos="zoom-out" data-aos-delay="100">
                        <p>El derecho civil se puede entender como el conjunto de normas jurídicas que regula el estado civil de las personas, su patrimonio, las relaciones familiares, las obligaciones y contratos, la propiedad y demás derechos reales, así como las sucesiones.</p>
						<p>Desde Garlaw Abogados ofrecemos un asesoramiento especializado e integral en las distintas materias que abarca el derecho civil, apostando por las nuevas tecnologías a nuestro alcance ya que con ellas no solo se optimizan nuestros procesos, sino que se facilita la vida al cliente, nuestro centro gravitacional, favoreciendo la sostenibilidad del planeta, cuya importancia es fundamental. Además, siempre primamos la adopción de acuerdos antes de acudir a la vía judicial, Garlaw Abogados piensa en ti y en la solución a tu problema de la forma más rápida y sencilla.</p>
                        <img className="img-fluid" width="100%" height="100%" src="/assets/images/Garlaw_Civil.webp" alt="Garlaw Abogados Derecho Civil"/>
                        <span className="caption text-muted">Garlaw Abogados. La persona como nuestro centro gravitacional</span>
                        <p>Te ofrecemos asesoramiento integral sobre las siguientes materias:</p>
						<ul className="list-bullets">
                        	<li className="mb-lar">Reclamaciones de cantidad.</li>
							<li className="mb-lar">Adopción de medidas de apoyo a las personas con discapacidad para el ejercicio de su capacidad jurídica.</li>
							<li className="mb-lar">División de la Cosa Común.</li>
							<li className="mb-lar">Donaciones. </li>
							<li className="mb-lar">Indemnizaciones por daños y perjuicios.</li>
							<li className="mb-lar">Familia.</li>
							<li className="mb-lar">Sucesiones.</li>
							<li className="mb-lar">Obligaciones y contratos.</li>
							<li className="mb-lar">Arrendamientos.</li>
							<li className="mb-lar">Hipotecas.</li>
							<li className="mb-lar">Vicios constructivos.</li>
                    	</ul>
                    </div>
                </div>
            </div>
        </article>

		<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Tu Abogado a un <span>Paso</span></h2>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="100">
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-map-marker"></i>
							<h3>Visita Nuestras Oficinas</h3>
							<p>Madrid, Segovia, Avila</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-whatsapp"></i>
							<h3>Nuestros Canales Digitales</h3>
							<p>Llamada, Email, Videollamada, Whatsapp</p>
							</div>
						</div>
							<div className="text-center">
							<NavLink to="/contacto" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = "Contacto">
								<button>Contacta</button>
							</NavLink>
							</div>
						</div>
					</div>
    			</section>


		</>
		)
	}
}
export default withRouter(Civil);