import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Divorcio_Hijo extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>¿Qué puedo hacer si me expareja no me deja hablar con mi hijo?</title>
				<meta name="description" content="¿Qué puedo hacer si me expareja no me deja hablar con mi hijo?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-divorcio-contacto-hijos" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="¿Qué puedo hacer si me expareja no me deja hablar con mi hijo?" />
				<meta property="og:description" content="¿Qué puedo hacer si me expareja no me deja hablar con mi hijo?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-divorcio-contacto-hijos" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Qué puedo hacer si me expareja no me deja hablar con mi hijo?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Qué puedo hacer si me expareja no me deja hablar con mi hijo?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Noviembre 13, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> El 20 % de los divorcios o separaciones en España son contenciosos, de manera que las relaciones entre los miembros de la entonces pareja quedan muy mermadas y deterioradas. Normalmente suele utilizarse a los hijos en común como arma para hacer daño a la otra parte, impidiendo por ejemplo las comunicaciones con el otro progenitor en los meses de vacaciones. Pero ¿qué podemos hacer ante esto? 
                        <br/><br/><br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_divorcio_hijos.webp" width="100%" height="100%" alt="Contacto con los hijos en Divorcio"/>
                        <span className="caption text-muted">Garlaw Abogados. Comunicación con hijos en Divorcio.</span>
                        <p>
                        <br/><br/>
                        Son muchos los clientes que acuden a nuestro despacho poniendo de manifiesto que su expareja no permite que se pueda comunicar con los hijos en común durante el periodo que se encuentran en su compañía, con la angustia que esto supone, pues pueden pasar días, e incluso semanas, en los casos de los periodos vacacionales de verano, sin saber cómo está su hijo o dónde se encuentra.
                        <br/><br/>
                        Por eso, desde Garlaw Abogados siempre abogamos por un buen diálogo y entendimiento entre los progenitores, ya que estás situaciones solo perjudican al menor, que impide tener una relación fluida y normal con ambos progenitores con independencia de con quién se encuentre. Sin embargo, esto no suele ser lo normal, y al final los perjudicados en esta situación son los menores que se ven en medio de una guerra interna entre sus progenitores.
                        <br/><br/>
                        Llegada esta situación, se plantea la duda a nuestros clientes de que pueden hacer si su expareja no deja comunicarse con sus hijos cuando se encuentran en su compañía. 
                        <br/><br/>
                        En estos casos, lo primero a lo que hay que estar es a lo que se establezca en el Convenio Regulador. En muchas de las ocasiones, en el propio Convenio se prevé la obligación del progenitor en cuya compañía se encuentran, de permitir las comunicaciones con el otro progenitor, e incluso se prevé un horario a lo largo del día. Si dicha obligación es incumplida, el progenitor cuya comunicación se ve restringida podrá plantear un <span className = "p-bold_su">demanda de ejecución de lo previsto en el propio Convenio</span>. Para interponer esta demanda, es necesario poder acreditar que dicho incumplimiento ha tenido lugar, lo que en algunas ocasiones se hace verdaderamente difícil. 
                        <br/><br/>
                        Pero <span className = "p-bold">¿qué ocurre si en el Convenio no se dice nada de las comunicaciones del progenitor con su hijo cuando no se encuentre en su compañía?</span>
                        <br/><br/>
                        En este caso, será necesario interponer una <span className = "p-bold_su">demanda de modificación de medidas</span>, con la finalidad de que en el Convenio inicialmente aprobado se recoja las comunicaciones de los progenitores con sus hijos. 
                        <br/><br/>
                        <span className = "p-bold">¿Dónde se encuentra regulado el derecho de comunicación de los progenitores con sus hijos?</span>
                        <br/><br/>
                        En los artículos 94 y 160 del Código Civil. El  <span className = "p-bold">artículo 94</span> establece:
                        <br/><br/>
                        <span className = "p-curs">
                        “La autoridad judicial determinará el tiempo, modo y lugar en que el progenitor que no tenga consigo a los hijos menores podrá ejercitar el derecho de visitarlos, <span className = "p-bold">comunicar con ellos</span> y tenerlos en su compañía”.
                        </span>
                        <br/><br/>
                        Por su parte, el <span className = "p-bold">artículo 160</span> señala: <span className = "p-curs">“Los hijos menores <span className = "p-bold">tienen derecho a relacionarse con sus progenitores</span> aunque éstos no ejerzan la patria potestad, salvo que se disponga otra cosa por resolución judicial o por la Entidad Pública en los casos establecidos en el artículo 161”.</span>
                        <br/><br/>
                        <span className = "p-bold">¿Qué criterios utiliza los tribunales para establecer las comunicaciones con los progenitores?</span>
                        <br/><br/>
                        Para establecer un régimen de comunicación de los menores con sus progenitores, los tribunales suelen tener en cuenta la edad de los menores, sus carencias emocionales, la relación existente con el menor, así como las costumbres.
                        <br/><br/>
                        Si tienes más dudas sobre este tema no dudes en ponerte en contacto con nosotros, estaremos encantados de resolverlas. 
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/ZQqPIDy_0j0"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7129844680665513984/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/2605541772956320"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1724084483811352596"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Divorcio_Hijo);