import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_New_Horizontal extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Novedades Propiedad Horizontal | Garlaw Abogados</title>
				<meta name="description" content="Novedades en la Ley de Propiedad Horizontal. Descúbre las novedades en este post." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-novedades-propiedad-horizontal" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Novedades Propiedad Horizontal | Garlaw Abogados" />
				<meta property="og:description" content="Novedades en la Ley de Propiedad Horizontal. Descúbre las novedades en este post." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-novedades-propiedad-horizontal" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Novedades en la Ley de Propiedad Horizontal. Descúbre las novedades en este post." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Novedades en la Ley de Propiedad Horizontal</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Julio 20, 2022 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> La Ley 10/2022, de 14 de junio, de medidas urgentes para impulsar la actividad de rehabilitación edificatoria en el contexto del Plan de Recuperación, Transformación y Resiliencia, introduce una serie de modificaciones en la Ley 49/1960 de Propiedad Horizontal, con la finalidad de acelerar el proceso de descarbonización de los edificios residenciales para mejorar su eficiencia energética.</p>
                        <img className="img-fluid img-post" src="/assets/images/post_novedades_propiedad_horizontal.webp" width="100%" height="100%" alt="Post Propiedad Horizontal"/>
                        <span className="caption text-muted">Garlaw Abogados. Novedades en Propiedad Horizontal</span>
                        <p>
                        La referida Ley 10/2022 que entró en vigor el 16 de junio, promueve medidas urgentes para fomentar el proceso de rehabilitación ante la elevada antigüedad que reviste las edificaciones en el Estado español. El Preámbulo de la misma señala que más de la mitad de los edificios residenciales de nuestro país obedece a construcciones anteriores a 1980.
                        <br/><br/>
                        Ante esta situación la referida Ley otorga voz a las Comunidades de propietarios, las cuales podrán tomar decisiones relevantes respecto a la realización de obras que permitan lograr una mejora energética del inmueble en su conjunto. Para ello se prevé un régimen de mayoría simple para la adopción de los acuerdos que tengan por objeto la realización de obras de rehabilitación, así como para solicitar las ayudas y financiación que sean necesarias. No obstante, el importe de la derrama no puede superar 12 mensualidades/año de la cuota ordinaria, si se supera esta cuantía la mayoría tiene que ser de las tres quintas partes. 
                        <br/><br/>
                        También se prevé la posibilidad de que las Comunidades puedan deducirse hasta el 20% del coste de las obras realizadas, previéndose ayudas para la instalación de ascensores. Con esta nueva ley se ha logrado, como decimos, que las Comunidades de Propietarios tengan voz, pues se las permiten que accedan a la contratación de proyectos de rehabilitación y regeneración urbana, así como para solicitar créditos y subvenciones destinadas a las mismas.
                        <br/><br/>
                        Otra importante novedad introducida es que el propietario disidente a estas obras deberá contribuir a las mismas, pues tendrán la consideración de gastos generales de la Comunidad de Propietarios. 
                        <br/><br/>
                        Como se puede observar, el legislador ha allanado el terreno para que no exista ningún inconveniente o límite para la realización de obras que contribuyan a la rehabilitación del edificio y, por tanto, a su eficiencia energética. Con ello, quiere conseguir los objetivos marcados en la Agenda 2030, acelerando el proceso de descarbonización de los edificios residenciales.
                        <br/><br/>
                        También se adoptan medidas en cuanto a posibles situaciones de impago por parte de comuneros morosos, pudiendo solucionarse estos conflictos extrajudicialmente mediante arbitraje o mediación, o judicialmente, mediante el procedimiento monitorio especial de reclamación de deudas.
                        <br/><br/>
                        Las principales novedades introducidas son las siguientes:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	    La generación automática de los intereses moratorios una vez vencido el crédito comunitario sin necesidad de requerimiento previo.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	    La posibilidad que tienen las Comunidades de sancionar al comunero moroso, privándole del uso de instalación o elementos comunes que no sean esenciales.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	    Igualmente, la posibilidad de establecer intereses superiores al interés del dinero a los comuneros morosos, siempre que no sean desproporcionados.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	    Se modifica el proceso monitorio especial, pues se permite reclamar futuras deudas comunitarias a las vencidas, si bien limitado al momento de notificación del acuerdo liquidatario al comunero moroso. Además, se establece la posibilidad de reclamar los gastos y costes que conlleve la reclamación, incluidos los del secretario-administrador.
                        <br/><br/>
                        Por tanto, son importantes las modificaciones introducidas por la Ley 10/2022 en materia de Propiedad Horizontal, encaminadas a mejorar la eficiencia energética. Desde Garlaw Abogados ofrecemos un asesoramiento especializado en materia de Propiedad Horizontal, para resolverte estas y otras cuestiones, no dudes en ponerte en contacto con nosotros. 
						</p>
						
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/TKLeu_0qw3M"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:activity:6955853729782751233/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1550088024737849345"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_New_Horizontal);