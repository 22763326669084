import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Tipo_Divorcios extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Tipos de Divorcio en España | Garlaw Abogados</title>
				<meta name="description" content="Tipos de Divorcio en España" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-tipos-de-divorcios" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Tipos de Divorcio en España | Garlaw Abogados" />
				<meta property="og:description" content="Tipos de Divorcio en España" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-tipos-de-divorcios" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Tipos de Divorcio en España" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Tipos de Divorcio en España</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Noviembre 22, 2022 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> En cuanto a divorcios se refiere, la tendencia en lo que va de 2022 es a la baja y se espera que esta cifra se reduzca, motivado principalmente en el coste económico que ello supone y que hoy día son muchas las parejas que deciden no casarse. La edad media se sitúa entorno a los 45 años y la duración del matrimonio gira en torno a los 16 años de media. Pero llegado el momento de divorciarse, ¿sabemos qué tipos hay?</p>
                        <img className="img-fluid img-post" src="/assets/images/post_tipos_divorcio.webp" width="100%" height="100%" alt="Post Medidas de Apoyo a las Personas con Discapacidad"/>
                        <span className="caption text-muted">Garlaw Abogados. Tipos de Divorcio en España</span>
                        <p>
                        Tradicionalmente solo podíamos hablar de divorcio contencioso y divorcio de mutuo acuerdo, hoy en día, además de estos tipos nos encontramos con el llamado divorcio exprés ante notario en escritura pública. 
                        <br/><br/>
                        Vamos a ver cada uno de ellos:
                        <br/><br/>
                        <span className = "p-bold">1-	DIVORCIO EXPRÉS ANTE NOTARIO: </span>
                        <br/><br/>
                        Es el método más rápido y económico de disolver el matrimonio. Esta novedad fue introducida por la Ley de Jurisdicción Voluntaria, de 2 de julio de 2015. Los requisitos son los siguientes:
                        <br/><br/>
                        -que exista mutuo acuerdo entre los cónyuges.
                        <br/><br/>
                        -que hayan trascurrido tres meses desde la celebración del matrimonio.
                        <br/><br/>
                        -que no exista hijos menores de edad. De haber hijos mayores de edad, solo en el caso de que les afecten las medidas a adoptar, deberán comparecer ante Notario y firmar junto a sus padres.
                        <br/><br/>
                        -redacción de un convenio regulador que deberá contener las medidas que regularán los efectos derivados del divorcio.
                        <br/><br/>
                        -designación de un abogado.
                        <br/><br/> 
                        -será competente el Notario del último domicilio común, o el del lugar de residencia habitual de cualquiera de los esposos.
                        <br/><br/>
                        <span className = "p-bold">2-	DIVORCIO DE MUTUO ACUERDO:</span>
                        <br/><br/>
                        Cuando existen hijos en común este es el más recomendable, no obstante, también podrá llevarse a cabo, aunque no se tengan hijos en común. Se inicia mediante la interposición de una demanda firmada por ambos cónyuges, o bien, puede presentar la demanda uno solo de ellos cuando disponga del consentimiento firmado del otro. 
                        <br/><br/>
                        Al ser de mutuo acuerdo, ambas partes pueden valerse del mismo abogado y procurador, pero ojo, si existieran luego controversias en relación con las medidas adoptadas en el convenio, dicho abogado y procurador no podrá representar separadamente a ninguno de los cónyuges, al existir un conflicto de intereses. 
                        <br/><br/>
                        Una vez que la demanda sea admitida, los cónyuges seran citados para que ratifiquen, por separado, su deseo de divorciarse. Cuando existan hijos menores de edad, el Ministerio Fiscal velara para que las medidas adoptadas en el convenio no les perjudique.
                        <br/><br/>
                        Los requisitos de este tipo de divorcio son:
                        <br/><br/>
                        -que exista mutuo acuerdo entre los cónyuges.
                        <br/><br/>
                        -que hayan trascurrido tres meses desde la celebración del matrimonio.
                        <br/><br/>
                        -redacción de un convenio regulador que deberá contener las medidas que regularán los efectos derivados del divorcio.
                        <br/><br/>
                        -designación de un abogado y procurador.
                        <br/><br/>
                        <span className = "p-bold">3-	DIVORCIO CONTENCIOSO:</span>
                        <br/><br/>
                        Es la opción menos recomendable, pero la única cuando las partes no llegan a un acuerdo. Cada parte deberá estar representada con un procurador y defendida por un abogado, de manera que cada parte deberá sostener en su demanda o contestación sus propias pretensiones en cuanto a las medidas a adoptar en relación a los hijos menores, como pueden ser la guarda y custodia, la pensión de alimentos, o medidas como la atribución del uso del domicilio familiar, el requerimiento de una pensión compensatoria, etc.
                        <br/><br/>
                        En este tipo de divorcio será el Juez quien decida las medidas del convenio regulador. Evidentemente, este tipo de divorcio no solo es más costoso económicamente, sino a nivel emocional, pues puede alargarse meses e incluso años. 
                        <br/><br/>
                        <br/><br/>
                        Desde Garlaw Abogados siempre apostamos por un entendimiento entre las partes, pues no sólo supone menos costes económicos, sino emocionales, especialmente cuando hay hijos menores de edad, toda vez que el contacto entre cónyuges para tomar decisiones trascendentales para la vida de los mismos será constante hasta que alcancen la mayoría de edad, de manera que, si las relaciones son lo más fluidas y con el mayor respeto posible, redundan en el beneficio del menor. 
                        <br/><br/>
                        Si todavía te quedan dudas o requieres de un asesoramiento especializado, ponte en contacto con nosotros. Garlaw Abogados te pone las cosas fáciles. Te estamos esperando.
                        <br/><br/>
                        </p>						
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            <li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/DJ0sGPR3nek"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:activity:7000832503108276227"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1595064494425776129"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Tipo_Divorcios);