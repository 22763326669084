import React  from 'react';
import {NavLink, withRouter}  from "react-router-dom";

import '../componentes.css'; 
 
import AOS from 'aos';
import 'aos/dist/aos.css';

import $ from 'jquery';
import MetaTags from 'react-meta-tags'; 
	
 
class Procesal extends React.Component {

	componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }
	  
  
    
	render() {
		return(

			<>

			<MetaTags>	
				<title>Derecho Procesal Civil | Garlaw Abogados</title>
				<meta name="description" content="Si estás pensando en interponer una reclamación judicial o has sido demandado, no te asustes, Garlaw Abogados se encargará de todo." />
				<link rel="canonical" href="https://www.garlawabogados.com/areas-legales/derecho-procesal-civil" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Derecho Procesal Civil | Garlaw Abogados" />
				<meta property="og:description" content="Si estás pensando en interponer una reclamación judicial o has sido demandado, no te asustes, Garlaw Abogados se encargará de todo." />
				<meta property="og:url" content="https://www.garlawabogados.com/areas-legales/derecho-procesal-civil" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Si estás pensando en interponer una reclamación judicial o has sido demandado, no te asustes, Garlaw Abogados se encargará de todo." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea" data-aos="zoom-in" data-aos-delay="100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 welcomeconoce">
								<div className = "welco-conoce">
									<h1>Derecho Procesal Civil</h1>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="areaas_legales">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7" data-aos="zoom-out" data-aos-delay="100">
                        <p>El derecho procesal civil se puede definir como el conjunto de normas que regulan el proceso judicial civil. Aunque desde Garlaw Abogados tenemos la máxima de garantizar soluciones legales intentado evitar la vía judicial, a veces no es posible, de manera que es necesario acudir a la misma para resolver el asunto planteado. El derecho procesal es fundamental para conseguir la satisfacción de nuestros clientes cuando la vía extrajudicial no ha funcionado, su conocimiento es una de las piezas angulares de nuestro trabajo, y nuestra experiencia nos avala.</p>
                        <img className="img-fluid" src="/assets/images/Garlaw_Procesal.webp" width="100%" height="100%" alt="Garlaw Abogados Derecho Procesal"/>
                        <span className="caption text-muted">Garlaw Abogados. Derecho Procesal Civil</span>
                        <p>Te prestamos los siguientes servicios:</p>
						<ul className="list-bullets">
                        	<li className="mb-lar">Redacción de Demandas y contestación a las mismas.</li>
							<li className="mb-lar">Escritos de Trámite.</li>
							<li className="mb-lar">Recursos de Apelación.</li>
							<li className="mb-lar">Ejecuciones.</li>
							<li className="mb-lar">Ejecuciones Dinerarias.</li>
							<li className="mb-lar">Ejecuciones de Obligaciones de Hacer.</li>
							<li className="mb-lar">Ejecuciones de Obligaciones de No Hacer.</li>
                    	</ul>
                    </div>
                </div>
            </div>
        </article>

		<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Tu Abogado a un <span>Paso</span></h2>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="100">
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-map-marker"></i>
							<h3>Visita Nuestras Oficinas</h3>
							<p>Madrid, Segovia, Avila</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-whatsapp"></i>
							<h3>Nuestros Canales Digitales</h3>
							<p>Llamada, Email, Videollamada, Whatsapp</p>
							</div>
						</div>
							<div className="text-center">
							<NavLink to="/contacto" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = "Contacto">
								<button>Contacta</button>
							</NavLink>
							</div>
						</div>
					</div>
    			</section>


		</>
		)
	}
}
export default withRouter(Procesal);