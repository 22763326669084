import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Vicios_Constructivos extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Vicios Constructivos | Garlaw Abogados</title>
				<meta name="description" content="Vicios Constructivos" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-vicios-constructivos" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Vicios Constructivos | Garlaw Abogados" />
				<meta property="og:description" content="Post Vicios Constructivos" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-vicios-constructivos" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Vicios Constructivos" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Vicios Constructivos</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Abril 18, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> El 95% de los inmuebles que se entregan tienen más de 10 reparaciones pendientes. Las reclamaciones por este tipo de vicios han ido aumentado los últimos años derivados principalmente del boom del ladrillo. Pero ¿qué son los vicios constructivos? 
                        <br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_vicios_constructivos.webp" width="100%" height="100%" alt="Post Vicios Constructivos"/>
                        <span className="caption text-muted">Garlaw Abogados. Vicios Constructivos</span>
                        <p>
                        Los vicios constructivos se pueden definir como los defectos detectados en las nuevas edificaciones que pueden afectar a una parte o la totalidad de las mismas. Su regulación la encontramos principalmente en la Ley de Ordenación de la Edificación 38/1999, de 5 de noviembre, en ella se establece el tipo de vicios o defectos constructivos, la responsabilidad de los agentes constructivos y los plazos de garantía. 
                        <br/><br/>
                        Entrando en materia, los tipos de vicios constructivos son los siguientes:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">1-	Defectos de remate, terminación o acabado:</span> son daños que afectan a los elementos de terminación o acabado. No se ajustan a la calidad y acabado contratado, y, por lo tanto, son plenamente reclamables. Tienen un plazo de garantía de un año. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">2-	Defectos de habitabilidad, funcionalidad o seguridad:</span> lo que se viene denominando como ruina funcional. Se trata de defectos que impiden un uso satisfactorio de la edificación, y, por lo tanto, afectan a la habitabilidad. Los ejemplos típicos de estos defectos son humedades, malos olores, goteras, problemas de impermeabilización general, aislamiento término… Su plazo de garantía es de tres años.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">3-	Defectos estructurales:</span> lo que se viene denominando como ruina física. Este tipo de defectos afectan a elementos estructurales que comprometen la seguridad del inmueble. Cuando se afecta por ejemplo a los muros de carga o vigas. Su plazo de garantía es de 10 años.
                        <br/><br/>
                        ¿Qué significa el plazo de garantía? Implica que los defectos o vicios deberán manifestarse en ese plazo desde la entrega o recepción de la edificación, es decir, desde el certificado final de obra o acta de recepción de la misma. (artículo 6.5 de la Ley de Ordenación de la Edificación). Si los defectos aparecen trascurrido dicho plazo no podremos reclamarlo con base a la referida Ley.
                        <br/><br/>
                        <span className = "p-bold_su">En consecuencia, es necesario que el vicio o defecto se produzca en el plazo de garantía, y que una vez que se manifieste en dicho periodo, se ejercite la acción dentro del plazo de dos años.</span> Esto constituye el plazo de prescripción de la acción, el cual podrá ser interrumpido si existe un requerimiento fehaciente dirigido a todos los agentes constructivos, incluido el promotor.
                        <br/><br/>
                        Por tanto, la garantía y la prescripción son dos instituciones jurídicas diferentes, y en este sentido se ha pronunciado el Tribunal Supremo mediante Sentencia nº 166/2020 de once de marzo. Los vicios o defectos deberán manifestarse dentro de los plazos de garantía fijados por la ley, debiendo reclamarse en el plazo de dos años desde su aparición, pudiendo interrumpirse, como ya hemos apuntado, este último.
                        <br/><br/>
                        Pero ¿cuáles son las acciones para reclamar la existencia de vicios constructivos?
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span> Por incumplimiento contractual: el comprador tendrá derecho a exigir el cumplimiento del contrato, de acuerdo con el artículo 1124 del código Civil. La jurisprudencia del Tribunal Supremo admite la compatibilidad de la acción de ruina funcional del artículo 1591 del Código Civil con la del cumplimiento o resolución contractual del artículo 1124 o incumplimiento defectuoso del artículo 1101 del Código Civil. (STS de 22 octubre 2012, 27 diciembre 2013 y 18 diciembre 2018, entre otras.)
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span> Acción de ruina funcional del artículo 1591 del Código civil.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span> La acción propia que concede la Ley de Ordenación de la Edificación, ex artículo 17.
                        <br/><br/>
                        Una vez aclarados los tipos de vicios o defectos, los plazos de garantía, las acciones a ejercer y los plazos de prescripción, nos preguntamos ¿cuál es la responsabilidad de los agentes constructivos?
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">1-	Promotor:</span>  artículo 9 de la Ley de Ordenación de la Edificación:
                        <br/><br/>
                        Será considerado promotor cualquier persona, física o jurídica, pública o privada, que, individual o colectivamente, decide, impulsa, programa y financia, con recursos propios o ajenos, las obras de edificación para sí o para su posterior enajenación, entrega o cesión a terceros bajo cualquier título.
                        <br/><br/>
                        Según lo dispuesto en el artículo 17.3 de la Ley de Ordenación de la Edificación, el promotor debe responder solidariamente con los demás agentes intervinientes ante los diversos defectos constructivos que pudieran aparecer en la vivienda entregada. A la figura del promotor se equiparán también las de gestor de cooperativas o de comunidades de propietarios, u otras análogas que aparecen cada vez con mayor frecuencia en la gestión económica de la edificación.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">2-	Proyectista:</span> artículo 10 de la Ley de Ordenación de la Edificación:
                        <br/><br/>
                        El proyectista es el agente que, por encargo del promotor y con sujeción a la normativa técnica y urbanística correspondiente, redacta el proyecto. 
                        <br/><br/>
                        Los proyectistas que contraten los cálculos, estudios, dictámenes o informes de otros profesionales, serán directamente responsables de los daños que puedan derivarse de su insuficiencia, incorrección o inexactitud, sin perjuicio de la repetición que pudieran ejercer contra sus autores.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">3-	Constructor:</span> artículo 11 de la Ley de Ordenación de la Edificación:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. El constructor es el agente que asume, contractualmente ante el promotor, el compromiso de ejecutar con medios humanos y materiales, propios o ajenos, las obras o parte de las mismas con sujeción al proyecto y al contrato.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Son obligaciones del constructor:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a) Ejecutar la obra con sujeción al proyecto, a la legislación aplicable y a las instrucciones del director de obra y del director de la ejecución de la obra, a fin de alcanzar la calidad exigida en el proyecto.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b) Tener la titulación o capacitación profesional que habilita para el cumplimiento de las condiciones exigibles para actuar como constructor.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c) Designar al jefe de obra que asumirá la representación técnica del constructor en la obra y que por su titulación o experiencia deberá tener la capacitación adecuada de acuerdo con las características y la complejidad de la obra.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;d) Asignar a la obra los medios humanos y materiales que su importancia requiera.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;e) Formalizar las subcontrataciones de determinadas partes o instalaciones de la obra dentro de los límites establecidos en el contrato.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;f) Firmar el acta de replanteo o de comienzo y el acta de recepción de la obra.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;g) Facilitar al director de obra los datos necesarios para la elaboración de la documentación de la obra ejecutada.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;h) Suscribir las garantías previstas en el artículo 19.
                        <br/><br/>
                        Cuando el constructor subcontrate con otras personas físicas o jurídicas la ejecución de determinadas partes o instalaciones de la obra, será directamente responsable de los daños materiales por vicios o defectos de su ejecución, sin perjuicio de la repetición a que hubiere lugar.
                        <br/><br/>
                        Conforme al último párrafo del art. 17.1 LOE los “vicios o defectos de ejecución que afecten a elementos de terminación o acabado de las obras” son imputables exclusivamente al constructor.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">4-	Director de obra:</span> artículo 12 de la Ley de Ordenación de la Edificación:
                        <br/><br/>
                        El director de obra es el agente que, formando parte de la dirección facultativa, dirige el desarrollo de la obra en los aspectos técnicos, estéticos, urbanísticos y medioambientales, de conformidad con el proyecto que la define, la licencia de edificación y demás autorizaciones preceptivas y las condiciones del contrato, con el objeto de asegurar su adecuación al fin propuesto. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">5-	Director de la ejecución de la obra:</span> artículo 13 de la Ley de Ordenación de la Edificación:
                        <br/><br/>
                        También denominado arquitecto técnico. El director de la ejecución de la obra es el agente que, formando parte de la dirección facultativa, asume la función técnica de dirigir la ejecución material de la obra y de controlar cualitativa y cuantitativamente la construcción y la calidad de lo edificado.
                        <br/><br/>
                        Como se puede observar, la existencia de vicios constructivos en una edificación da lugar a una casuística muy compleja, por eso desde Garlaw Abogados siempre aconsejamos contar con un asesoramiento jurídico desde el principio. Si tienes más dudas sobre este tema no dudes en ponerte en contacto con nosotros, te ponemos las cosas fáciles.
                        </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/WhAjx5ANlCI"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7054124059562835968/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/9046232452113373"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1648357700445536258"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Vicios_Constructivos);