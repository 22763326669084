import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Cuestiones_Compraventa extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Cuestiones a tener en cuenta en una compraventa</title>
				<meta name="description" content="Cuestiones a tener en cuenta en una compraventa" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-cuestiones-compraventa" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Cuestiones a tener en cuenta en una compraventa" />
				<meta property="og:description" content="Cuestiones a tener en cuenta en una compraventa" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-cuestiones-compraventa" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Cuestiones a tener en cuenta en una compraventa" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Cuestiones a tener en cuenta en una compraventa</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Octubre 8, 2024 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> ¿Estás pensando en comprarte una vivienda y no sabes por dónde empezar? Desde Garlaw Abogados queremos ayudarte, es por eso por lo que en primer lugar siempre recomendamos que no se firme nada que no se entienda o con lo que no se esté de acuerdo, contar con un asesoramiento adecuado desde el principio puede evitar problemas mayores a la hora de realizar la compraventa. Es por eso por lo que te dejamos unos consejos a tener en cuenta si decides comprar una vivienda.
                        <br/><br/><br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_cuestiones_compraventa.webp" width="100%" height="100%" alt="Cuestiones a tener en cuenta en una compraventa"/>
                        <span className="caption text-muted">Garlaw Abogados Cuestiones a tener en cuenta en una compraventa</span>
                        <p>
                        La compra de una vivienda es una de las decisiones más importantes en la vida, es por eso, por lo que el proceso suele ser largo y muchas veces complicado, con números trámites legales y documentos, que a menudo no se entienden en su totalidad, es por eso por lo que te ofrecemos en este post las principales cuestiones que debes tener en cuenta si decides comprar una vivienda:
                        <br/><br/>
                        <span className = "p-bold_su">1-	Paso previo a la compraventa: Información detallada del inmueble. </span>
                        <br/><br/>
                        En primer lugar, debemos cerciorarnos de la titularidad del inmueble, del estado del mismo, de la existencia de cargas y gravámenes que pesan sobre él y del cumplimiento por parte del vendedor de todas las obligaciones relacionadas con el inmueble. 
                        <br/><br/>
                        Para eso es necesario solicitar la siguiente documentación:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	<span className = "p-subr">Nota simple del inmueble:</span> en la misma nos permitirá saber la titularidad del inmueble, la descripción de este, así como la existencia de cargas y gravámenes sobre el mismo. Si forma parte de un edificio sujeto a propiedad horizontal, nos permitirá saber la cuota de participación que le corresponde. 
                        <br/><br/>
                        Si, además, junto con la vivienda, se nos enseña trastero y garaje, podremos comprobar, si las mismas son anejas a la vivienda, o por el contrario son fincas registrales independientes, y de esa forma deberán costar en la posterior escritura de compraventa notarial.
                        <br/><br/>
                        Este documento es de suma importancia antes de decidir comprar una vivienda, debiendo solicitarse en todo caso, si no queremos llevarnos sorpresas inesperadas.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	<span className = "p-subr">Certificado de eficiencia energética:</span> este documento deberá estar en poder del vendedor y deberá unirse a la escritura de compraventa, ya que determina que la vivienda sea habitable.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	<span className = "p-subr">Cédula de habitabilidad o licencia de primera ocupación. </span>
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	<span className = "p-subr">Estatutos de la Comunidad de Propietarios y Certificado de estar al corriente del pago de las cuotas:</span> en el supuesto de que el inmueble forme parte de un edificio sujeto al régimen de propiedad horizontal, es importante conocer de antemano los Estatutos de la Comunidad, y que el Secretario-Administrador de la Comunidad de Propietarios, certifique que el vendedor está al corriente del pago de las cuotas, pero ya no solo ordinarias, sino también extraordinarias, debiendo informarnos si hay alguna derrama a futuro inmediato, ya que es una de las muchas sorpresas que nos podemos encontrar.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	<span className = "p-subr">Último recibo del Impuesto de Bienes Inmuebles (IBI):</span> de manera que el vendedor este al corriente de sus obligaciones fiscales.
                        <br/><br/>
                        <span className = "p-bold_su">2-	Contrato de Arras. </span>
                        <br/><br/>
                        Aunque no es obligatorio, desde Garlaw Abogados siempre aconsejamos la firma de un contrato de arras entre las partes, como garantía del otorgamiento posterior del contrato de compraventa, especialmente en los casos en que la compraventa está supeditada a la concesión de un préstamo hipotecario a favor del comprador, lo que determina una demora mayor en el tiempo. 
                        <br/><br/>
                        En dicho contrato, deberemos fijarnos que se recogen la descripción exacta del inmueble y de los términos acordados para la posterior compraventa de la vivienda. Os remitimos al artículo de nuestro blog, donde se habla exclusivamente de este contrato para mayor información. 
                        <br/><br/>
                        <span className = "p-bold_su">3-	Contrato o Escritura Pública de Compraventa. </span>
                        <br/><br/>
                        El mismo puede realizarse en un documento privado o en escritura pública notarial. Siempre aconsejamos que se realice en escritura pública ya que con ello se permite que pueda acceder al Registro de la Propiedad, pero ello no impide la compraventa sea válida si se realiza en documento privado. 
                        <br/><br/>
                        No obstante, en la práctica habitual en el contrato de arras se fija un plazo para el otorgamiento de escritura pública notarial, siendo el comprador quién elige la notaría para realizar la firma, pero como decimos esto no es requisito para su validez.
                        <br/><br/>
                        <span className = "p-bold_su">4-	Gastos notariales, registrales e Impuestos. </span>
                        <br/><br/>
                        Normalmente, tanto los gastos como los impuestos se van a hacer costar en el contrato de arras. No obstante, también deberá recogerse en el contrato de compraventa o en la escritura pública. 
                        <br/><br/>
                        Respecto de los impuestos, el vendedor estará sujeto al pago del Impuesto de Plusvalía en aquellos municipios en los que se establezca, ya que hay en algunos en los que no existe.  En cambio, el comprador deberá abonar el Impuesto de Valor Añadido (IVA) si se trata de una vivienda de nueva construcción, o el Impuesto de Transmisiones Patrimoniales si es de segunda mano. 
                        <br/><br/>
                        En relación a los gastos notariales y registrales habrá que estar a lo pactado entre las partes, en la práctica, lo frecuente, es que lo asuma el comprador, salvo los gastos notariales y registrales de cancelación de la hipoteca a nombre del vendedor, el cual lo sumirá éste íntegramente. 
                        <br/><br/>
                        Como se puede observar, son muchas las cuestiones a tener en cuenta en una compraventa, es por eso, por lo que siempre aconsejamos contar desde el inicio con un asesoramiento legal y especializado en  Derecho Inmobiliario, ya que con ello se pueden evitar problemas mayores.   
                        <br/><br/>
                        Si tienes más dudas sobre este tema o quieres que analicemos tu caso en concreto, no dudes en ponerte en contacto con nosotros. Te estamos esperando.

                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                        <li><a title = "Garlaw Abogados Youtube" className= "youtube" href=" https://youtu.be/vAAlcUEU3II"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7249428479849467904/?actorCompanyId=82083167"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/277705815437530"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href=" https://x.com/garlawabogados/status/1843666984782966822"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Cuestiones_Compraventa);