import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Pension_Verano extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>¿Debo pagar la pensión de alimentos en las vacaciones de verano?</title>
				<meta name="description" content="¿Debo pagar la pensión de alimentos en las vacaciones de verano?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-pension-alimentos-vacaciones" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="¿Debo pagar la pensión de alimentos en las vacaciones de verano?" />
				<meta property="og:description" content="¿Debo pagar la pensión de alimentos en las vacaciones de verano?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-pension-alimentos-vacaciones" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Debo pagar la pensión de alimentos en las vacaciones de verano?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Debo pagar la pensión de alimentos en las vacaciones de verano?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Octubre 10, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Muchos progenitores divorciados o separados se hacen la pregunta de si deben seguir abonando la pensión de alimentos en el periodo estival cuando van a pasarlo en su compañía. La Respuesta es SI, pues de lo contrario estarían incurriendo en un incumplimiento de lo estipulado en el convenio regulador o lo fijado por Sentencia, e incluso en un delito de abandono de familia. Vamos a explicarte porque la jurisprudencia llega a esa conclusión.
                        <br/><br/><br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_pension_verano.webp" width="100%" height="100%" alt="Pensión de Alimentos en Verano"/>
                        <span className="caption text-muted">Garlaw Abogados. Pensión de Alimentos en Vacaciones de Verano</span>
                        <p>
                        <br/><br/>
                        Muchos clientes acuden a nuestro despacho preguntando si están obligados a seguir pasando la pensión de alimentos en los periodos vacacionales, ya sea de verano, navidades o semana santa, ya que consideran que si lo disfrutan en su compañía es injusto seguir abandonado la misma pues podría suponer un enriquecimiento injusto del otro progenitor. Sin embargo, esto no es así.
                        <br/><br/>
                        Salvo que en el Convenio Regulador aprobado por Sentencia se haya estipulado específicamente esta circunstancia, el progenitor obligado a pasar una pensión de alimentos debe seguir abonándola aunque sus hijos se encuentren en su compañía en vacaciones. 
                        <br/><br/>
                        La razón de esta circunstancia se encuentra en que la pensión de alimentos es una prestación única tendente a cubrir todos los gastos para la subsistencia y desarrollo de los hijos, fijándose de forma anual y no mensual, aunque su abono se prorratee por meses. De esta forma se entiende, que unos meses se compensan con otros. 
                        <br/><br/>
                        En este sentido se ha pronunciado la jurisprudencia, entre otras podemos mencionar la <span className = "p-bold_su">SENTENCIA DE LA AUDIENCIA PROVINCIAL DE BURGOS de 30 de mayo de 2000</span>, la cual dispone:
                        <br/><br/>
                        <span className = "p-curs">“se aprobó por el Juez que la pensión por alimentos para los hijos se abonaría por el padre con carácter mensual, sin excepción alguna, a pesar de que en el mismo convenio, (…) Pero, además, resulta que cuando se establece este tipo de pensión, no obstante fijarse pagos mensuales, en atención a lo previsto en el artículo 146 del CC, <span className = "p-subr">la cantidad concedida debe computarse globalmente (con referencia a una anualidad), pues los medios del obligado y las necesidades del alimentista se valoran con esa referencia temporal, ya que es evidente que ni unos ni otros son fijos mes a mes</span>; sin que a falta de previsión especifica al respecto, incida en la obligación de abonar la pensión alimenticia, el régimen de visitas establecido en relación con los hijos menores, porque dicho régimen así como el resto de las medidas aprobadas en la sentencia tiene que haber sido valoradas para fijar la pensión”.
                        </span>
                        <br/><br/>
                        O la <span className = "p-bold_su">SENTENCIA DE LA AUDIENCIA PROVINCIAL DE GUADALAJARA de 15 de octubre de 2003</span>, la cual dispone:
                        <br/><br/>
                        <span className = "p-curs">
                        “que la pensión alimenticia es una prestación única tendente a cubrir los gastos de todo tipo precisos para la subsistencia y adecuado desarrollo de los hijos, tales como vivienda, alimentación, cuidado, vestido, educación, sanidad, ocio, etc., tratándose de una cantidad que no se fija en función de unos gastos mensuales determinados, sino de una suma anual alzada que, por regla general, se divide en doce mensualidades, con inclusión, por tanto, de la correspondiente al mes de vacaciones que los hijos pasan con el obligado al pago”.
                        </span>
                        <br/><br/>
                        En consecuencia, el progenitor obligado a abonar la pensión de alimentos debe hacerlo aunque el hijo se encuentre con él de vacaciones. 
                        <br/><br/>
                        Pero <span className = "p-bold">¿qué ocurre si no abona la pensión?</span>
                        <br/><br/>
                        En este caso puede incurrir en una doble responsabilidad, por un lado, desde el punto de vista penal, y por otro, desde el punto de vista civil.
                        <br/><br/>
                        Penalmente, cometería un delito de abandono de familia tipificado en el artículo 227 del Código Penal, el cual dispone:
                        <br/><br/>
                        <span className = "p-curs">
                        “1. El que dejare de pagar durante <span className = "p-bold_su">dos meses consecutivos o cuatro meses no consecutivos</span> cualquier tipo de prestación económica en favor de su cónyuge o sus hijos, establecida en convenio judicialmente aprobado o resolución judicial en los supuestos de separación legal, divorcio, declaración de nulidad del matrimonio, proceso de filiación, o proceso de alimentos a favor de sus hijos, será castigado con la pena de prisión de tres meses a un año o multa de seis a 24 meses.
                        <br/><br/>
                        2. Con la misma pena será castigado el que dejare de pagar cualquier otra prestación económica establecida de forma conjunta o única en los supuestos previstos en el apartado anterior.
                        <br/><br/>
                        3. La reparación del daño procedente del delito comportará siempre el pago de las cuantías adeudadas.”
                        </span><br/><br/>
                        Civilmente, incurriría en un incumplimiento del convenio regulador o de la Sentencia aprobada, que daría lugar a una demanda de ejecución por parte del otro progenitor.
                        <br/><br/>
                        Pero <span className = "p-bold">¿quién decide cómo reclamar el pago de la pensión?</span>
                        <br/><br/>
                        Será el otro progenitor quien decida porque vía proceder a reclamar el impago de la pensión de alimentos, si por la vía civil o por la vía penal.
                        <br/><br/>
                        <br/><br/>
                        Por eso desde Garlaw Abogados siempre recomendamos a nuestros clientes proceder al pago de la pensión de alimentos de forma escrupulosa con sus correspondientes actualizaciones, pues de lo contrario se pueden ver inmersos en un procedimiento judicial cuya defensa es difícil y les llevaría aparejados más costes que el simple pago de la pensión. Si tienes más dudas sobre este tema no dudes en ponerte en contacto con nosotros, estaremos encantados de resolverlas.
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/Ms9qJ8WoKo8"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7117512832492957696/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/595974179220852"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1711745709567860797"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Pension_Verano);