import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Claves_Alquiler extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Claves en los Contratos de Alquiler | Garlaw Abogados</title>
				<meta name="description" content="Claves en los Contratos de Alquiler" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-vicios-constructivos" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Claves en los Contratos de Alquiler | Garlaw Abogados" />
				<meta property="og:description" content="Post Claves en los Contratos de Alquiler" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-claves-contratos-alquiler" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Post Claves en los Contratos de Alquiler" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>TODAS LAS CLAVES DE LOS CONTRATOS DE ALQUILER</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Mayo 16, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Cada día son más las personas que optan por el alquiler frente a la compra, en buena parte motivado por la crisis económica, y, sobre todo, por la dificultad existente en las grandes ciudades de acceso a la compra de vivienda.  
                        <br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_claves_alquiler.webp" width="100%" height="100%" alt="Post Claves Alquiler"/>
                        <span className="caption text-muted">Garlaw Abogados. Claves en los Contratos de Alquiler</span>
                        <p>
                        El alquiler avanza en España y ya alcanza el 23% de la población, acercándose a la media de la Unión Europea. Dado la importancia y trascendencia que tiene tanto para el arrendador, como para el arrendatario, la firma de un contrato de arrendamiento, es por lo que desde Garlaw Abogados ofrecemos los principales claves del mismo para evitar futuros problemas.
                        <br/><br/>
                        <span className = "p-bold">¿El arrendamiento puede ser verbal?</span>
                        <br/><br/>
                        Sí, no existe ninguna obligación legal que determine que el arrendamiento deba constar por escrito, sin embargo, es lo más conveniente. Con ello, no solo tenemos una prueba cierta de la existencia de este, sino cuales son las condiciones pactadas. En el caso del que el arrendamiento sea verbal, y exista un problema en relación con el mismo, entre arrendador y arrendatario, será mucho más complicado la resolución de la discordia existente.
                        <br/><br/>
                        <span className = "p-bold">¿Qué extremos debe contener el contrato de arrendamiento?</span>
                        <br/><br/>
                        De nuevo, no existe ninguna norma legal que determine que extremos debe recoger el contrato de arrendamiento, pues en lo no expresamente pactado se regirá por la Ley de Arrendamientos Urbanos. No obstante, es conveniente que como mínimo, conste en el contrato los siguientes extremos:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Nombre, apellidos, DNI y domicilio del arrendador. Si es persona jurídica, nombre de la sociedad, CIF y la persona que actúa en su representación para la firma del contrato.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Datos del inmueble a arrendar y cuál va a ser su destino, vivienda o uso distinto del de vivienda. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Duración del arrendamiento y sus prórrogas. Si el inmueble se destina a vivienda el plazo de duración mínima que establece la ley, será de 5 años si el arrendador se trata de una persona física, y 7 años si se trata de persona jurídica. En cambio, para los inmuebles cuyo destino sea distinto al uso de vivienda, la duración será la libremente pactada entre las partes.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Renta y actualización de la misma.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Fianza y su actualización.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Derecho y Obligaciones de las partes.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Causas de Resolución del Contrato.
                        <br/><br/>
                        <span className = "p-bold">¿Es conveniente hacer un inventario de bienes?</span>
                        <br/><br/>
                        Si el inmueble se entrega con muebles o con algún tipo de mobiliario, desde Garlaw Abogados siempre aconsejamos hacer un inventario del mismo anexo al contrato y que sea igualmente firmado por el arrendatario. Si no consta dicho inventario, es muy difícil acreditar la existencia de esos muebles a efectos de una futura reclamación llegado el vencimiento del contrato.
                        <br/><br/>
                        <span className = "p-bold">¿Cuál es el importe de la fianza?</span>
                        <br/><br/>
                        Tratándose de contrato de arrendamiento destinados a vivienda, la fianza será una cantidad equivalente a una mensualidad de renta. Para el caso de que se trate de uso distinto al de vivienda, la fianza será una cantidad equivalente a dos mensualidades de renta.
                        <br/><br/>
                        <span className = "p-bold">¿Se puede solicitar además una garantía adicional a la fianza?</span>
                        <br/><br/>
                        La respuesta es sí, pero la misma no podrá exceder de dos mensualidades de la renta.
                        <br/><br/>
                        <span className = "p-bold">¿Se puede actualizar la renta?</span>
                        <br/><br/>
                        Sí, pero para ello debe haberse pactado expresamente. No obstante,  a consecuencia de la elevada subida del índice de precios al consumo (IPC), se ha dictado el Real Decreto-Ley 6/2022 por el que se limita temporalmente la actualización de la renta en los arrendamientos de vivienda hasta un máximo de un 2%.
                        <br/><br/>
                        <span className = "p-bold">¿El arrendatario puede desistir del contrato?</span>
                        <br/><br/>
                        Sí, siempre que se lo comunique al arrendador con treinta días de antelación una vez trascurrido seis meses de su celebración. En este caso, puede pactarse entre las partes una indemnización a favor del arrendador de una cantidad equivalente a una mensualidad de la renta en vigor por cada año de vigencia del contrato que reste por cumplir. 
                        <br/><br/>
                        <span className = "p-bold">¿Qué debo hacer si no me devuelven la fianza?</span>
                        <br/><br/>
                        En primer lugar, es necesario asegurarnos que hemos devuelto el piso en las mismas condiciones que le recibimos, para ello en el acuerdo de resolución del contrato debe constar que no existen desperfectos. Así las cosas, debemos requerir, en primer lugar, por medio de burofax al arrendador a su devolución, si esto no fuera posible deberemos interponer la oportuna demanda al respecto.
                        <br/><br/>
                        <span className = "p-bold">¿Qué ocurre si la vivienda no se encuentra en condiciones de habitabilidad?</span>
                        <br/><br/>
                        En el caso de que la vivienda no se encuentra en condiciones de habitabilidad, por la existencia, por ejemplo, de humedades en las paredes, el arrendatario podrá optar entre exigir al arrendador la realización de las obras o reparaciones que sean pertinentes ex artículo 21 de la Ley de Arrendamientos Urbanos, o bien, la resolución del contrato en base al artículo 27 de la Ley de Arrendamientos Urbanos.
                        <br/><br/>
                        <span className = "p-bold">¿Qué ocurre si el arrendatario realiza actividades molestas, insalubres, nocivas, peligrosas o ilícitas en la vivienda?</span>
                        <br/><br/>
                        En este caso el arrendador podrá resolver el contrato de arrendamiento con base en el artículo 27 de la Ley de Arrendamientos Urbanos.
                        <br/><br/>
                        <span className = "p-bold">¿Qué gastos y suministros asume el arrendatario y cuáles el arrendador?</span>
                        <br/><br/>
                        El arrendador asume el pago del impuesto sobre bienes inmuebles y los gastos de la Comunidad, salvo que se hubiere pactado otra cosa con el arrendatario. Los gastos que sean susceptibles de individualización, tales como la luz, agua o gas, corresponden al arrendatario. 
                        <br/><br/>
                        <span className = "p-bold">¿El arrendador puede vender la vivienda? ¿Qué ocurre con mi contrato de arrendamiento? </span>
                        <br/><br/>
                        El arrendador podrá vender la vivienda arrendada, pero en todo caso el contrato de arrendamiento deberá ser respetado, ex artículo 14 de la Ley de Arrendamientos Urbanos. De manera que el comprador se subrogará en los derechos y obligaciones del arrendador durante la vigencia del contrato.
                        <br/><br/>
                        <span className = "p-bold">¿Qué pasa si el arrendador necesita la vivienda para su propio uso?</span>
                        <br/><br/>
                        En estos casos, el arrendador podrá recuperar la vivienda siempre y cuando se haya hecho constar esta circunstancia expresamente en el contrato y comunique al arrendatario esta necesidad de ocupar la vivienda.
                        <br/><br/>
                        <span className = "p-bold">¿Quién puede alquilar la vivienda, el nudo propietario o el usufructuario?</span>
                        <br/><br/>
                        En los casos en los que exista un usufructuario de la vivienda, será solo este quien pueda arrendar el inmueble, ya que es el titular del uso exclusivo el inmueble.
                        <br/><br/>
                        Desde Garlaw Abogados somos conscientes de la problemática que se deriva de los contratos de arrendamiento, es por ello, por lo que siempre aconsejamos contar con un buen asesoramiento legal desde el inicio. Recuerda que ponemos a tu disposición todos nuestros canales digitales para resolver cuantas dudas te surjan al respecto, siempre pensamos como ponértelo fácil. No dudes en contactar con nosotros, te estamos esperando.
                        </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/3PWxkc7HSyQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7064511654687911936/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/6213935172008944"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1658744582211883011"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Claves_Alquiler);