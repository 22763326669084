import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Vivienda_Heredada extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Vivienda Heredada | Garlaw Abogados</title>
				<meta name="description" content="¿Qué pasa si uno de los hermanos vive en la vivienda heredada? ¿podemos echarle?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-vivienda-heredada" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Vivienda Heredada | Garlaw Abogados" />
				<meta property="og:description" content="¿Qué pasa si uno de los hermanos vive en la vivienda heredada? ¿podemos echarle?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-vivienda-heredada" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Qué pasa si uno de los hermanos vive en la vivienda heredada? ¿podemos echarle?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Qué pasa si uno de los hermanos vive en la vivienda heredada? ¿podemos echarle?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Diciembre 12, 2022 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Son de sobra conocidos los múltiples problemas y conflictos que acarrean el reparto y la adjudicación de los bienes hereditarios, sobre todo y especialmente cuando se trata de inmuebles, pues habrá herederos que quieran venderlos, otros arrendarlo o habrá incluso algunos que vivan en ellos. Pero ¿qué podemos hacer antes este último supuesto?</p>
                        <img className="img-fluid img-post" src="/assets/images/post_vivienda_heredada.webp" width="100%" height="100%" alt="Post Vivienda Heredada"/>
                        <span className="caption text-muted">Garlaw Abogados. Vivienda Heredada</span>
                        <p>
                        <br/><br/>
                        Son muchas las preguntas de nuestros clientes que nos plantean que pueden hacer ante una vivienda heredada en la que reside uno de los hermanos. 
                        <br/><br/>
                        En primer lugar, debemos tener en cuenta que cuando recibimos en herencia una vivienda junto con nuestros hermanos nos encontramos ante un  <span className = "p-bold">condominio del artículo 392 del Código Civil</span>. Esto supone que cada uno es copropietario de una parte abstracta de la vivienda, ya que la misma no es divisible.
                        <br/><br/>
                        <span className = "p-bold">¿pero esto implica que uno de los copropietarios pueda vivir en ella en detrimento de los otros?</span>
                        <br/><br/>
                        La respuesta es NO. Según el artículo 394 del Código Civil, cada copropietario podrá servirse de las cosas comunes siempre que sean conforme a su destino y no perjudique a los demás. Sólo podrá vivir en ella si así se hubiera acordado por todos los copropietarios.
                        <br/><br/>
                        Entonces <span className = "p-bold">¿podemos echar al hermano que reside en la vivienda sin consentimiento de los demás?</span>
                        <br/><br/>
                        La respuesta es SÍ. En el caso de que uno de los copropietarios se instale en la vivienda, o ya residiera antes en la misma, sin consentimiento de los demás copropietarios se encontrará en una situación de precario, pudiendo instar la oportuna demanda de desahucio por precario.
                        <br/><br/>
                        <span className = "p-bold">¿Cuáles son las recomendaciones en estos casos?</span>
                        <br/><br/>
                        Desde Garlaw Abogados siempre recomendamos que en el caso de que uno de los copropietarios quiera vivir en la vivienda, se establezca un contrato de arrendamiento, de manera que los demás copropietarios que sean privados del uso reciban una compensación económica. Esta siempre es la solución más optima si los demás copropietarios no quieres hacer uso de la vivienda. No obstante, si quisieran hacer uso de la misma, lo mejor sería instalar un régimen de uso por turnos, aunque esta opción también suele ser problemática.
                        <br/><br/>
                        <span className = "p-bold">¿Quién se hace cargo de los gastos de la vivienda si reside uno de los hermanos?</span>
                        <br/><br/>
                        El heredero que vive en ella es quién deberá afrontar los gastos de uso corriente de la misma, como puede ser luz, agua, gas, comunidad, gastos por el uso…
                        <br/><br/>
                        En cambio, los gastos que se refieran a la propiedad corresponden a los copropietarios según su cuota de participación. Por ejemplo, el IBI, la hipoteca, cuotas extraordinarias de la Comunidad, etc…
                        <br/><br/>
                        <span className = "p-bold">¿Se puede reclamar al copropietario que no se hace cargo de su parte en los gastos?</span>
                        <br/><br/>
                        La respuesta es SÍ. Como ya hemos indicado cada copropietario tendrá que asumir los gastos en función de su cuota de participación, y si no lo hace se podrá interponer la oportuna demanda de reclamación de cantidad. 
                        <br/><br/>
                        <span className = "p-bold">¿Puede el heredero que resida en la vivienda hacer obras de mejora?</span>
                        <br/><br/>
                        La respuesta es NO. El artículo 397 del Código Civil establece que ningún copropietario podrá hacer alteraciones en la cosa común sin el consentimiento de los demás.
                        <br/><br/>
                        <span className = "p-bold">¿Puede uno de los copropietarios vender o alquilar la vivienda por si sólo?</span>
                        <br/><br/>
                        La respuesta es NO. Requerirá el consentimiento de los demás copropietarios, así lo ha establecido el Tribunal Supremo, al considerarlo un acto de disposición si supera el plazo de seis meses. 
                        <br/><br/>
                        <span className = "p-bold">¿Qué pasa si sólo uno de lo copropietarios quiere vender la vivienda?</span>
                        <br/><br/>
                        Según el artículo 400 del Código Civil, ningún copropietario estará obligado a permanecer en la comunidad. Cada uno de ellos podrá pedir en cualquier tiempo que se divida la cosa común. Por tanto, si no se llega a un acuerdo a este respecto, como puede ser que los demás copropietarios compren su parte, o poner la vivienda a la venta y repartirse el precio en función de sus cuotas de participación, se deberá instar la oportuna demanda de división de la cosa común. 
                        <br/><br/>
                        Como podemos observar, son numerosas las problemáticas que surgen entorno a la figura condominio, por eso desde Garlaw Abogados te ofrecemos un asesoramiento especializado y ajustado a tu problema en concreto, ofreciéndote siempre la solución óptima a tu situación. No dudes en ponerte en contacto con nosotros, te ponemos las cosas fáciles.
                        </p>
						
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            <li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/eBznm6FSsxY"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7008098289753456641"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1602333166982815747"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Vivienda_Heredada);