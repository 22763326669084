import React  from 'react';
import {NavLink, withRouter}  from "react-router-dom";

import '../componentes.css'; 
 
import AOS from 'aos';
import 'aos/dist/aos.css';
	
import $ from 'jquery'; 
import MetaTags from 'react-meta-tags';
 
class Familia extends React.Component {

	componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }
	  
  
    
	render() {
		return(

			<>
			
			<MetaTags>
				<title>Familia y Sucesiones | Garlaw Abogados</title>
				<meta name="description" content="Porque sabemos que la familia es lo primero, te ofrecemos un asesoramiento legal completo. Abogados especialistas en herencias y sucesiones." />
				<link rel="canonical" href="https://www.garlawabogados.com/areas-legales/familia-sucesiones" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Familia y Sucesiones | Garlaw Abogados" />
				<meta property="og:description" content="Porque sabemos que la familia es lo primero, te ofrecemos un asesoramiento legal completo. Abogados especialistas en herencias y sucesiones." />
				<meta property="og:url" content="https://www.garlawabogados.com/areas-legales/familia-sucesiones" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Porque sabemos que la familia es lo primero, te ofrecemos un asesoramiento legal completo. Abogados especialistas en herencias y sucesiones." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea" data-aos="zoom-in" data-aos-delay="100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 welcomeconoce">
								<div className = "welco-conoce">
									<h1>Familia</h1>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="areaas_legales">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7" data-aos="zoom-out" data-aos-delay="100">
                        <p>¿No sabes cuales son los requisitos para poder ser adoptante en España? ¿Has roto con tu pareja y no sois capaces de llegar a un acuerdo sobre vuestros hijos? ¿Han cambiado tus circunstancias personales y no puedes seguir abonando la misma cantidad de pensión alimenticia a tus hijos? ¿Conoces a alguna persona que no pueda gobernar su persona y sus bienes? Son muchos los problemas que a lo largo de la vida suelen planteársele a una persona, y el ámbito familiar no es ajeno a esto, es por ello por lo que contar con un asesoramiento legal especializado en cada momento puede marcarte la diferencia para bien, pues puede ahorrarte situaciones angustiosas, contando con un equipo de máxima calidad como es Garlaw Abogados, el cual su premisa principal es llegar a un entendimiento por el bien de la familia, apostando por el diálogo y la transparencia, sin que el cliente tenga que preocuparse de nada.  ¿Qué me dices? ¿Te solucionamos tus problemas familiares?</p>
                        <img className="img-fluid" width="100%" height="100%"  src="/assets/images/Garlaw_Familia.webp" alt="Garlaw Abogados Familia"/>
                        <span className="caption text-muted">Garlaw Abogados. Familia</span>
                        <p>En esta materia se ofrecerá un asesoramiento especializado en:</p>
						<ul className="list-bullets">
                        	<li className="mb-lar">Filiación.</li>
							<li className="mb-lar">Adopción.</li>
							<li className="mb-lar">Menores.</li>
							<li className="mb-lar">Parejas de Hecho.</li>
							<li className="mb-lar">Nulidad Matrimonial.</li>
							<li className="mb-lar">Separación.</li>
							<li className="mb-lar">Divorcio.</li>
							<li className="mb-lar">Liquidación de Gananciales.</li>
							<li className="mb-lar">Uso del Domicilio Familiar.</li>
							<li className="mb-lar">Patria Potestad.</li>
							<li className="mb-lar">Custodia Compartida.</li>
                            <li className="mb-lar">Adopción de Medidas Paternofiliales.</li>
                            <li className="mb-lar">Modificación de Medidas Paternofiliales.</li>
                            <li className="mb-lar">Pensión de Alimentos.</li>
                            <li className="mb-lar">Visitas entre Padres e Hijos y entre Abuelos y Nietos.</li>
                    	</ul>
                    </div>
                </div>
            </div>
        </article>



        <div className="parea" data-aos="zoom-in" data-aos-delay="100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 welcomeconoce">
								<div className = "welco-conoce">
									<h1>Sucesiones y Herencias</h1>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="areaas_legales">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7" data-aos="zoom-out" data-aos-delay="100">
                        <p>¿Quieres hacer un testamento pero no sabes cómo? ¿Te han instituido como heredero o legatario y no sabes que tienes que hacer? te encuentras en el lugar correcto, desde Garlaw Abogados te asesoramos, ayudamos y acompañamos a lo largo del todo el proceso, incluido los impuestos y trámites administrativo, para que tú solo te preocupes de lo que de verdad importa. Estamos a tu disposición.</p>
                        <img className="img-fluid" width="100%" height="100%" src="/assets/images/Garlaw_Sucesiones.webp" alt="Garlaw Abogados Sucesiones"/>
                        <span className="caption text-muted">Garlaw Abogados. Sucesiones</span>
                        <p>Te ofrecemos asesoramiento integral sobre las siguientes materias:</p>
						<ul className="list-bullets">
                        	<li className="mb-lar">Testamentos.</li>
							<li className="mb-lar">Aceptación pura y simple.</li>
							<li className="mb-lar">Aceptación a beneficio de inventario.</li>
							<li className="mb-lar">Renuncia o repudiación de la herencia.</li>
							<li className="mb-lar">Desheredaciones.</li>
							<li className="mb-lar">Legítima.</li>
							<li className="mb-lar">Legados.</li>
							<li className="mb-lar">Últimas voluntades.</li>
                    	</ul>
                    </div>
                </div>
            </div>
        </article>


		<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Tu Abogado a un <span>Paso</span></h2>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="100">
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-map-marker"></i>
							<h3>Visita Nuestras Oficinas</h3>
							<p>Madrid, Segovia, Avila</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-whatsapp"></i>
							<h3>Nuestros Canales Digitales</h3>
							<p>Llamada, Email, Videollamada, Whatsapp</p>
							</div>
						</div>
							<div className="text-center">
							<NavLink to="/contacto" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = "Contacto">
								<button>Contacta</button>
							</NavLink>
							</div>
						</div>
					</div>
    			</section>


		</>
		)
	}
}
export default withRouter(Familia);