import React  from 'react';
import { withRouter } from 'react-router-dom';

import '../componentes.css'; 
 
 
class Cookies extends React.Component {
    
	render() {
		return(
                    <section className="section" id="about2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="left-text-content">
                                        <div className="section-heading">
                                            <h6> <strong>POLITICA DE COOKIES</strong></h6>
                                        </div>
                                        <br/>
                                        <p><br/>
                                        Este sitio web utiliza cookies para facilitar la relación de los visitantes con su contenido y para permitir elaborar estadísticas sobre las visitantes que recibimos.
                                        <br/>
                                        En cumplimiento de la Directiva 2009/136/CE, desarrollada en nuestro ordenamiento por el apartado segundo del artículo 22 de la Ley de Servicios de Sociedad de la Información, siguiendo las directrices de la Agencia Española de Protección de Datos, procedemos a informarle detalladamente del uso que se realiza en nuestra web.
                                        <br/>
                                        Se denominan cookies a unos pequeños archivos que se graban en el navegador utilizado por cada visitante de nuestra web para que el servidor pueda recordar la visita de ese usuario con posterioridad cuando vuelva a acceder a nuestros contenidos. Esta información no revela su identidad, ni dato personal alguno, ni accede al contenido almacenado en su pc, pero sí que permite a nuestro sistema identificarle a usted como un usuario determinado que ya visitó la web con anterioridad, visualizó determinadas páginas, etc. y además permite guardar sus preferencias personales e información técnica como por ejemplo las visitas realizadas o páginas concretas que visite.
                                        <br/>
                                        La finalidad de las cookies es la de facilitar al Usuario un acceso más rápido a los Servicios seleccionados. Las cookies nos ayudan a mejorar nuestro sitio Web y a prestar un servicio mejor y más personalizado. En concreto, nos permiten: hacer una estimación sobre números y patrones de uso, almacenar información acerca de tus preferencias y personalizar nuestro sitio web de conformidad con tus intereses individuales, acelerar tus búsquedas y reconocerte cuando regreses de nuevo a nuestro sitio.
                                        <br/>
                                        Esta página web utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc., una  compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). Google Analytics utiliza “cookies”, que son archivos de texto ubicados en su ordenador, para ayudar al sitio Web a analizar el uso que hacen los usuarios del sitio Web. La información que genera la cookie acerca de su uso del sitio Web (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del sitio Web, recopilando informes de la actividad del sitio Web y prestando otros servicios relacionados con la actividad del sitio Web y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga Google. Puede Usted rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, debe Usted saber que si lo hace puede ser que no pueda usar la plena funcionabilidad de este sitio Web. Al utilizar este sitio Web Usted consiente el tratamiento de información acerca de Usted por Google en la forma y para los fines arriba indicados. A estos efectos, se informa que Google Inc. tiene la consideración de “puerto seguro” en cuanto a la normativa de protección de datos personales.
                                        <br/><br/>
                                        Puedes negarte a aceptar cookies activando la configuración en tu navegador que permite rechazar las cookies. No obstante, si seleccionas esta configuración, quizás no puedas acceder a determinadas partes del Sitio Web o no puedas aprovecharte de alguno de nuestros servicios. A menos que hayas ajustado la configuración de tu navegador de forma que rechace cookies, nuestro sistema producirá cookies cuando te conectes a nuestro sitio.
                                        <br/>
                                        Para permitir, conocer, bloquear o eliminar las cookies instaladas en su equipo puede hacerlo mediante la configuración de las opciones del navegador instalado en su ordenador.
                                        <br/>
                                        Puede encontrar información sobre cómo configurar los navegadores más usados en las siguientes ubicaciones:
                                        <br/>
                                        Internet Explorer: Herramientas -> Opciones de Internet -> Privacidad -> Configuración. Para más información, puede consultar el soporte de Microsoft o la Ayuda del navegador.
                                        <br/>
                                        Firefox: Herramientas -> Opciones -> Privacidad -> Historial -> Configuración Personalizada. Para más información, puede consultar el soporte de Mozilla o la Ayuda del navegador.
                                        <br/>
                                        Chrome: Configuración -> Mostrar opciones avanzadas -> Privacidad -> Configuración de contenido. Para más información, puede consultar el soporte de Google o la Ayuda del navegador.
                                        <br/>
                                        Safari: Preferencias -> Seguridad. Para más información, puede consultar el soporte de Apple o la Ayuda del navegador.
                                        <br/>
                                        Respecto de las cookies de terceros, es decir aquellas que son ajenas a nuestro sitio web, no podemos hacernos responsables del contenido y veracidad de las políticas de privacidad que ellos incluyen por lo que la información que le ofrecemos es siempre con referencia a la fuente.
                                        <br/>
                                        Se puede modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, por ello se aconseja a los Usuarios que la visiten periódicamente.
                                        <br/>
                                        Cuando se produzcan cambios significativos en esta Política de Cookies, se comunicarán a los Usuarios bien mediante la web o a través de correo electrónico a los Usuarios registrados.
                                        <br/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>	
		)
	}
}
export default withRouter(Cookies);