import React  from 'react';
import { withRouter } from 'react-router-dom';

import '../componentes.css'; 
 
 
class AvisoLegal extends React.Component {
    
	render() {
		return(
                    <section className="section" id="about2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-12">
                                    <div className="left-text-content">
                                        <div className="section-heading">
                                            <h6> <strong>Aviso Legal </strong></h6>
                                        </div>
                                        <p> <br/>El presente Aviso Legal regula las condiciones generales de acceso y utilización de este portal web, entendiendo por éste todas las páginas y sus contenidos propiedad de <strong>SANDRA GARCÍA GARCÍA</strong> a las cuales se accede a través del dominio <strong>www.garlawabogados.com</strong>.
                                        <br/>La utilización del sitio web implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal. En consecuencia, el usuario del sitio web debe leer atentamente el presente Aviso Legal en cada una de las ocasiones en que se proponga utilizar la web, ya que el texto podría sufrir modificaciones a criterio del titular de la web, o a causa de un cambio legislativo, jurisprudencial o en la práctica empresarial.
                                        </p>
                                        <h6>DATOS IDENTIFICATIVOS: </h6>
                                        <p>
                                        En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, a continuación, se reflejan los siguientes datos: <br/>
                                        Nombre del titular o el administrador: SANDRA GARCÍA GARCÍA <br/>
                                        NIF: 70259214X <br/>
                                        Domicilio: Plaza Ribadeo 2, 2-1., CP 28029 Madrid <br/>
                                        Teléfono:   621 00 33 74<br/> 
                                        Correo electrónico: info@garlawabogados.com<br/>
                                        Nombre del dominio: www.garlawabogados.com <br/>
                                        </p>
                                        <h6>USUARIOS: </h6>
                                        <p> El acceso y/o uso de este portal web atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, el presente aviso legal. Las citadas Condiciones serán de aplicación independientemente de las Condiciones Generales de Contratación que en su caso resulten de obligado cumplimiento.
                                            <br/>
                                        </p>
                                        <h6>USO DEL PORTAL: </h6>
                                        <p>
                                        El usuario se compromete a hacer uso de los servicios, productos y utilidades ofrecidos por el administrador a través de este sitio Web de conformidad con la ley, este Aviso Legal y demás avisos e instrucciones puestos en su conocimiento, así como con la moral y las buenas costumbres generalmente aceptadas y el orden público.
                                        <br/>
                                        Con carácter general, el acceso a la información y utilidades del sitio Web no exige una previa suscripción o registro de usuario. No obstante, lo anterior, el acceso a alguno de los servicios o productos que se ofrezcan a través del sitio Web puede estar condicionado a la previa cumplimentación del formulario de suscripción o registro. A estos efectos, el usuario garantiza la autenticidad y veracidad de todos aquellos datos personales que comunique al cumplimentar el formulario de suscripción o registro. El usuario se compromete y responsabiliza de mantener toda la información facilitada de forma que responda, en cada momento, a su situación actual. El tratamiento de los datos de carácter personal suministrados por el usuario se realizará por el administrador con sujeción a la Política de Privacidad contenida en este sitio Web.
                                        <br/>
                                        El idioma utilizado por el titular en la web será el castellano. El Administrador no se responsabiliza de la no comprensión o entendimiento del idioma de la web por el usuario, ni de sus consecuencias.
                                        <br/>
                                        La titular del portal podrá modificar los contenidos sin previo aviso, así como suprimir y cambiar éstos dentro de la web, como la forma en que se accede a éstos, sin justificación alguna y libremente, no responsabilizándose de las consecuencias que los mismos puedan ocasionar a los usuarios.
                                        <br/>
                                        Se prohíbe el uso de los contenidos de la web para promocionar, contratar o divulgar publicidad o información propia o de terceras personas sin la autorización del Administrador ni remitir publicidad o información valiéndose para ello de los servicios o información que se ponen a disposición de los usuarios, independientemente de si la utilización es gratuita o no.
                                        <br/>
                                        Los enlaces o hiperenlaces que incorporen terceros en sus páginas web, dirigidos a esta web, serán para la apertura de la página web completa, no pudiendo manifestar, directa o indirectamente, indicaciones falsas, inexactas o confusas, ni incurrir en acciones desleales o ilícitas en contra de SANDRA GARCÍA GARCÍA, y en definitiva, Garlaw Abogados.
                                        <br/>
                                        </p>
                                        <h6>PROPIEDAD INTELECTUAL E INDUSTRIAL: </h6>
                                        <p>
                                        SANDRA GARCÍA GARCÍA  por sí o como cesionaria, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, artículos jurídicos, legales, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de SANDRA GARCÍA GARCÍA todos los derechos reservados.
                                        <br/>
                                        En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de SANDRA GARCÍA GARCÍA. El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de SANDRA GARCÍA GARCÍA.
                                        <br/>
                                        La utilización no autorizada de la información contenida en este Sitio, así como los perjuicios ocasionados en los derechos de propiedad intelectual e industrial de SANDRA GARCÍA GARCÍA pueden dar lugar al ejercicio de las acciones que legalmente correspondan y, si procede, a las responsabilidades que de dicho ejercicio se deriven.
                                        <br/>
                                        </p>
                                        <h6>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD: </h6>
                                        <p>
                                        SANDRA GARCÍA GARCÍA no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: errores u omisiones en los contenidos, falta de disponibilidad del portal o la transmisión de virus o programas maliciosos o lesivos en los  contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.
                                        <br/>
                                        El contenido, información y/o consejos expresados en este portal web deben entenderse como simplemente orientativos. SANDRA GARCÍA GARCÍA no responde de ninguna forma de la efectividad o exactitud de los mismos, quedando exenta de cualquier responsabilidad con los usuarios que haga uso de ellos, ya que son éstas las que deberán decidir según su criterio la oportunidad de los mismos. En este Sitio se pueden publicar contenidos y comentarios aportados por terceras personas. SANDRA GARCÍA GARCÍA no responde de la veracidad y exactitud de los mismos, quedando exenta de cualquier responsabilidad con los usuarios que hagan uso de ellos.
                                        <br/>
                                        SANDRA GARCÍA GARCÍA se reserva el derecho de modificar el contenido del Sitio sin previo aviso y sin ningún tipo de limitación. Así mismo la empresa declina cualquier responsabilidad por los eventuales daños y perjuicios que puedan ocasionarse por la falta de disponibilidad y/o continuidad de este Sitio y de los servicios que se ofrecen en él. Así mismo, tampoco podemos garantizar la ausencia de virus ni de otros elementos en la Web que puedan producir alteraciones en su sistema informático.
                                        <br/>
                                        SANDRA GARCÍA GARCÍA declina cualquier responsabilidad por los servicios y/o información que se preste en otros sitios enlazados con este, ya que no controla ni ejerce ningún tipo de supervisión en Sitios Webs de terceros. Aconsejamos a los usuarios de los mismos a actuar con prudencia y consultar las eventuales condiciones legales que se expongan en dichas Webs. Así mismo los Usuarios que remitan  cualquier tipo de información a SANDRA GARCÍA GARCÍA se comprometen a que la misma sea veraz y que no vulnere cualquier derecho de terceros ni la legalidad vigente.
                                        <br/>
                                        Si ud. cree que cualquier contenido y/o información de este Sitio vulnera un derecho legítimo o la legalidad vigente le agradeceríamos que se pusiera en contacto con SANDRA GARCÍA GARCÍA para que  podamos tomar las medidas oportunas.
                                        <br/>
                                        </p>
                                        <h6>LEGISLACIÓN APLICABLE Y JURISDICCIÓN: </h6>
                                        <p>
                                        La relación entre SANDRA GARCÍA GARCÍA y el usuario se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgados y tribunales de la ciudad de Madrid.
                                        <br/>
                                        </p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </section>	
		)
	}
}
export default withRouter(AvisoLegal);