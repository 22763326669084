import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Gastos_Universidad extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>¿Quién paga los gastos universitarios de los hijos en común tras una separación o divorcio?</title>
				<meta name="description" content="¿Quién paga los gastos universitarios de los hijos en común tras una separación o divorcio?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-gastos-universidad-en-divorcio" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="¿Quién paga los gastos universitarios de los hijos en común tras una separación o divorcio?" />
				<meta property="og:description" content="¿Quién paga los gastos universitarios de los hijos en común tras una separación o divorcio?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-gastos-universidad-en-divorcio" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Quién paga los gastos universitarios de los hijos en común tras una separación o divorcio?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Quién paga los gastos universitarios de los hijos en común tras una separación o divorcio?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Marzo 5, 2024 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Son muchos los clientes que acuden a nuestro despacho con la duda de quién debe abonar los gastos universitarios de los hijos en común después de una separación o divorcio. La principal problemática al respecto es determinar si esos gastos, son gastos ordinarios y, por lo tanto, se encuentran dentro de la pensión de alimentos, o, por el contrario, deben considerarse gastos extraordinarios. A lo largo de este artículo vamos a resolver todas las dudas al respecto. 
                        <br/><br/><br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_gastos_universidad.webp" width="100%" height="100%" alt="Gastos Universitarios en Divorcio"/>
                        <span className="caption text-muted">Garlaw Abogados. Gastos Universitarios en Divorcio.</span>
                        <p>
                        Debemos de partir que los gastos de educación de los hijos, incluido los universitarios, son gastos ordinarios salvo que se pacte lo contrario por los progenitores. Por lo tanto, los mismos deben ser tenidos en cuenta a la hora de fijar la cuantía de la pensión de alimentos.
                        <br/><br/>
                        Ahora bien, cuando hablamos de gastos universitarios es necesario hacer matizaciones al respecto.
                        <br/><br/>
                        <span className = "p-bold">¿Qué gastos universitarios están incluidos en la pensión de alimentos?</span>
                        <br/><br/>
                        En el caso de que los hijos cursen los estudios en una universidad pública, la jurisprudencia ha venido estableciendo que <span className = "p-bold_su">la matricula, tasa y material escolar </span>están incluidos en la pensión de alimentos y, por lo tanto, se consideran gastos ordinarios.
                        <br/><br/>
                        En este sentido se ha pronunciado el <span className = "p-bold_su">Tribunal Supremo mediante Sentencias de fecha 13 de septiembre de 2017 o de 18 de enero de 2017</span>. También podemos mencionar <span className = "p-bold_su">la Sentencia de la Audiencia Provincial de Madrid nº 820/2019 de 11 de octubre</span>, donde dice:
                        <br/><br/>
                        <span className = "p-curs">
                        &nbsp;&nbsp;&nbsp;&nbsp;“Para <span className = "p-bold_su">la fijación de las pensiones</span> de alimentos se han de considerar todos los desembolsos cotidianos comunes, los que de por sí justifican dicha aportación, pues ya solo la cuota de colegio se cifraba constante el proceso en unos 370 € mensuales, debiendo recordarse que se deben cuantificar con vocación de futuro, en evitación de que mínimas incidencias, máxime de resultar previsibles, como es el caso de <span className = "p-bold">accederse a estudios superiores en la Universidad</span>, aboquen a las partes a incesantes procesos de modificación de medidas para su reajuste, por los cauces del artículo 775 de la L.E.Civil, siendo que la evolución o crecimiento no implica necesariamente incremento o descenso de las necesidades, techo final de los alimentos, sino una mera transformación en la que unas que desaparecen abren paso a otras que van surgiendo.”
                        </span>
                        <br/><br/>
                        Pero como decimos, la Universidad debe ser pública, si la misma es privada, se considera como gasto extraordinario y, por lo tanto, no está incluido en la pensión de alimentos. En este sentido se ha pronunciado de nuevo <span className = "p-bold_su">el Tribunal Supremo mediante Sentencias de fecha 18 de enero de 2017</span>. 
                        <br/><br/>
                        Además, es necesario recordar, que como todo gasto extraordinario necesitará el consentimiento de ambos progenitores.
                        <br/><br/>
                        Pero también, está incluido en la pensión de alimentos, no solo la matricula, tasa y material escolar, sino también los <span className = "p-bold_su">gastos de traslado y residencia</span> de los hijos en común, salvo que se trate de residencia privadas. En este sentido podemos mencionar <span className = "p-bold_su">Auto de la Audiencia Provincial de Valladolid, de fecha 24 de septiembre de 2009</span>: 
                        <br/><br/>
                        <span className = "p-curs">
                        &nbsp;&nbsp;&nbsp;&nbsp;“Los reclamados gastos de residencia y material devengados por la estancia de la común hija menor en la Escuela de Hostelería de la ciudad de Bilbao, no van a poder considerarse de naturaleza extraordinaria, pese a que sobre los mismos pueda defenderse su carácter de necesarios, siendo derivados de la formación académica de la menor, que van a devengarse periódicamente y que, conforme a los criterios de este mismo Tribunal, son comprensibles dentro de los ordinarios (y previsibles) gastos de educación o formación, siempre inclusive en los cubiertos por la pensión alimenticia establecida inicialmente.”
                        </span>
                        <br/><br/>
                        Como decimos, si se trata de residencias privadas, los mismos se considerarán extraordinarios, por el coste de la misma, o por existir otras alternativas públicas de estancia o de traslado, y de nuevo se necesitará el consentimiento de ambos progenitores. 
                        <br/><br/>
                        Pero <span className = "p-bold">¿qué pasa con los intercambios internacionales o programa de Erasmus?</span>
                        <br/><br/>
                        En estos casos, al ser un gasto esporádico e imprevisible, se considera por la unanimidad de la jurisprudencia que el mismo es un gasto extraordinario y necesita el consentimiento de ambos progenitores. En este sentido podemos mencionar la Sentencia <span className = "p-bold_su">de la Audiencia Provincial de Madrid nº 1656/2020, de fecha 20 de enero</span>: <span className = "p-curs">“Con carácter general en esta Sala se viene considerando gasto extraordinario, los gastos de estudios en el extranjero, ya sean para perfeccionar idiomas, o dentro del Erasmus, así como los de especialización o las clases particulares y por tanto requieren el consenso y el abono de ambos progenitores.”</span>
                        <br/><br/>
                        Y <span className = "p-bold">¿con respecto al máster?</span>
                        <br/><br/>
                        De nuevo es un gasto esporádico e imprevisible y se considera por la jurisprudencia que el mismo es un gasto extraordinario. En este sentido podemos mencionar el <span className = "p-bold_su">Auto de la Audiencia Provincial de Valencia de fecha 8 de noviembre de 2017</span>. O la Sentencia <span className = "p-bold_su">de la Audiencia Provincial de Madrid nº 1656/2020, de fecha 20 de enero</span>.
                        <br/><br/>
                        Si aún te quedan dudas al respecto o requieres de un asesoramiento especializado, no dudes en ponerte en contacto con nosotros,  estaremos encantado de ayudarte. Y recuerda  Garlaw Abogados te pone las cosas fáciles. Te estamos esperando.
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/FXjqgrCg8TI"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7170803897991741441/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/728141259435160"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1765035277825769847"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Gastos_Universidad);