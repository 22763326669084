import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Nueva_Ley_Vivienda extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Principales novedades de la Nueva Ley de Vivienda</title>
				<meta name="description" content="Principales novedades de la Nueva Ley de Vivienda" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-nueva-ley-vivienda" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Principales novedades de la Nueva Ley de Vivienda" />
				<meta property="og:description" content="Principales novedades de la Nueva Ley de Vivienda" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-nueva-ley-vivienda" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Principales novedades de la Nueva Ley de Vivienda" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Principales novedades de la Nueva Ley de Vivienda</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Septiembre 12, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> El paso 26 de mayo entró en vigor la nueva Ley de la Vivienda 12/2023, la cual introduce importantes novedades en materia de vivienda, que van desde establecer límites en la renta de los arrendamientos, pasando por modificaciones fiscales, hasta llegar a establecer una nueva protección contra los desahucios.
                        <br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_nueva_ley_vivienda.webp" width="100%" height="100%" alt="Nueva Ley de Vivienda"/>
                        <span className="caption text-muted">Garlaw Abogados. Nueva Ley de Vivienda</span>
                        <p>
                        Vamos a ver cuáles son cada una de estas novedades:
                        <br/><br/>
                        1.	<span className = "p-bold_su">Se introduce un nuevo concepto de gran tenedor</span>: la nueva ley lo define como “la persona física o jurídica que sea titular de más de diez inmuebles urbanos de uso residencial o una superficie construida de más de 1.500 m2 de uso residencial, excluyendo en todo caso garajes y trasteros”. No obstante, este umbral puede reducirse a los cinco inmuebles siempre que los mismos se encuentren en una misma zona declarada tensionada por la Comunidad Autónoma. 
                        <br/><br/>
                        2.	<span className = "p-bold_su">Límites a la actualización de la renta.</span> La disposición adicional sexta de la Ley establece una limitación extraordinaria en la actualización de la renta de todos los contratos de arrendamiento de vivienda, con independencia de que se encuentren en una zona tensionada, o el arrendador sea gran tenedor. Durante el año 2023 se mantiene la limitación de la renta en el 2% , durante el año 2024, en una 3% y la creación de un nuevo índice para el año 2025, que no estará ligado al IPC.
                        <br/><br/>
                        3.	<span className = "p-bold_su">Zonas de Mercado Residencial Tensionado.</span> La ley confiere la posibilidad a las Comunidades Autónomas y algunos ayuntamientos con competencia en materia de vivienda, la posibilidad de declarar zonas de mercado residencial tensionado durante un periodo de tres años, prorrogable anualmente. Para ello es necesario que se cumplan una de las dos condiciones siguientes:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	Que el coste medio de la hipoteca o el arrendamiento más los gastos supere el 30% de los ingresos medios o de la renta media de los hogares.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	Que durante los cinco años anteriores a la declaración, se haya encarecido el precio de la vivienda en la venta o alquiler al menos de 3 puntos por encima de la inflamación de la Comunidad que se trate. 
                        <br/><br/>
                        4.	<span className = "p-bold_su">Regulación de la renta en las zonas tensionadas.</span> Se establece un límite en la renta al inicio del nuevo contrato, la cual no podrá exceder de la fijada en el anterior contrato de arrendamiento en la misma vivienda, una vez aplicada la cláusula de actualización anual de la misma. Solo se podrá subir un 10% en los siguientes casos:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	Cuando se haya hecho una rehabilitación o mejora de la vivienda los dos años anteriores al nuevo contrato. Se exigen certificados de mejora de eficiencia energética al menos de un 30% o bien mejoras de accesibilidad.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	Cuando la duración del nuevo contrato de arrendamiento se fije por un periodo de 10 o más años o se establezca un derecho de prorroga a cargo del arrendatario con esa duración.
                        <br/><br/>
                        5.	<span className = "p-bold_su">Prorroga de los contratos de arrendamiento.</span> Se prevé una prórroga obligatoria de un año cuando el arrendador se trate de un gran tenedor y el arrendatario acredite una situación de vulnerabilidad. 
                        <br/><br/>
                        Tratándose de viviendas ubicadas en zonas tensionadas debidamente declaradas, el arrendatario podrá acogerse a una nueva prórroga por plazo anuales, hasta un máximo de tres.
                        <br/><br/>
                        6.	<span className = "p-bold_su">Reparto de gastos y honorarios.</span> Podrán pactarse que los gastos general del inmueble sean a cargo del arrendatario, no obstante, se prohíbe que la actualización de la renta pueda aumentarse con los gastos si no estaban previstos con anterioridad. Los gastos de gestión inmobiliaria y formalización del contrato serán a cargo del arrendador. 
                        <br/><br/>
                        7.	<span className = "p-bold_su">Obligación del pago de la renta por medio electrónicos.</span> Salvo que el arrendatario carezca de cuenta bancaria.
                        <br/><br/>
                        8.	<span className = "p-bold_su">Beneficios fiscales en los arrendamientos de vivienda.</span> Para los nuevos contratos se establece una bonificación del 50 %, que podrá incrementarse al 90% cuando se trate de viviendas que se encuentren en zonas tensionadas y se contemple una bajada del 5% respecto de la renta del contrato anterior. 
                        <br/><br/>
                        9.	<span className = "p-bold_su">Recargo en el Impuesto de Bienes Inmuebles.</span> Los Ayuntamientos podrán aplicar un recargo en el Impuesto de Bienes Inmuebles en las viviendas que lleven vacías más de dos años. La penalización mínima será de un 50%, pudiendo llegar al 150%.
                        <br/><br/>
                        10.	<span className = "p-bold_su">Nuevas medidas frente a los desahucios.</span> Establece la reanudación de los desahucios y lanzamientos una vez llegado el 30 de junio de 2023, salvo que se trate el arrendador de un gran tenedor, en cuyo caso será necesario un procedimiento de conciliación o intermediación. 
                        <br/><br/>
                        Otra novedad, es la obligación de fijar siempre la fecha y hora exacta del lanzamiento, plazos de suspensión cuando el arrendatario se trate de una persona especialmente vulnerable, o la necesidad de conciliación o mediación cuando el arrendador tenga la condición de gran tenedor y el arrendatario se encuentre en una situación de vulnerabilidad. 
                        <br/><br/>
                        11.	<span className = "p-bold_su">Información mínima en operaciones de compraventa y arrendamientos de viviendas.</span> Las personas interesadas en comprar o arrendar una vivienda, podrán solicitar la siguiente información:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span> Información del vendedor.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	Condiciones económicas de la operación. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	Respecto a la vivienda:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">o</span>	Cédula de habitabilidad.
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">o</span>	Superficie útil y construida.
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">o</span>	Antigüedad del edificio y reformas del mismo.
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">o</span>	Servicios de la vivienda.
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">o</span>	Certificado de eficiencia energética.
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">o</span>	Condiciones de accesibilidad de la vivienda.
                        <br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">o</span>	Estado de ocupación.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	 Información jurídica del inmueble.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	 Información de la sujeción y circunstancias si es una vivienda de protección oficial.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">•</span>	Grado de protección y condiciones de protección arquitectónica.
                        <br/><br/>
                        Como se puede observar, se han introducido importantes novedades en materia de vivienda, por eso desde Garlaw Abogados siempre aconsejamos contar con un buen asesoramiento legal antes de realizar cualquier operación de compraventa o arrendamiento, es fundamental que conozcas tus derechos al respecto. Por eso, estamos a tu disposición para resolver cualquier duda al respecto, ponte en contacto con nosotros, te estamos esperando.
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/watch?v=znycN9TdgNk"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7107699894856880128/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/1712017829295140"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1701935947321864558"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Nueva_Ley_Vivienda);