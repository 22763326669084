import ReactDOM from 'react-dom'
import React from 'react';
import { BrowserRouter } from 'react-router-dom';


// Scroll
import ScrollRestoration from 'react-scroll-restoration';

import App from './App';


ReactDOM.render(
  <BrowserRouter>
  <ScrollRestoration/>
      <App />
  </BrowserRouter>
  , document.getElementById('root')
);
