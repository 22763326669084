import React from 'react';
import {NavLink, withRouter}  from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import '../componentes.css'; 
import $ from 'jquery'; 


class Menu extends React.Component {


 
  render() {
 
  	return (

        <div className="d-lg-flex align-items-center fixed-top">
        <div id="topbar" className="d-lg-flex align-items-center fixed-top">
          <div className="container d-flex justify-content-center">
            <div className="contact-info mr-auto">
              <i className="fa fa-envelope"></i> <a title = "Mail de Contacto" href="mailto:info@garlawabogados.com">info@garlawabogados.com</a>
              <i className="fa fa-phone" ></i><a title = "Teléfono de Contacto" href="tel:621003374">  621 00 33 74</a>
            </div>
          </div>
        </div>

		<header className="header-area header-sticky">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <nav className="main-nav">
                        {/*  ***** Logo Start *****  */}
                        <a href="/" className="logo" title="Garlaw Abogados: Bufete de Abogados en Madrid, Avila y Segovia">
                            <img src="/assets/images/logo_largo.webp" alt = "Garlaw Abogados Logo" id = "logo2"/>
                        </a>
                        {/* ***** Logo End ***** */}
                        {/* ***** Menu Start ***** */}
                        <ul className="nav">
                            <li>
                            <NavLink exact to="/" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = "Inicio -  Conocenos">Inicio </NavLink>
                            </li>
                            <li className="submenu"> 
                            <a href="#">Especialidades</a>
                                <ul> 
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}> 
                                    <NavLink to='/areas-legales/derecho-civil' title = "Area Legal: Derecho Civil">Civil</NavLink>
                                    </li>
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}> 
                                    <NavLink to='/areas-legales/familia-sucesiones' title = "Area Legal: Familia y Sucesiones">Familia - Sucesiones</NavLink >
                                    </li>
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
                                    <NavLink to='/areas-legales/arrendamientos-contratos' title = "Area Legal: Arrendamientos y Contratos">Arrendamientos - Contratos</NavLink >
                                    </li>
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
                                    <NavLink to='/areas-legales/hipotecas' title = "Area Legal: Hipotecas">Hipotecas</NavLink >
                                    </li>
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
                                    <NavLink to='/areas-legales/propiedad-horizontal' title = "Area Legal: Propiedad Horizontal">Propiedad Horizontal</NavLink >
                                    </li>
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
                                    <NavLink   to='/areas-legales/vicios-constructivos' title = "Area Legal: Vicios Constructivos">Vicios Constructivos</NavLink >
                                    </li>
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
                                    <NavLink   to='/areas-legales/derecho-procesal-civil' title = "Area Legal: Derecho Procesal Civil">Derecho Procesal Civil</NavLink>
                                    </li>
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
                                    <NavLink   to='/areas-legales/nuevas-tecnologias' title = "Area Legal: Derecho Nuevas Tecnologías">Nuevas Tecnologías</NavLink >
                                    </li>
                                    <li onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
                                    <NavLink   to='/areas-legales/derecho-tramites-administrativos' title = "Area Legal: Derecho Administrativo">Derecho Administrativo</NavLink >
                                    </li>
                                </ul>
                            </li>
                            <li className = "nav-blog">
                            <NavLink to="/blog" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = "Blog de Garlaw Abogados">Blog</NavLink>
                            </li>
                            <li>
                            <NavLink exact to="/contacto" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = "Contacto">Contacto</NavLink>
                            </li>
                        </ul>        
                        <a href="#" className='menu-trigger' id = 'menu-movil' onClick={function(event){event.preventDefault();}}>
                            <span >Menu </span>
                        </a>
                        {/* ***** Menu End ***** */}
                    </nav>
                </div>
            </div>
        </div>
    </header>
    </div>
  	)
    
  }
 
}
 
export default withRouter(Menu);