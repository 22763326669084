import React  from 'react';
import {NavLink, withRouter}  from "react-router-dom";

import '../componentes.css'; 
 
import AOS from 'aos';
import 'aos/dist/aos.css';
	
import $ from 'jquery'; 
import MetaTags from 'react-meta-tags';
 
class Contratos extends React.Component {

	componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }
	  
  
    
	render() {
		return(

			<>

			<MetaTags>
				<title>Arrendamientos y Contratos | Garlaw Abogados</title>
				<meta name="description" content="Garlaw Abogados resuelve cuántas dudas y problemas surjan en materia contractual, especialmente en arrendamientos. Eres nuestra máxima prioridad." />
				<link rel="canonical" href="https://www.garlawabogados.com/areas-legales/arrendamientos-contratos" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Arrendamientos y Contratos | Garlaw Abogados" />
				<meta property="og:description" content="Garlaw Abogados resuelve cuántas dudas y problemas surjan en materia contractual, especialmente en arrendamientos. Eres nuestra máxima prioridad." />
				<meta property="og:url" content="https://www.garlawabogados.com/areas-legales/arrendamientos-contratos" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Garlaw Abogados resuelve cuántas dudas y problemas surjan en materia contractual, especialmente en arrendamientos. Eres nuestra máxima prioridad." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>


			<div className="parea" data-aos="zoom-in" data-aos-delay="100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 welcomeconoce">
								<div className = "welco-conoce">
									<h1>Arrendamientos</h1>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="areaas_legales">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7" data-aos="zoom-out" data-aos-delay="100">
                        <p>¿Quieres arrendar una vivienda o un local? ¿Te han dejado de pagar la renta? ¿Tu arrendatario ha hecho obras inconsentidas? ¿Y qué pasa si ha cedido el inmueble en subarriendo? Desde Garlaw Abogados creemos que un asesoramiento legal desde el inicio de la relación arrendaticia puede ser la solución a muchos de los problemas que se desencadenan con posterioridad, confía en nosotros y notarás la diferencia.</p>
                        <img className="img-fluid" src="/assets/images/Garlaw_Arrendamientos.webp" width="100%" height="100%" alt="Garlaw Abogados Arrendamientos"/>
                        <span className="caption text-muted">Garlaw Abogados. Arrendamientos</span>
                        <p>Ofrecemos un asesoramiento integral tanto al arrendador como al arrendatario sobre las siguientes materias:</p>
						<ul className="list-bullets">
                        	<li className="mb-lar">Redacción y revisión de contratos de arrendamiento.</li>
							<li className="mb-lar">Desahucios.</li>
							<li className="mb-lar">Resoluciones de Contratos.</li>
							<li className="mb-lar">Impagos.</li>
							<li className="mb-lar">Fianza.</li>
							<li className="mb-lar">Gastos.</li>
							<li className="mb-lar">Cesión y Subarriendo.</li>
							<li className="mb-lar">Mantenimiento del Inmueble.</li>
							<li className="mb-lar">Rentas.</li>
                    	</ul>
                    </div>
                </div>
            </div>
        </article>


        <div className="parea" data-aos="zoom-in" data-aos-delay="100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 welcomeconoce">
								<div className = "welco-conoce">
									<h1>Contratos</h1>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="areaas_legales">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7" data-aos="zoom-out" data-aos-delay="100">
                        <p>Pero no sólo te asesoramos en caso de un contrato de arrendamiento, sino en cualquier otro contrato que regule tus relaciones jurídicas. Por eso si tienes dudas sobre la redacción, interpretación o aplicación de un contrato ponte en contacto con nosotros, te sacamos de dudas. En esta área pretendemos resolver cuántas dudas y problemas surjan en materia contractual, sea el tipo de contrato que sea, buscando siempre el beneficio en nuestro cliente y garantizando su satisfacción.</p>
                        <img className="img-fluid" src="/assets/images/Garlaw_Contratos.webp" width="100%" height="100%" alt="Garlaw Abogados Contratos"/>
                        <span className="caption text-muted">Garlaw Abogados. Contratos</span>
                        <p>Por eso te ofrecemos asesoramiento en:</p>
						<ul className="list-bullets">
                        	<li className="mb-lar">Redacción e Interpretación de Contratos.</li>
							<li className="mb-lar">Incumplimiento de contratos.</li>
							<li className="mb-lar">Arras.</li>
							<li className="mb-lar">Compraventas.</li>
							<li className="mb-lar">Préstamos.</li>
							<li className="mb-lar">Contrato de Obra.</li>
							<li className="mb-lar">Depósitos.</li>
							<li className="mb-lar">Mandato.</li>
							<li className="mb-lar">Mantenimiento del Inmueble.</li>
							<li className="mb-lar">O cualquier otro que necesites que se adapte a tu Problema en Concreto.</li>
                    	</ul>
                    </div>
                </div>
            </div>
        </article>

		<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Tu Abogado a un <span>Paso</span></h2>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="100">
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-map-marker"></i>
							<h3>Visita Nuestras Oficinas</h3>
							<p>Madrid, Segovia, Avila</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-whatsapp"></i>
							<h3>Nuestros Canales Digitales</h3>
							<p>Llamada, Email, Videollamada, Whatsapp</p>
							</div>
						</div>
							<div className="text-center">
							<NavLink to="/contacto" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = "Contacto">
								<button>Contacta</button>
							</NavLink>
							</div>
						</div>
					</div>
    			</section>


		</>
		)
	}
}
export default withRouter(Contratos);