import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Vivienda_Divorcio extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Vivienda Divorcio | Garlaw Abogados</title>
				<meta name="description" content="¿A quién le corresponde el uso de la vivienda familiar tras un divorcio?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-vivienda-divorcio" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Vivienda Divorcio | Garlaw Abogados" />
				<meta property="og:description" content="¿A quién le corresponde el uso de la vivienda familiar tras un divorcio?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-vivienda-divorcio" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿A quién le corresponde el uso de la vivienda familiar tras un divorcio?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿A quién le corresponde el uso de la vivienda familiar tras un divorcio?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Enero 9, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> La vivienda familiar es aquella que se utiliza como domicilio habitual por el matrimonio o pareja de hecho, es decir, en la que han convivido de forma permanente hasta la ruptura, pero ¿a quién le corresponde su uso tras el divorcio?</p>
                        <img className="img-fluid img-post" src="/assets/images/post_vivienda_divorcio.webp" width="100%" height="100%" alt="Post Vivienda Divorcio"/>
                        <span className="caption text-muted">Garlaw Abogados. Vivienda Divorcio</span>
                        <p>
                        La atribución de la misma es una de las cuestiones más controvertidas e importantes en un supuesto de divorcio o ruptura matrimonial. No obstante, es necesario dejar sentado desde este momento, que en ningún momento se está hablando de la propiedad de la vivienda, sino del uso de esta, de manera que con independencia de a quién se atribuya su uso, la titularidad de la propiedad no cambiará, seguirá perteneciendo a ambos cónyuges, a uno solo de ellos, o bien, a un tercero (ej. los padres de uno de los cónyuges).
                        <br/><br/>
                        Una vez aclarado este punto, para poder determinar a quién corresponde su uso, lo primero que hay que tener en cuenta es si hay o no hijos en el matrimonio o pareja.
                        <br/><br/>
                        <span className = "p-bold_su">-	Pareja con hijos menores de edad:</span>
                        <br/><br/>
                        Siempre que no exista un acuerdo entre los cónyuges o pareja, la atribución del uso da la vivienda familiar habiendo hijos menores de edad dependerá del tipo de custodia. 
                        <br/><br/>
                        •	<span className = "p-subr">Custodia exclusiva:</span> En este caso se asignará a los hijos y al progenitor custodio, es decir, el cónyuge en cuya compañía queden, aunque sea propiedad exclusiva del otro progenitor no custodio, o de un tercero, y hasta que estos alcancen la mayoría de edad, ex artículo 96.1 del Código Civil:
                        <br/><br/>
                        <span className = "p-curs">“En defecto de acuerdo de los cónyuges aprobado por la autoridad judicial, el uso de la vivienda familiar y de los objetos de uso ordinario de ella corresponderá a los hijos comunes menores de edad y al cónyuge en cuya compañía queden, hasta que todos aquellos alcancen la mayoría de edad.”
                        </span>
                        <br/><br/>
                        Para el caso de que existan hijos menores en situación de discapacidad el referido artículo continúa diciendo: <span className = "p-curs">“Si entre los hijos menores hubiera alguno en una situación de discapacidad que hiciera conveniente la continuación en el uso de la vivienda familiar después de su mayoría de edad, la autoridad judicial determinará el plazo de duración de ese derecho, en función de las circunstancias concurrentes.”
                        </span>
                        <br/><br/>
                        •	<span className = "p-subr">Custodia compartida:</span> el Código Civil no prevé esta circunstancia, por lo tanto, será el juez quien decida, debiendo ponderar las circunstancias concurrentes en el caso, por aplicación analógica del artículo 96 del Código Civil. A este respecto se ha pronunciado el <span className = "p-bold">Tribunal Supremo, mediante sentencia de 12 de mayo de 2017</span>: <span className = "p-curs">“en el caso de la custodia compartida (…) la norma que debe aplicarse analógicamente es la del párrafo segundo que regula el supuesto en el que, existiendo varios hijos, unos quedan un progenitor, y otros bajo la del otro, y permite al juez resolver lo procedente”
                        </span>
                        <br/><br/>
                        Pero ¿cuáles son las circunstancias que el juez deberá tener en cuenta a la hora de atribuir el uso de la vivienda familiar?
                        <br/><br/>
                        <span className = "p-bold">1-</span>	El interés más necesitado de protección. En este caso primará el interés superior del menor, pero también se tendrá en cuenta la situación de los cónyuges (recursos económicos, si tienen una vivienda privativa…). 
                        <br/><br/>
                        <span className = "p-bold">2-</span>	La propiedad de la vivienda. Si pertenece a ambos o solo a uno de ellos.
                        <br/><br/>
                        <span className = "p-bold">3-</span>	Atendiendo siempre a un límite temporal, el <span className = "p-bold">Tribunal Supremo mediante Sentencia número 593/2014 de 24 de octubre</span>, estableció que el uso de la vivienda familiar no se podía atribuir indefinidamente a los hijos, ni tampoco a uno de los cónyuges, y que habría que establecer un límite temporal. 
                        <br/><br/>
                        Ahora bien, aclarado esto, nos podemos encontrar con diversas modalidades de uso de la vivienda familiar con custodia compartida:
                        <br/><br/>
                        <span className = "p-bold">-</span>	Modelo llamado “casa nido”, donde se atribuye el uso de la vivienda familiar a ambos progenitores por periodos alternos, permaneciendo los hijos siempre en la vivienda. Este modelo suele ser muy problemático, por lo que se suele desaconsejar el mismo.
                        <br/><br/>
                        <span className = "p-bold">-</span>		Atribución temporal a uno solo de los progenitores de la vivienda común: Como ya hemos indicado el Juez deberá ponderar las circunstancias concurrentes en cada caso, y una de ellas, es cuál de los cónyuges se haya más necesitado de protección, pero como decimos con un límite temporal. En este caso el otro progenitor deberá disponer de una vivienda adecuada para cumplir con la custodia compartida.
                        <br/><br/>
                        <span className = "p-bold">-</span>	Atribución temporal a uno solo de los progenitores que no es el propietario de la vivienda: cuando sea el más necesitado de protección.
                        <br/><br/>
                        <span className = "p-bold">-</span>		Atribución al progenitor que es el propietario de la vivienda. Siempre que el no propietario pueda acceder o disponer de una vivienda adecuada.
                        <br/><br/>
                        •	<span className = "p-subr">Custodia partida:</span> No es un caso frecuente, pero existe. Al igual que con la custodia compartida, el Código Civil no prevé esta circunstancia, por lo tanto, será el juez el que decida ponderando las circunstancias, pudiendo acordar un uso rotatorio, la división si fuera posible, la atribución a grupo familiar…
                        <br/><br/>
                        <span className = "p-bold_su">-	Pareja sin hijos:</span>
                        <br/><br/>
                        A falta de acuerdo entre los cónyuges, será el juez quien decida. El mismo de nuevo tendrá en cuenta el interés más necesitado de protección, pudiendo atribuir su uso a uno de los cónyuges, aunque no sea propietario de la misma, pero de nuevo con un límite temporal.  Por tanto, podemos encontrarnos con diversas opciones:
                        <br/><br/>
                        •	<span className = "p-subr">Vivienda cotitularidad de ambos cónyuges:</span> como decimos habrá que estar al interés más necesitado de protección, con limitación en el tiempo. Puede establecerse también un uso alternativo de la vivienda o incluso la no atribución a ninguna de las partes. 
                        <br/><br/>
                        •	<span className = "p-subr">Vivienda de titularidad privada de uno de los cónyuges:</span> puede atribuirse al cónyuge no propietario, si su interés es más necesitado de protección. 
                        <br/><br/>
                        <span className = "p-bold_su">
                        Debemos reseñar que en el caso de que el uso de la vivienda sea atribuida al cónyuge no propietario, la misma no se podrá vender por el cónyuge propietario sin el consentimiento de quien ostenta su uso, a pesar de no ser propietario de la misma, de ahí la importancia de la limitación temporal, y no de no adoptar medidas indefinidas que puedan perjudicar a una de las partes.
                        </span>
                        <br/><br/>
                        Como se puede observar la casuística es muy amplia y habrá que estar a las circunstancias concretas de la pareja o matrimonio. Pero la misma no sólo se termina aquí, sino que también podemos encontrarnos con que la vivienda familiar no sea propiedad de los cónyuges o miembros de la pareja sino de un tercero, o que se encuentren en régimen de arrendamiento, ¿qué pasa en estos casos?
                        <br/><br/>
                        -	<span className = "p-subr">La vivienda pertenezca a un tercero:</span> Un ejemplo típico sería cuando el matrimonio o pareja viven en una vivienda propiedad de los padres de uno de ellos o de algún familiar. En este caso podrá atribuirse el uso al cónyuge que no es familiar de los propietarios. Este hecho puede resultar injusto, pues los padres ceden de forma voluntaria y gratuita  el uso de un inmueble a su hijo y su pareja, y puede ocurrir que se atribuya el uso a quién no es su familiar después de la ruptura, ¿qué pueden hacer en este caso? Instar la oportuna demanda por precario.
                        <br/><br/>
                        -	<span className = "p-subr">La vivienda sea de alquiler:</span> puede atribuirse a sólo uno de los cónyuges, el cual deberá informar al arrendador en el plazo de dos meses desde la notificación de la sentencia, acompañando copia de esta. Aunque en el contrato de arrendamiento figuren ambos cónyuges, siempre aconsejamos que se remita al arrendador con el fin de evitar responsabilidades al cónyuge cuyo uso no le ha sido atribuido. 
                        <br/><br/>
                        A todo lo expuesto, debemos tener en cuenta además otra circunstancia, el hecho de que el cónyuge a quien se le haya atribuido el uso de la vivienda se vuelva a casar o convivir con otra pareja, en este caso el <span className = "p-bold">Tribunal Supremo ha declarado desde su Sentencia de 29 de octubre de 2019 la extinción de este</span>, toda vez que pierde la condición de domicilio familiar:
                        <br/><br/>
                        <span className = "p-curs">
                        “Es un hecho probado que la demandada mantiene una relación afectiva estable con una nueva pareja, que reside en el domicilio que se asignó a la hija menor y a su madre, como custodia al aprobarse el correspondiente regulador.
                        <br/><br/>
                        Esta Sala en sentencia 641/2018, de 20 de noviembre, declaró:
                        <br/><br/>
                        “(i) El derecho de uso de la vivienda familiar existe y deja de existir en función de las circunstancias que concurren en el caso. Se confiere y se mantiene en tanto que conserve este carácter familiar. La vivienda sobre la que se establece el uso no es otra que aquella en que la familia haya convivido como tal, con una voluntad de permanencia (sentencia 726/2013, de 19 de noviembre).
                        <br/><br/>
                        En el presente caso, este carácter ha desaparecido, no porque la madre e hijos hayan dejado de vivir en ella, sino por la entrada de un tercero, dejando de servir a los fines del matrimonio. La introducción de una tercera persona hace perder a la vivienda su antigua naturaleza “por servir en su uso a una familia distinta y diferente”, como dice la sentencia recurrida.
                        <br/><br/>
                        “(ii) La medida no priva a los menores de su derecho a una vivienda, ni cambia la custodia, que se mantiene en favor de su madre. La atribución del uso a los hijos menores y al progenitor custodio se produce para salvaguardar los derechos de aquellos.
                        <br/><br/>
                        Pero más allá de que se les proporcione una vivienda que cubra las necesidades de alojamiento en condiciones de dignidad y decoro, no es posible mantenerlos en el uso de un inmueble que no tiene el carácter de domicilio familiar, puesto que dejó de servir a los fines que determinaron la atribución del uso en el momento de la ruptura matrimonial, más allá del tiempo necesario para liquidar la sociedad legal de gananciales existente entre ambos progenitores.
                        <br/><br/>
                        “El interés de los hijos no puede desvincularse absolutamente del de sus padres, cuando es posible conciliarlos. El interés en abstracto o simplemente especulativo no es suficiente y la misma decisión adoptada en su día por los progenitores para poner fin al matrimonio, la deben tener ahora para actuar en beneficio e interés de sus hijos respecto de la vivienda, una vez que se ha extinguido la medida inicial de uso, y que en el caso se ve favorecida por el carácter ganancial del inmueble y por la posibilidad real de poder seguir ocupándolo si la madre adquiere la mitad o se produce su venta y adquiere otra vivienda”.
                        <br/><br/>
                        En aplicación de esta doctrina, que la sala de apelación no desconocía, debemos declarar que la introducción en la vivienda familiar de un tercero, en una relación afectiva estable, desnaturaliza el carácter de la vivienda, dado que deja de ser familiar, en el sentido de que manteniéndose la menor en la misma, se forma una nueva pareja sentimental entre su madre y un tercero que disfruta de una vivienda que también es propiedad del demandante, que además abona el 50% del préstamo hipotecario.
                        <br/><br/>
                        Por lo expuesto, procede estimar el recurso de casación, en aplicación del art. 96.1 del C. Civil, declarando que la vivienda que fue familiar ha dejado de serlo, por lo que dejamos sin efecto la atribución de la misma a la menor y a la madre que la custodia, las cuales podrán permanecer en la misma por un tiempo prudencial de un año, tras el cual deberán desalojarla.”
                        <br/><br/>
                        </span>
                        Dado la problemática que existe a este respecto, desde Garlaw Abogados siempre recomendamos contar con un buen asesoramiento desde el inicio, abogando siempre por un entendimiento entre las partes. Si tienes más dudas sobre este tema o quieres que analicemos tu caso en concreto, no dudes en ponerte en contacto con nosotros. Te estamos esperando.
                        </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            <li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/Va45W4f54Fk"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7018598409557151745/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1612834581505351684"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Vivienda_Divorcio);