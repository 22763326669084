import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Dinero_Privativo extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?</title>
				<meta name="description" content="¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-dinero-privativo-en-cuenta-ganancial" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?" />
				<meta property="og:description" content="¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-dinero-privativo-en-cuenta-ganancial" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Septiembre 10, 2024 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> En España el régimen económico matrimonial mayoritario sigue siendo el de la sociedad de gananciales. Dicho régimen se rige por una regla principal recogida en el artículo 1.361 del Código Civil, así se dispone que se presumen gananciales los bienes existentes en el matrimonio mientras no se pruebe que pertenecen privativamente a uno de los dos cónyuges. Pero ¿qué pasa con el dinero privativo que se ingresa en una cuenta ganancial? 
                        <br/><br/><br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_dinero_privativo.webp" width="100%" height="100%" alt="¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?"/>
                        <span className="caption text-muted">Garlaw Abogados ¿Qué pasa con el dinero privativo que se ingresa en una cuenta ganancial?</span>
                        <p>
                        El dinero privativo que se ingresa en una cuenta ganancial no se convierte en ganancial, de forma que el progenitor titular del mismo podrá exigir su reembolso. 
                        <br/><br/>
                        En este sentido se ha pronunciado el <span className = "p-bold_su">Tribunal Supremo mediante Sentencia de 25 de abril de 2022</span>, en ella se dice lo siguiente:
                        <br/><br/>
                        <span className = "p-curs">“Es doctrina de esta Sala que los depósitos indistintos no presuponen comunidad de dominio sobre los objetos depositados; por lo que habrá de estarse a las relaciones internas entre los titulares, y más concretamente a la originaria procedencia del dinero que nutre la cuenta para calificar el carácter dominical de los fondos.»
                        <br/><br/>
                        Por consiguiente, no cabe utilizar como argumento para la determinación de la naturaleza jurídica de los fondos controvertidos, el hecho de que fueran transferidos a una cuenta abierta a nombre de ambos esposos. Esta titularidad conjunta constituye, además, un dato que no tiene que conocer el ordenante, que se limita a girar el dinero a la cuenta que se le indica. Es más, lo normal es que desconozca dicha titularidad.
                        <br/><br/>
                        La circunstancia de emplear dinero privativo en la adquisición de un bien al que se atribuye por los cónyuges la condición de ganancial no significa la renuncia al derecho de reembolso, ni implica la donación de su importe por parte del cónyuge titular a su sociedad ganancial.
                        <br/><br/>
                        El art. 1358 del Código civil señala que: «Cuando conforme a este Código los bienes sean privativos o gananciales, con independencia de la procedencia del caudal con que la adquisición se realice, habrá de reembolsarse el valor satisfecho a costa, respectivamente, del caudal común o del propio, mediante el reintegro de su importe actualizado al tiempo de la liquidación».
                        <br/><br/>
                        Son gananciales los bienes adquiridos conjuntamente por los esposos cuando consta la voluntad de ambos de atribuir carácter ganancial al bien adquirido, pero, en tal caso, si se prueba que para la adquisición se han empleado fondos privativos, el cónyuge titular del dinero tiene derecho a que se le reintegre el importe actualizado, aunque no hiciera reserva sobre la procedencia del dinero ni sobre su derecho de reembolso.
                        <br/><br/>
                        <span className = "p-bold">
                        El mero hecho de ingreso de dinero privativo en una cuenta común no lo convierte en ganancial. En consecuencia, si se emplea para hacer frente a necesidades y cargas de la familia o para la adquisición de bienes a los que los cónyuges, de común acuerdo, atribuyen carácter ganancial, surge un derecho de reembolso a favor de su titular, aunque no hiciera reserva de ese derecho en el momento del ingreso del dinero en la cuenta.”
                        </span></span>
                        <br/><br/>
                        Por lo tanto, a falta de prueba, se presume que dicho dinero se gastó en interés familiar y procede el derecho de reembolso, salvo que se pueda probar que se aplicó en beneficio exclusivo del cónyuge titular del mismo. 
                        <br/><br/>
                        El principal problema en estos casos es poder acreditar que se trata de dinero privativo y no ganancial. Por eso es necesario recordar lo que dispone el <span className = "p-bold_su">artículo 1346 del Código Civil</span>, en el que se enumera cuáles son los bienes privativos de cada uno de los cónyuges:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Los bienes, animales y derechos que le pertenecieran al comenzar la sociedad.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>		Los que adquiera después por título gratuito.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>		Los adquiridos a costa o en sustitución de bienes privativos.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>		Los adquiridos por derecho de retracto perteneciente a uno solo de los cónyuges.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>		Los bienes y derechos patrimoniales inherentes a la persona y los no transmisibles ínter vivos.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>		El resarcimiento por daños inferidos a la persona de uno de los cónyuges o a sus bienes privativos.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>		Las ropas y objetos de uso personal que no sean de extraordinario valor.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>		Los instrumentos necesarios para el ejercicio de la profesión u oficio, salvo cuando éstos sean parte integrante o pertenencias de un establecimiento o explotación de carácter común.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>		Los bienes mencionados en los apartados 4 y 8 no perderán su carácter de privativos por el hecho de que su adquisición se haya realizado con fondos comunes; pero, en este caso, la sociedad será acreedora del cónyuge propietario por el valor satisfecho.
                        <br/><br/>
                        Desde Garlaw Abogados siempre aconsejamos que cuando se ingrese en la cuenta común, en el concepto del ingreso se haga costar esta circunstancia, sin perjuicio de la documentación que la acredite, con ello se facilita que pasado los años y ante un divorcio y posterior liquidación de gananciales se pueda probar que se trata de dinero privativo y exigir su reembolso.
                        <br/><br/>
                        Si tienes más dudas sobre este tema o quieres que analicemos tu caso en concreto, no dudes en ponerte en contacto con nosotros. Te estamos esperando.
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/zgqjKU3ilUU"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7239259833076449281/?actorCompanyId=82083167"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/1213383026360856"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://x.com/garlawabogados/status/1833503203557839161"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Dinero_Privativo);