import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Sentencia_Extranjera_Divorcio extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Sentencia Extranjera de Divorcio, ¿es válida en España?</title>
				<meta name="description" content="Sentencia Extranjera de Divorcio, ¿es válida en España?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-sentencia-extranjera-de-divorcio" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Sentencia Extranjera de Divorcio, ¿es válida en España?" />
				<meta property="og:description" content="Sentencia Extranjera de Divorcio, ¿es válida en España?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-sentencia-extranjera-de-divorcio" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Sentencia Extranjera de Divorcio, ¿es válida en España?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Sentencia Extranjera de Divorcio, ¿es válida en España?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Noviembre 10, 2024 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Muchas personas tienen una Sentencia de divorcio dictada en su país de origen y acuden a nuestro despacho preguntando si la misma tiene validez en España o por el contrario es necesario iniciar un procedimiento para reconocer su validez en nuestro país. En este artículo vamos a aclarar que pasos hay que seguir para validar una Sentencia de divorcio en España.
                        <br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_sentencia_extranjera_divorcio.webp" width="100%" height="100%" alt="Sentencia Extranjera de Divorcio, ¿es válida en España?"/>
                        <span className="caption text-muted">Garlaw Abogados. Sentencia Extranjera de Divorcio, ¿es válida en España?</span>
                        <p>
                        Para que una Sentencia de Divorcio extranjera tenga validez en España es necesario realizar un Exequátur.
                        <br/><br/>
                        Pero ¿qué es un Exequátur?
                        <br/><br/>
                        Se trata de un procedimiento judicial mediante el cual se comprueba si la Sentencia extrajera reúne los requisitos para que pueda ser reconocida en España. Por lo tanto, las Sentencias extrajeras no tiene una validez inmediata en nuestro país sino se trámite el correspondiente Exequátur.
                        <br/><br/>
                        Este procedimiento no se trata de un nuevo juicio de divorcio, ni si quiera una revisión del que se hizo en su momento, como decimos, solamente se limita a comprobar que la referida Sentencia cumple con las condiciones necesarias para ser reconocida en España.  
                        <br/><br/>
                        Para tramitar el mismo, será obligatorio valerse de abogado y procurador, además se necesitará los siguientes documentos:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span>	Sentencia original apostillada o legalizada
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span> Declaración de firmeza de la Sentencia
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span> Traducción jurada si está en otro idioma
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span> Certificado de matrimonio
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">-</span> Certificado literal de nacimiento de los hijos
                        <br/><br/>
                        Con respecto al juez competente habrá que estar a lo que disponga el artículo 52.1 de la Ley 29/2015, de 30 de julio, de Cooperación Jurídica Internacional en Materia Civil:
                        <br/><br/><span className = "p-curs">
                        “La competencia para conocer de las solicitudes de exequátur corresponde a los Juzgados de Primera Instancia del domicilio de la parte frente a la que se solicita el reconocimiento o ejecución, o de la persona a quien se refieren los efectos de la resolución judicial extranjera. Subsidiariamente, la competencia territorial se determinará por el lugar de ejecución o por el lugar en el que la resolución deba producir sus efectos, siendo competente, en último caso, el Juzgado de Primera Instancia ante el cual se interponga la demanda de exequátur.”
                        </span><br/><br/>
                        ¿Puede ocurrir que no se reconozca la validez en España de una Sentencia Extrajera de Divorcio?
                        <br/><br/>
                        La respuesta es SÍ, en el caso de que exista hijos menores, por ejemplo, si no se regulan las medidas relativas a los mismos, o no son compatibles con las recogidas por la legislación española, puede determinar que no se reconozca validez al mismo, debiendo iniciarse el procedimiento de divorcio en España si queremos obtener una Sentencia que podamos posteriormente ejecutar en caso de incumplimiento. 
                        <br/><br/>
                        ¿Se puede ejecutar la Sentencia una vez reconocida en España?
                        <br/><br/>
                        La respuesta es SÍ, pero el requisito previo es la tramitación del Exequátur. Una vez reconocida su validez, la misma es plenamente ejecutable en caso de incumplimiento. 
                        <br/><br/>
                        Si tienes más dudas sobre este tema o quieres que analicemos tu caso en concreto, no dudes en ponerte en contacto con nosotros. Te estamos esperando.
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                        <li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/gLFl91fQw_o"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7262032235610972161/?actorCompanyId=82083167"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/602068898915100"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://x.com/garlawabogados/status/1856268149210935721"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Sentencia_Extranjera_Divorcio);