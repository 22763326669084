import React  from 'react';
import {withRouter}  from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Conflicto extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Conflicto Padres | Garlaw Abogados</title>
				<meta name="description" content="Desacuerdo de los padres sobre decisiones en la custodia de sus hijos. Descúbrelo en este post." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-patria-potestad" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Conflicto Padres | Garlaw Abogados" />
				<meta property="og:description" content="Desacuerdo de los padres sobre decisiones en la custodia de sus hijos. Descúbrelo en este post." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-patria-potestad" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Desacuerdo de los padres sobre decisiones en la custodia de sus hijos. Descúbrelo en este post." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Qué ocurre en caso de desacuerdo entre los padres sobre cuestiones importantes en la vida de su hijo menor?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Abril 20, 2021 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Tras la separación o el divorcio, el desacuerdo entre los progenitores en el ejercicio de la patria potestad respecto de los hijos menores de edad viene siendo una constante. Desgraciadamente, en muchas ocasiones, nos encontramos ante progenitores que utilizan como arma arrojadiza contra su ex pareja, la toma de decisiones trascendentales para la vida del menor. Pero ¿qué se puede hacer ante estos casos?</p>
                        <img className="img-fluid img-post" src="/assets/images/post_conflicto.webp" width="100%" height="100%" alt="Post Patria Potestad"/>
                        <span className="caption text-muted">Garlaw Abogados. Patria Potestad</span>
                        <p>
						La separación o el divorcio de una pareja no es una cuestión sencilla, sobre todo cuando existen hijos menores en los que es necesario la toma de decisiones importantes para el devenir presente y futuro de su vida.
						<br/>
						Es en estos casos, es cuando empiezan a aparecer diferencias de criterios entre los progenitores que muchas veces se tornan infranqueables ante las distintas personalidades de los padres.
						<br/>
						Ante estas situaciones, que vienen siendo una constante, la mejor solución sigue siendo la toma de acuerdos entre ellos, el diálogo y una relación cordial como precursor de la salvaguarda de los intereses de sus hijos.
						<br/><br/>
						No debemos olvidarnos del hecho de que, aunque exista una ruptura sentimental de pareja, el devenir de nuestra vida, en cuanto a padres de un hijo menor, va a quedar unido al otro progenitor, y es por ello por lo que la relación debe ser lo más fluida posible.
						<br/>
						Sin embargo, como decimos en la práctica, desgraciadamente, esta no viene siendo la tónica habitual, y nos encontramos ante progenitores que comienzan una guerra cruzada contra el otro, aunque ello haga un flaco favor al interés del menor, que se encuentra en medio de ese fuego cruzado.
						<br/> <br/>
 						¿Qué podemos hacer antes esta situación? Pues bien, la respuesta será distinta dependiendo de la decisión que deba adoptarse respecto de la vida del menor.
						 <br/> <br/>
						Así, habrá decisiones que no dependan en absoluto del consentimiento del otro progenitor, y habrá otras que por su trascendencia lo requiera. Pero ¿Cuáles son las decisiones que requieren el consentimiento del otro progenitor?
						<br/><br/>
						Serán aquellas que revistan una especial relevancia o trascendencia en la esfera personal del menor. Entre ellas podemos mencionar: la elección o cambio de centro escolar, la celebración de determinados actos religiosos, la elección de la religión a profesar, el sometimiento a determinadas terapias o tratamientos médicos o psicológicos, decidir a qué actividades extraescolares asistirá el menor, viajes al extranjero o cambio de domicilio entre otras muchas.
						Todas estas decisiones tienen una gran relevancia para el futuro del menor, lo que implique que se requiera el consentimiento de ambos progenitores.
						<br/>
 						No será necesario, en cambio, dicho consentimiento, cuando se trate de decisiones que afecten a la guardia y custodia del menor del progenitor que lo tenga en cada momento, como pueden ser: la hora de irse a dormir, la ropa, alimentación, los programas de televisión que se le permiten ver, etc.
						 <br/>
						Tampoco requerirán el consentimiento del otro progenitor cuando se trate de decisión de urgencia, como puede ser una intervención quirúrgica o un accidente, como es lógico.
						<br/> <br/>
						Ahora bien, ¿qué ocurre si no existe acuerdo en las decisiones que hemos calificado de transcendentales?
						Pues bien, muchos progenitores agotan todas las vías posibles para intentar llegar a un acuerdo y les resulta imposible.
						<br/>
						Ante esta situación, será necesario solicitar la oportuna autorización judicial.
						<br/> <br/>
						El artículo 156 del Código Civil dice:
						</p>
						<p className = "p-curs">
						"En caso de desacuerdo, cualquiera de los dos podrá acudir al Juez, quien, después de oír a ambos y al hijo si tuviera suficiente madurez y, en todo caso, si fuera mayor de doce años, atribuirá la facultad de decidir al padre o a la madre.”
						</p>
						<p>
						La autorización judicial lo que hará será sustituir el consentimiento del otro progenitor. Ahora bien, ¿cómo obtengo esa autorización?
						</p>
						<p className = "p-cur-os">
						“Ante el desacuerdo de los progenitores sobre cuestiones transcendentales para la vida de su hijo menor, será necesario iniciar un procedimiento de jurisdicción voluntaria para obtener la oportuna autorización judicial al respecto”
						</p>
						<p>
						Para ello deberás iniciar un procedimiento de Jurisdicción voluntaria, conforme a la Ley 15/2015, de 2 de julio. Dicho procedimiento se inicia mediante solicitud al Juzgado que en su caso haya dictado la Sentencia de divorcio o medidas paternofiliales, si aún no hay Sentencia, ante el del domicilio del niño.
						<br/><br/>
						Una vez presentada esta solicitud, y siempre que cumpla los requisitos de admisión, se citará al Ministerio Fiscal y a todos los interesados a una comparecencia. En la misma, deberá exponerse cada postura, debiendo argumentarla y aportar las pruebas de las que intente valerse al respecto. 
						<br/>
						El juez, después de oídas a las partes y al Ministerio Fiscal, dictará auto resolviendo al respecto. (ex artículo 85 y 86 de la Ley de Jurisdicción Voluntaria).
						<br/><br/>
						Tenemos que tener en cuenta, que si el menor es mayor de 12 años será oído y su opinión deberá ser tenida en cuenta al respecto.
						<br/>
						Otra cuestión a tener en cuenta, es que no es necesario la intervención de abogado y procurador para tramitar este procedimiento, de conformidad con lo que establece el artículo 85 de la LJV. Siendo necesario, como ya hemos apuntado, que la solicitud esté argumentada y suficientemente motivada.
						<br/>
						No obstante, aunque no sea preceptiva la intervención de abogado y procurador, es más que recomendable, toda vez que se están tratando cuestiones de vital importancia para la vida del menor, y un mínimo error puede hacer que el Juez no se decante en favor de la decisión que entendemos más beneficiosa para el menor.
						<br/><br/>
						Por otro lado, en caso de que uno de los progenitores decida unilateralmente adoptar una decisión transcendental para el menor, sin consentimiento del otro progenitor y sin solicitar la oportuna autorización judicial, como puede ser el cambio de centro escolar, supondría el incumplimiento de las medidas paternofiliales adoptadas por Sentencia. Para que se entienda, es como si dejáramos de cumplir el régimen de visitas o de pagar la pensión si estamos obligado a ello.
						<br/>
						Las consecuencias dependerán de la decisión adoptada, la más grave, sin duda, sería el cambio de domicilio del menor, que podría llegar a suponer un cambio de la guarda y custodia.
						<br/><br/>
						Es por ello, que siempre se aconseja intentar llegar a un acuerdo en cuanto a las cuestiones del menor se refieren, pues ello redundará en su beneficio. Debemos ser conscientes que los menores no pueden ser utilizados como medios para hacer daño a las ex parejas, pues con ello estaríamos anteponiendo nuestro interés personal al beneficio del menor. 
						<br/><br/>
						Por todo ello, debemos procurar un ambiente de entendimiento no sólo en este ámbito de nuestra vida, sino en cualquiera, pues con ello se ahorrarían no solo numerosos costes económicos, sino de tiempo también, pues el hecho de iniciar un procedimiento judicial supone un enorme retraso en la toma de una decisión que puede ser del todo beneficiosa para el menor.
						</p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>  
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Conflicto);