import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Colegio extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>¿Debe informar el colegio a los padres separados?</title>
				<meta name="description" content="¿Debe informar el colegio a los padres separados?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-padres-separados-colegio" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="¿Debe informar el colegio a los padres separados?" />
				<meta property="og:description" content="¿Debe informar el colegio a los padres separados?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-padres-separados-colegio" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Debe informar el colegio a los padres separados?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Debe informar el colegio a los padres separados?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Julio 10, 2024 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Mucho de nuestros clientes acuden a nuestro despacho poniendo de manifiesto que el Colegio no les informa de las notas de sus hijos, ni les permite establecer tutorías, amparándose en que no ostentan la guarda y custodia del mismo, pero ¿legalmente es esto así? 
                        <br/><br/><br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_colegio.webp" width="100%" height="100%" alt="¿Debe informar el colegio a los padres separados?"/>
                        <span className="caption text-muted">Garlaw Abogados ¿Debe informar el colegio a los padres separados?</span>
                        <p>
                        En primer lugar, es necesario distinguir entre patria potestad y la guardia y custodia de un menor. 
                        <br/><br/>
                        Mientras que la <span className = "p-bold_su">patria potestad </span>abarca los derechos y deberes generales de los padres para con sus hijos, lo que implica que se le atribuya la capacidad para decidir conjuntamente sobre los aspectos más trascendentes de la vida del menor, la <span className = "p-bold_su">guardia y custodia</span> se enfoca en la responsabilidad cotidiana de cuidar a los hijos, en definitiva, su cuidado y convivencia diaria. 
                        <br/><br/>
                        Por lo tanto, el derecho a ser informado por el centro educativo del menor se engloba dentro de la patria potestad y no así de la guarda y custodia, lo que determina que con independiente de quien sea titular de la guardia y custodia de los menores, el centro educativo deberá informarle de la misma manera y permitirle el mismo número de tutorías que al progenitor no custodio. 
                        <br/><br/>
                        Pero  <span className = "p-bold">¿cómo puedo obtener esa información si el centro educativo se niega?</span>
                        <br/><br/>
                        En primer lugar, debe solicitarse dicha información por escrito dirigido al centro acompañando copia de la resolución judicial donde se determine que la patria potestad sigue siendo compartida.  Desde Garlaw Abogados aconsejamos que se realice mediante burofax. 
                        <br/><br/>
                        Si tras dicha solicitud, el centro educativo sigue sin facilitar por duplicado la información y los documentos relativos a la evoluciona académica del menor, deberá interponerse una queja ante la Conserjería de Educación e Inspección Educativa de la Comunidad donde este escolarizado el menor.
                        <br/><br/>
                        En concreto, en la Comunidad Autónoma de Madrid, existe una guía informativa de actuación de los centros educativos ante los progenitores separados/divorciados que comparten patria potestad, en la misma se indica:
                        <br/><br/>
                        <span className = "p-curs"><span className = "p-bold">
                        3. DERECHO DE INFORMACIÓN DE LOS PROGENITORES</span>
                        <br/><br/>
                        En los supuestos de patria potestad compartida ambos progenitores tienen derecho a recibir la misma información sobre las circunstancias que concurran en el proceso educativo del menor lo que obliga a Centro a garantizar la duplicidad de la información relativa al proceso educativo de sus hijos.
                        </span>
                        <br/><br/>
                        Como se puede observar, ambos progenitores ostentan los mismos derechos a ser informados en relación con la evolución académica de sus hijos, con independencia de quien sea el titular de la guarda y custodia del mismo. 
                        <br/><br/>
                        Desde Garlaw Abogados instamos a los padres y centros escolares, a que utilicen la guía para tener claro los derechos y deberes de cada uno de ellos.
                        <br/><br/>
                        Si tienes más dudas sobre este tema o quieres que analicemos tu caso en concreto, no dudes en ponerte en contacto con nosotros. Te estamos esperando.

                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/XBKBqTZu8IQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:activity:7216824445057871872"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados/videos/818317333608775"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://x.com/garlawabogados/status/1811059253186023881"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Colegio);