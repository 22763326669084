import React from 'react';
import {Link, withRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import '../componentes.css'; 



class Footer extends React.Component {

  render() {
 
  	return (
        <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-xs-12">
                    <div className="right-text-content">
                            <ul className="social-icons">
                                <li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
                                <li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
                                <li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
                                <li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>  
                            </ul>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="logo">
                        <a href="/"><img src="/assets/images/logo_pie.png" alt="Garlaw Abogados Logo" id= "logo3"/></a>
                    </div>
                </div>
                <div className="col-lg-4 col-xs-12">
                    <div className="left-text-content">
                    <p>
                        © Copyright 2022<strong> Garlaw Abogados.</strong><br/>
                        Plaza Ribadeo<br/>
                        Madrid, España <br/>
                        <strong>Teléfono: <a title = "Teléfono de Contacto" href="tel:621003374">  621 00 33 74</a></strong><br/>
                        <strong>Email: <a title = "Mail de Contacto" href="mailto:info@garlawabogados.com"> info@garlawabogados.com</a></strong><br/>
                    </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container d-flex justify-content-center">
            <div className="avisolegal">
            <Link to="/avisolegal" title = "Aviso Legal">Aviso Legal </Link>| <Link to="/politicaprivacidad" title = "Política de Privacidad"> Política Privacidad </Link>| <Link to="/cookies" title = "Cookies">  Cookies </Link>
            </div>
        </div>
    </footer>

        )
    
    }
   
  }
 
export default withRouter(Footer);