import React  from 'react';
import {withRouter}  from "react-router-dom";
import '../componentes.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';	
 
class Post_Testamento extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Testamento | Garlaw Abogados</title>
				<meta name="description" content="¿Debo hacer Testamento? Descúbrelo en este post." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-testamento" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Testamento | Garlaw Abogados" />
				<meta property="og:description" content="¿Debo hacer Testamento? Descúbrelo en este post." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-testamento" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Debo hacer Testamento? Descúbrelo en este post." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>Testamento ¿Debo hacerlo?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Enero 11, 2021 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Muchas personas obvian el trámite del testamento, pensando que sus bienes pasaran a sus herederos sin mayor problema. Lo que desconocen por completo, es que fallecer sin que conste testamento alguno, se convierte en un auténtico quebradero de cabeza para sus herederos, invirtiéndose mayor tiempo y dinero en los trámites para resolver la herencia. En este artículo vamos a exponer las múltiples ventajas que tiene hacer testamento, resolviendo dudas y despejando miedos acerca de su realización.</p>
                        <img className="img-fluid img-post" src="/assets/images/post_testamento.webp" width="100%" height="100%" alt="Post Testamento"/>
                        <span className="caption text-muted">Garlaw Abogados. Testamento</span>
                        <p>
						Existen múltiples reticencias entre muchas personas a la hora de proceder a hacer un testamento para el día en que se produzca su fallecimiento. No obstante, desconocen por completo que es un trámite sencillo, que facilitará mucho la resolución de su herencia, llegado el día, y que no hacerlo solo ofrece desventajas al respecto.
						<br/><br/>
						Con el presente artículo pretendemos romper falsos mitos y miedos alrededor de la figura del testamento, mostrando las claras ventajas que el mismo proporciona a un coste muy bajo.
						<br/><br/>
						El testamento no es otra cosa que el acto o instrumento legal por el que una persona manifiesta su voluntad en cuanto al destino de su patrimonio. 
						<br/><br/>
						En aras de proteger dicha voluntad, es por ello por lo que existen una multitud de clases de testamento, pudiendo englobarse en dos categorías: <span className = "p-bold">el testamento común y los especiales</span>. A su vez, el común puede ser ológrafo, abierto o cerrado, y el especial, el militar, el marítimo y el hecho en país extranjero.
						</p>
						<p>
						Respecto del <span className = "p-bold">testamento común</span>, ya hemos dicho que dentro del mismo se incluye:
						<br/><br/>
						<span className = "p-subr">Testamento abierto</span>: que es el más utilizado. Se realiza ante notario, conservando éste el original. No es necesario la presencia de testigos salvo los casos fijados en la Ley. Puede hacerlo toda persona mayor de 14 años y en pleno uso de sus facultades mentales.
						<br/><br/>
						<span className = "p-subr">Testamento cerrado</span>: el testador, sin revelar cuál es su última voluntad, declara que ésta se encuentra contenida en un ‘pliego’ que entrega al Notario. Este puede ser realizado de su puño y letra o mecanografiado. El testamento se introducirá en un sobre o envoltorio de modo que no pueda extraerse del mismo sin romperlo y se depositará ante el Notario que deba autorizarlo.
						<br/><br/>
						<span className = "p-subr">Testamento ológrafo</span>:  es el realizado de puño y letra por le testador, debiendo contener su firma y la fecha en la que se otorga. Sólo podrán otorgar este testamento los mayores de edad. Además, en este caso será necesario que se presente ante el Juzgado de Primera Instancia del domicilio del testador para su convalidación.
						</p>
						<p>
						Respecto de los <span className = "p-bold">testamentos especiales:</span>
						<br/><br/>
						<span className = "p-subr">Testamento militar</span>: se prevé para situaciones de guerra, permitiendo que cualquier militar o personal al servicio del ejército, otorgue testamento ante un oficial que tenga al menos la graduación de Capitán, o ante el capellán o médico que le asista si se encuentra enfermo.
						<br/><br/>
						<span className = "p-subr">Testamento marítimo</span>: es le testamento otorgado por cualquier persona a bordo de un barco durante un viaje por mar, pudiendo ser abierto o cerrado.
						<br/><br/>
						<span className = "p-subr">Testamento realizado en le extranjero</span>: Se puede realizar por los españoles que se encuentren en el extranjero, nos obstante, deberán seguir las normas del país en que se encuentren, pudiendo ser abierto, cerrado u ológrafo.
						</p>
						<p className = "p-cur-os">
						“En caso de morir sin testamento, la herencia se puede convertir en un verdadero quebradero de cabeza para los herederos”
						</p>
						<p>
						A pesar de existir gran variedad de testamentos, lo cierto es que en la practica el más utilizado, como ya hemos dicho es el testamento abierto, dado la facilidad y sencillez del mismo.
						<br/><br/>
						No obstante, y a pesar de ello, muchas personas son reticentes a realizarlo, sin duda por desconocimiento de las ventajas que ello comporta.
						<br/><br/>
						Efectivamente, el hecho de otorgar testamento ofrece numerosas ventajas que deben ser tenidas muy en cuenta:
						<br/><br/>
						<span className = "p-bold">1.</span> Con el otorgamiento del testamento se garantiza que se cumpla la voluntad del testador. 
						<br/><br/>
						<span className = "p-bold">2.</span> Mediante el mismo el causante puede mejorar a uno de los herederos, o disponer de un tercio de mejora a su libre elección.
						<br/><br/>
						<span className = "p-bold">3.</span> Puede designar a un albacea que custodie sus bienes, o vele por el cumplimiento del mismo.
						<br/><br/>
						<span className = "p-bold">4.</span> En caso de morir sin testamento, la herencia se puede convertir en un verdadero quebradero de cabeza para los herederos. Los cuales necesitarán ante la ausencia del mismo, una declaración de herederos abintestato que deberá realizarse ante el Notario o el Juzgado dependiendo quienes sean los herederos del mismo. Dicha declaración no determinará el reparto de los bienes, sino quienes son los herederos de los mismos.
						<br/><br/>
						<span className = "p-bold">5.</span> La ausencia de testamento supone un mayor coste de tiempo y dinero a los herederos. Efectivamente, el importe por otorgar testamento abierto ante Notario oscila entre los 40 y 90 euros, mientras que la ausencia del mismo hace que los costes sen muchísimo más elevados, con el añadido de la gran cantidad de trámites que se deben realizar y con el tiempo que ello conlleva.
						<br/><br/>
						Con ello se evita mayores enfrentamientos entre los herederos a la hora del reparto de los bienes.
						<br/><br/>
						Estas son sólo alguna de las ventajas que ofrece el hecho de otorgar testamento antes del fallecimiento, animando con ello a su realización.
						<br/><br/>
						Entendemos que muchas personas posterguen el otorgamiento del mismo, bien por desconocimiento de como debe realizarse, por una concepción errónea de que su otorgamiento le va a suponer un elevado coste económico, que como ya hemos indicado no es así, o bien por el hecho de que debe plantearse su propio fallecimiento y su desaparición del mundo terrenal. 
						Sea como sea, lo más sensato es realizarlo, pues el fallecimiento es lo único que tenemos seguro a ciencia cierta en esta vida, garantizando con ello menos problemáticas a nuestros herederos.
						</p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>  
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Testamento);