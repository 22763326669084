import React  from 'react';
import {NavLink, withRouter}  from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from 'jquery'; 
import MetaTags from 'react-meta-tags';

import { Icon } from '@iconify/react';

import '../componentes.css'; 

 
 
class Home extends React.Component {
	
	  componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	  
	

	render() {

 
		return(
			/* Welcome area*/
			<>

			<MetaTags>
				<title>Despacho de Abogados Madrid, Avila y Segovia | Garlaw Abogados</title>
    			<meta name="description" content="Despacho de abogados situado en Madrid, Ávila y Segovia. Bufete experto en Derecho civil y Nuevas Tecnologías. Te ponemos las cosas fáciles." />
   				 <link rel="canonical" href="https://www.garlawabogados.com" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Despacho de Abogados Madrid, Avila y Segovia | Garlaw Abogados" />
				<meta property="og:description" content="Despacho de abogados situado en Madrid, Ávila y Segovia. Bufete experto en Derecho civil y Nuevas Tecnologías. Te ponemos las cosas fáciles." />
				<meta property="og:url" content="https://www.garlawabogados.com" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="Despacho de abogados situado en Madrid, Ávila y Segovia. Bufete experto en Derecho civil y Nuevas Tecnologías." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>
				<div className="welcome">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 welcomeconoce">
								<div className = "welco-conoce">
								<p> Tu Despacho de Abogados</p>
										<h1>El Derecho nunca fue tan fácil</h1>
								</div>
								<div className = "welco-video">
									<a title = "Conoce a Garlaw Abogados" id="play-video" className="video-play-button" onClick={function(event){event.preventDefault();
									$('#video-overlay').addClass('open');
									$("#video-overlay").append('<iframe width="560" height="315" alt= "Bienvenidos a Garlaw Abogados" src="https://www.youtube.com/embed/YfCO36wiWak?autoplay=1&feature=oembed&enablejsapi=1"  title="Bienvenidos a Garlaw Abogados" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" frameborder="0" allowfullscreen></iframe>');
									}} href="#" >
										<span></span>
									</a>
								<div id="video-overlay" className="video-overlay" >
									<a className="video-overlay-close" onClick={function(event){
										event.preventDefault();
										$('.video-overlay.open').removeClass('open').find('iframe').remove();
										}} href="#">&times;</a>
								</div>
								</div>
							</div>
							<div className="d-none d-lg-block col-lg-3 welcomeimagenes">
							<img rel="preload" src="/assets/images/Garlaw_Ley.webp" className="img-fluid-1 rounded-circle mb-1" alt="Garlaw Balanza de la Justicia" />
							</div>
							<div className="d-none d-lg-block col-lg-3 welcomeimagenes2">
							<img src="/assets/images/Garlaw_Justicia.webp" className="img-fluid-2 rounded-circle mb-2" alt="Garlaw Diosa de la Justicia" />
							<img src="/assets/images/Garlaw_Digital.webp" className="img-fluid-3 rounded-circle mb-3" alt="Garlaw Abogado Digital" />
							</div>
						</div>
					</div>		
				</div> 
				
				<section id="services" className="services">
					<div className="container" data-aos="fade-up">

						<div className="section-title">
						<h2>Areas Legales</h2>
						<h3>Especialistas en tus <span>Problemas</span></h3>
						</div>

						<div className="row">
						<div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink to="/areas-legales/derecho-civil" title= 'Area Legal: Derecho Civil'>
							<div className="icon-box">
							<div className="icon"><Icon icon="mdi:account"/></div>
							<h4>Civil</h4>
							<p>Reclamaciones de Cantidad, Medidas de Apoyo a la personas con Discapacidad, División de la cosa común</p>
							</div>
							</NavLink>
						</div>
						

						<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink  to='/areas-legales/familia-sucesiones' title= 'Area Legal: Familia y Sucesiones'>
							<div className="icon-box">
							<div className="icon"><Icon icon="mdi:human-queue"/></div>
							<h4>Familia y Sucesiones</h4>
							<p>Separaciones, Divorcios, Liquidación de gananciales, Adopción y Modificación de medidas, Herencias</p>
							</div>
							</NavLink>
						</div>

						<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink  to='/areas-legales/arrendamientos-contratos' title= 'Area Legal: Arrendamientos y Contratos'>
							<div className="icon-box">
							<div className="icon"><Icon icon="mdi:file-document-edit-outline"/></div>
							<h4>Arrendamientos y Contratos</h4>
							<p>Desahucios, Incumplimientos contractuales, Redacción de contratos, Compraventa</p>
							</div>
							</NavLink>
						</div>

						<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink  to='/areas-legales/hipotecas' title= 'Area Legal: Hipotecas'>
							<div className="icon-box">
							<div className="icon"><Icon icon="mdi:account-cash-outline"/></div>
							<h4>Hipotecas</h4>
							<p>Cláusula suelo, Gastos de Hipoteca, IRPH, Derechos del Consumidor</p>
							</div>
							</NavLink>
						</div>

						<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink  to='/areas-legales/propiedad-horizontal' title= 'Area Legal: Propiedad Horizontal'>
							<div className="icon-box">
							<div className="icon"><Icon icon="mdi:warehouse"/></div>
							<h4>Propiedad Horizontal</h4>
							<p>Reclamación de cuotas, Impugnación de acuerdos, Asesoramiento Integral a Administradores de Fincas</p>
							</div>
							</NavLink>
						</div>

						<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink  to='/areas-legales/vicios-constructivos' title= 'Area Legal: Vicios Constructivos'>
							<div className="icon-box">
							<div className="icon"><Icon icon="mdi:account-hard-hat"/></div>
							<h4>Vicios Constructivos</h4>
							<p>Calificación de los vicios, Responsabilidad de los Agentes Constructivos, Reclamación</p>
							</div>
							</NavLink>
						</div>

						<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink  to='/areas-legales/derecho-procesal-civil' title= 'Area Legal: Derecho Procesal Civil'>
							<div className="icon-box">
							<div className="icon"><Icon icon="simple-icons:bookstack"/></div>
							<h4>Derecho Procesal Civil</h4>
							<p>Fase extrajudicial, Demandas, Contestaciones, Recursos, Ejecuciones Sentencias</p>
							</div>
							</NavLink>
						</div>


						<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink  to='/areas-legales/nuevas-tecnologias' title= 'Area Legal: Derecho de Nuevas Tecnologías'>
							<div className="icon-box">
							<div className="icon"><Icon icon="mdi:cellphone-link"/></div>
							<h4>Nuevas Tecnologías</h4>
							<p>Testamento Digital, Smart Contrats, Derecho al Olvido, Comercio Electrónico, Protección de Datos</p>
							</div>
							</NavLink>
						</div>

						<div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300" onClick={function(event){
                                    event.preventDefault();
									$('.submenu a').addClass('active');
									}}>
							<NavLink  to='/areas-legales/derecho-tramites-administrativos' title= 'Area Legal: Derecho y Trámites Administrativos'>
							<div className="icon-box">
							<div className="icon"><Icon icon="maki:town-hall"/></div>
							<h4 className ="h4_largo">Derecho y Trámites Administrativos</h4>
							<p>Impuesto de Sucesiones y Donaciones, Plusvalía, IBI, Registro de la Propiedad</p>
							</div>
							</NavLink>
						</div>


						</div>

					</div>
				</section>

				<section id="about" className="about section-bg">
					<div className="container" data-aos="fade-up">

						<div className="section-title">
						<h2>Equipo</h2>
						<h3>Conoce a <span>Nuestros Profesionales</span></h3>	
						</div>

						<div className="row">
						<div className="col-lg-6 socia" data-aos="zoom-out" data-aos-delay="100">
							<img src="/assets/images/socia_fundadora.webp"  width="100%" height="100%" className="img-fluid" alt="Socia Fundadora Garlaw Abogados"/>
							<p className="p_foto">Sandra García García - Socia Fundadora Garlaw Abogados</p>
						</div>
						<div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
							<h3>GARLAW ABOGADOS es un despacho de abogados joven, emprendedor, pragmático, digital e innovador.</h3>
							<p className="font-italic">
							Nuestro principal desafío en solventar cuantos conflictos surjan de una manera rápida, sencilla y con la ayuda que nos proporcionan las nuevas tecnologías que existen a nuestro alcance. En un proceso de transformación digital, como el que nos encontramos, se requieren nuevas formas de pensar para resolver los nuevos conflictos actuales. 
							</p>
							<ul>
							<li>
								<i className="fa fa-handshake-o" aria-hidden="true"></i>
								<div>
								<h4>Somos Comprometidos</h4>
								</div>
							</li>
							<li>
								<i className="fa fa-laptop" aria-hidden="true"></i>
								<div>
								<h4>Somos Digitales</h4>
								</div>
							</li>
							<li>
								<i className="fa fa-leaf" aria-hidden="true"></i>
								<div>
								<h4>Somos Sostenibles</h4>
								</div>
							</li>
							</ul>
							<p>
							Por eso, desde GARLAW ABOGADOS te ofrecemos un asesoramiento especializado, poniendo a tu alcance las herramientas tecnológicas necesarias para que la solución a tus problemas sea lo más fácil posible, a un solo click, cómodo, rápido y sostenible.
							</p>
						</div>
						</div>

					</div>
    			</section>
				<section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title">
						<h2>Contacto</h2>
						<h3>Tu Abogado a un <span>Paso</span></h3>
						</div>

						<div className="row" data-aos="fade-up" data-aos-delay="100">
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-map-marker"></i>
							<h3>Visita Nuestras Oficinas</h3>
							<p>Madrid, Segovia, Avila</p>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="info-box mb-4">
							<i className="fa fa-whatsapp"></i>
							<h3>Nuestros Canales Digitales</h3>
							<p>Llamada, Email, Videollamada, Whatsapp</p>
							</div>
						</div>
							<div className="text-center">
							<NavLink to="/contacto" onClick={function(event){
									$('.submenu a').removeClass('active');
									}} title = 'Contacto'>
								<button>Contacta</button>
							</NavLink>
							</div>
						</div>
					</div>
    			</section>

			</>
		)
 
	}
 
}
export default withRouter(Home);