import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Cargo_Tarjeta extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>¿Cargos en tu tarjeta que no has autorizado?</title>
				<meta name="description" content="¿Cargos en tu tarjeta que no has autorizado?" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-cargo-tarjeta-no-autorizado" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="¿Cargos en tu tarjeta que no has autorizado?" />
				<meta property="og:description" content="¿Cargos en tu tarjeta que no has autorizado?" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-cargo-tarjeta-no-autorizado" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Cargos en tu tarjeta que no has autorizado?" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Cargos en tu tarjeta que no has autorizado?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Diciembre 12, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> El Banco de España ha revelado un aumento exponencial desde el año 2019 en las tasas de fraude de las operaciones realizadas con tarjeta de crédito o débito. Es bastante frecuente que se produzcan cargos indebidos y no autorizados en la cuenta bancaria a través de nuestra tarjeta de crédito. Por eso, si has sido víctima de un suceso como este, te explicamos que debes saber y hacer al respecto. 
                        <br/><br/><br/><br/></p>
                        <img className="img-fluid img-post" src="/assets/images/post_cargo_tarjeta.webp" width="100%" height="100%" alt="¿Cargos en tu tarjeta que no has autorizado?"/>
                        <span className="caption text-muted">Garlaw Abogados. ¿Cargos en tu tarjeta que no has autorizado?</span>
                        <p>
                        Son cada día más las personas afectadas por esta situación, vinculada sobre todo al aumento del comercio electrónico. No obstante, existen distintos tipos de ataques bancarios:
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">1-</span>	Clonación en comercio físico: ese supuesto suele ser residual, y el menos frecuente pero no debe descartarse.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">2-</span>	Páginas falsas o que son víctimas de un ataque: de manera que al ingresar mi tarjeta de crédito para pagar se quedan con los datos de la misma, y nuestros datos personales.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">3-</span>	Robo NFC: se produce cuando acercan la terminal de pago a nuestra tarjeta sin nosotros saberlo. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">4-</span>	Robo de la tarjeta o la pérdida de la misma.
                        <br/><br/>
                        Pero ¿qué debemos hacer si tenemos un cargo en nuestra tarjeta que no hemos autorizado?
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">1-</span>	<span className = "p-bold_su">Bloquear la tarjeta:</span> lo primero de todo es bloquearla ya sea mediante la aplicación web o móvil o bien llamando al número de teléfono que toda entidad bancaria facilita al efecto.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">2-</span>	<span className = "p-bold_su">Investigar donde puede estar el origen:</span> aunque es muy difícil, intentar hacer una recopilación de los últimos pagos, de las páginas web donde se han realizado para analizar dónde puede estar el origen de los cobros indebidos.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">3-</span>	<span className = "p-bold_su">Guardar los recibos:</span> hacer capturas de pantalla de la aplicación web o móvil, a fin de determinar que cuantía es la sustraída y poder probarlo documentalmente.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">4-</span>	<span className = "p-bold_su">Interponer una denuncia:</span> este requisito es previo y esencial para poder exigir el reintegro al banco. 
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;&nbsp;<span className = "p-bold">5-</span>	<span className = "p-bold_su">Reclamar al banco:</span> para ello deberemos aportar la denuncia y todos los recibos o capturas que tengamos. Una vez interpuesta la reclamación, el banco deberá devolver al usuario todas las cantidades de dinero que superen los 50 euros. 
                        <br/><br/>
                        No obstante, en algunas ocasiones el banco se niega a devolver las cantidades, alegando que el usuario ha actuado con grave negligencia, en este caso es necesario interponer una reclamación ante el servicio de atención de la entidad bancaria o ante el defensor al cliente de la entidad. Y si no existe respuesta o la misma sigue siendo en el mismo sentido de no devolver las cantidades de dinero, deberá reclamarse judicialmente. La jurisprudencia viene estableciendo, que salvo que la entidad acredite claramente la negligencia grave por parte del usuario, deberá siempre responder del reintegro de las cantidades obtenidas fraudulentamente, ya que se trata de una responsabilidad de naturaleza cuasi-objetiva.
                        <br/><br/>
                        Desde Garlaw Abogados siempre recomendamos estar pendientes de los cargos que se hacen en nuestras cuentas bancarias, ser muy precavidos en ingresar los datos de nuestra tarjeta en las páginas web que veamos que no sean de fiar, y sobre todo actuar rápido cuando veamos cargos indebidos en nuestra cuenta.  Si tienes más dudas sobre este tema no dudes en ponerte en contacto con nosotros, estaremos encantados de resolverlas.  
                       </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Cargo_Tarjeta);