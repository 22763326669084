import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Horizontal extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Propiedad Horizontal | Garlaw Abogados</title>
				<meta name="description" content="¿Pueden las Comunidades de Propietarios prohibir las viviendas de alquiler vacacional? Descúbrelo en este post." />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-propiedad-horizontal" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Propiedad Horizontal | Garlaw Abogados" />
				<meta property="og:description" content="¿Pueden las Comunidades de Propietarios prohibir las viviendas de alquiler vacacional? Descúbrelo en este post." />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-propiedad-horizontal" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="¿Pueden las Comunidades de Propietarios prohibir las viviendas de alquiler vacacional? Descúbrelo en este post." />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>¿Pueden las Comunidades de Propietarios prohibir el alquiler vacacional en base a la nueva redacción del artículo 17.12 de la Ley de Propiedad Horizontal?</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Julio 20, 2021 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> Tras la reforma de la Ley de Propiedad horizontal por el Real Decreto-ley 7/2019, del 6 de marzo, se modifica el artículo 17 y se crea el apartado 12, en virtud del cual se establece que lo acuerdos de las Juntas de Propietarios que limiten o condicionen el llamado alquiler vacacional, requerirán el voto favorable de las tres quintas partes del total de lo propietarios que, a su vez, representen las tres quintas partes de las cuotas de participación.</p>
                        <img className="img-fluid img-post" src="/assets/images/post_phorizontal.webp" width="100%" height="100%" alt="Post Propiedad Horizontal"/>
                        <span className="caption text-muted">Garlaw Abogados. Propiedad Horizontal</span>
                        <p>Las viviendas de alquiler vacacional se han convertido en un fenómeno imparable en los últimos años, en las que las Comunidades de propietarios no han sido ajenas a ello, sino más bien, han resultado especialmente afectadas por esta nueva realidad impulsada por la economía colaborativa y el cambio en el comportamiento de los consumidores.
							<br/>
							Son numerosas las problemáticas que a raíz de esto se vienen continuamente planteando en el seno de las Comunidades de Propietarios, siendo la última de ellas la modificación introducida en la Ley de Propiedad horizontal por el Real Decreto-ley 7/2019, de 6 marzo. Dicho decreto modifica el artículo 17, e introduce el siguiente apartado 12:
						</p>
						<p className = "p-curs">
						“El acuerdo por el que se limite o condicione el ejercicio de la actividad a que se refiere la letra e) del artículo 5 de la Ley 29/1994, de 24 de noviembre, de Arrendamientos Urbanos, en los términos establecidos en la normativa sectorial turística, suponga o no modificación del título constitutivo o de los estatutos, requerirá el voto favorable de las tres quintas partes del total de los propietarios que, a su vez, representen las tres quintas partes de las cuotas de participación. Asimismo, esta misma mayoría se requerirá para el acuerdo por el que se establezcan cuotas especiales de gastos o un incremento en la participación de los gastos comunes de la vivienda donde se realice dicha actividad, siempre que estas modificaciones no supongan un incremento superior al 20%. Estos acuerdos no tendrán efectos retroactivos.”
						</p>
						<p>
						Como se puede observar, dicho artículo establece únicamente la mayoría de las tres quintas partes para adoptar acuerdos que limiten o condicionen el ejercicio de la actividad vacacional, pero, ¿puede entenderse dentro de dicha mayoría los acuerdos que prohíban la misma?, o, ¿por el contrario se requiere la unanimidad de los propietarios?
						<br/><br/>
						Esta cuestión ha dividido a nuestra doctrina, existiendo  dos posiciones claramente diferenciadas y motivadas.
						<br/><br/>
						1º- Los que sostienen que el apartado 12 del artículo 17, únicamente se refieren a los acuerdos que limitan o condicionan el ejercicio del alquiler vacacional pero no los que los prohíben, los cuales requerirán la unanimidad de conformidad con el artículo 17.6 de la LPH.
						Esta postura se basa en el propio tenor literal del apartado 12, el cual utiliza únicamente las palabras limitar o condicionar, pero no prohibir, de manera que no podrá incluirse la misma.
						<br/>
						Interpretar la palabra limitación cómo una prohibición absoluta vulneraría las facultades dominicales derivadas del derecho del titular de la vivienda, pudiendo considerarse un abuso de derecho por parte de la Comunidad.
						Esta postura parte de la posición doctrinal mantenida por la Sala de lo Civil del Tribunal Supremo en materia de propiedad horizontal. Dicho cuerpo doctrinal considera posible y aceptable establecer limitaciones o prohibiciones a la propiedad, siempre y cuando atienda a la protección del interés general de la Comunidad, y dicha prohibición o limitación conste de forma expresa, clara y concisa, abogando por una interpretación restrictiva (STS 728/2011, 24 de octubre; 145/2013, 4 de marzo; y 419/2013, 25 de junio)
						<br/>
						De manera que a tenor de lo que sostiene el alto Tribunal, esta postura mantiene que si toda prohibición o limitación a la propiedad debe ser expresa, no puede interpretarse de forma extensiva el apartado 12 del artículo 17, pues si el legislador hubiera querido introducir en el mismo la posibilidad de la prohibición, lo habría hecho. 
						<br/>
						En esta misma línea se ha pronunciado la Dirección General de los Registros y del Notariado mediante Resolución de 19 de diciembre de 2019. En la misma se niega la inscripción de un acuerdo comunitario adoptado por la mayoría de las tres quintas partes que prevé el artículo 17.12 por el que se autoriza el alquiler vacacional en el edificio. Dicha resolución lo fundamenta sobre el tenor literal del referido artículo, el cual exige la mayoría de las tres quintas partes únicamente para limitar o condicionar el ejercicio del alquiler vacacional, pero no para autorizarlo o prohibirlo, lo cual requerirá la necesaria unanimidad de conformidad con lo que establece el artículo 17.6 de la LPH.
						<br/>
						Esta es la posición mayoritaria de la doctrina, la cual considera que será necesario la unanimidad para prohibir el alquiler vacacional, y la mayoría de las tres quintas partes para limitarlo o condicionarlo, siendo ambas en todo caso fundamentadas y motivadas.					
						</p>
						<p className = "p-cur-os">
						“Los acuerdos de las Juntas de propietarios que limiten o condiciones el ejercicio del alquiler vacacional en la Comunidad, requerirá, el voto favorable de las tres quintas partes de los propietarios, que, a su vez, representen las tres quintas partes de las cuotas de participación”
						</p>
						<p>
						2º- Por otro lado, y a sensu contrario, nos encontramos la posición doctrinal que mantiene que por la mayoría de las tres quintas partes que establece el artículo 17.12 puede prohibirse el alquiler vacacional.
						<br/>
						Dicha posición se fundamenta en el poder omnímodo que atribuye el referido artículo a las juntas de propietarios, toda vez, que se les permite también a través del mismo, establecer cuotas especiales o más gastos comunes a cargo de las viviendas arrendadas para fines turísticos, todo ello sin necesidad de fundamentación ya que la norma no lo exige.
						<br/>
						Además, consideran que la prohibición no es sino la más absoluta limitación, de manera que tendría cabida en el tenor literal del precepto. Esta corriente también se sustenta en la doctrina anteriormente referida del Tribunal Supremo, pero en este caso, ponen el foco en que el Alto Tribunal permite establecer limitaciones o prohibiciones. 
						En esta línea se ha pronunciado la Sala de lo Civil del Tribunal Superior de Cataluña (STSJ 33/2016, de 19 de mayo; 74/2018, de 13 de septiembre).
						<br/>
						Podemos mencionar como defensores de esta postura a D. Eduardo Salinas Verdeguer, Magistrado del Tribunal Superior de Justicia de Castilla la Mancha, y a D. Salvador Vilata Mendas, Magistrado del Juzgado nº1 de lo Mercantil de Valencia. Este último sostiene literalmente: 
						</p>
						<p className = "p-curs">
						“Sentado lo anterior, la norma alude a la posibilidad de establecimiento de limitaciones o condicionantes para tal actividad en un inmueble privativo de la comunidad. No se alude, al menos expresamente, a la prohibición. Pero tampoco parece que la norma excluya este último escenario. Ahora bien, con ello se habilita que el propietario afectado por un acuerdo (no causalizado) de prohibición absoluta, pudiere impugnar judicialmente el acuerdo por la vía del art.18.1 letra c) LPH ”.
						</p>
						<p>
						Esto es, por tanto, a grandes trazos, las dos principales posturas al respecto. 	
						</p>
						<p>
						Desde <span className = "p-bold">Garlaw Abogados</span>, como abogados especialistas en propiedad horizontal, consideramos que la prohibición del alquiler vacacional dentro de la Comunidades de Propietarios seguirá requiriendo la unanimidad de los propietarios en consonancia con  lo que establece el artículo 17.6 de la LPH, limitándose la mayoría de las tres quintas partes para aquellos acuerdos en los que se limite o condicione el ejercicio de dicha actividad (ex art.17.12 LPH).
						<br/>
						Entendemos que esta es la postura más correcta, toda vez, que admitir lo contrario implicaría dotar a las Comunidades de propietarios de un poder absoluto que entraría en contradicción con el derecho a la propiedad privada del artículo 33 de la Constitución, ya que supondría limitar las más amplias facultades dominicales a través de una interpretación extensiva del artículo 17.12 de la LPH.
						</p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
												<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://www.youtube.com/channel/UC4hWA6uK5ZqexOx4L3Yf9SQ"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/company/garlaw-abogados"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Horizontal);