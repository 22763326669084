import React  from 'react';
import {withRouter}  from "react-router-dom";
import AOS from 'aos';
import { Icon } from '@iconify/react';
import MetaTags from 'react-meta-tags';
	
class Post_Incumplimiento_Visitas extends React.Component {

    componentDidMount(){
		AOS.init({
		  duration : 2000
		})
	  }

	render() {
		return(

			<>

			<MetaTags>
				<title>Post Incumplimiento del Régimen de Visitas | Garlaw Abogados</title>
				<meta name="description" content="El Incumplimiento del Régimen de Visitas" />
				<link rel="canonical" href="https://www.garlawabogados.com/blog/post-incumplimiento-regimen-visitas" />
				<meta property="og:locale" content="es_ES" />
				<meta property="og:site_name" content="Garlaw Abogados" />
				<meta property="og:type" content="article" />
				<meta property='article:author' content='Sandra García' />
				<meta property="og:title" content="Post Incumplimiento del Régimen de Visitas | Garlaw Abogados" />
				<meta property="og:description" content="El Incumplimiento del Régimen de Visitas" />
				<meta property="og:url" content="https://www.garlawabogados.com/blog/post-incumplimiento-regimen-visitas" />
				<meta property="og:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:secure_url" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="og:image:width" content="500" />
				<meta property="og:image:height" content="255" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Garlaw Abogados" />
				<meta name="twitter:description" content="El Incumplimiento del Régimen de Visitas" />
				<meta name="twitter:image" content="https://www.garlawabogados.com/logo512.png" />
				<meta property="fb:app_id" content="722253125650848"/>
			</MetaTags>

			<div className="parea-blog">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 blog-post">
								<div className = "post">
									<h1>El Incumplimiento del Régimen de Visitas</h1>
                                    <span className="author mr-2"><img src="/assets/images/SGG.webp" width="100%" height="100%" alt="Sandra García"/> Sandra García</span>
                                    <Icon icon="fa-regular:calendar-alt" height="14" hFlip={true}/>
                                    <span className="mr-2">Febrero 27, 2023 </span>
								</div>
							</div>
						</div>
					</div>		
				</div> 

			<article id="post-area" data-aos="fade-up">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-12 col-lg-12 col-xl-12">
                        <p className="intro-post"> En el Convenio Regulador o Sentencia es donde se fija los efectos de la disolución del matrimonio, donde se establecerá el régimen de guarda y custodia de los hijos, y el régimen de comunicación y estancias con el progenitor no custodio. Pero ¿qué ocurre si se incumplen ese régimen de visitas por el progenitor no custodio?</p>
                        <img className="img-fluid img-post" src="/assets/images/post_incumplimiento_visitas.webp" width="100%" height="100%" alt="Post Incumplimiento del Régimen de Visitas"/>
                        <span className="caption text-muted">Garlaw Abogados. Incumplimiento del Régimen de Visitas</span>
                        <p>
                        Pues bien, en primer lugar, será necesario determinar si dicho incumplimiento es <span className = "p-bold">puntual o reiterado</span>. Por incumplimiento puntual, nos referimos a enfermedad del progenitor no custodio, o problemas un día determinado de recoger al menor por temas de trabajo. Incluso podemos incluir en incumplimiento puntual, una imposibilidad que se alargue en varios meses, como puede ser que, por temas de trabajo, el progenitor deba viajar al extranjero y estar allí, por ejemplo, tres meses. En estos casos, siempre abogamos por un entendimiento entre los progenitores, además y en aras de la garantía de todos los derechos de las partes implicadas, aconsejamos que se redacte un documento privado firmado por ambas partes en el que se recoja los términos en que se va a cumplir el régimen de visitas durante este tiempo, pudiendo incluso realizar el oportuno acta notarial en el que se deje constancia de este acuerdo de voluntades. 
                        <br/><br/>
                        El problema existe cuando dicho incumplimiento es reiterado, y no existe ningún entendimiento entre las partes, en este caso aconsejamos que se requiera al progenitor no custodio, preferiblemente por vía burofax o email certificado, al cumplimiento del convenio o sentencia. No obstante, si después de estos requerimientos la situación no mejora, <span className = "p-bold_su">deberemos interponer una demanda de ejecución del convenio o sentencia, en la que el juez exija al infractor que obedezca con la resolución dictada, pudiendo exigir que se le aperciba al progenitor de incurrir en un delito de desobediencia a la autoridad judicial en caso de un nuevo incumplimiento. 
                        </span>
                        <br/><br/>
                        Si persiste la voluntad incumplidora, el juez podrá imponer al progenitor no custodio, multas coercitivas de conformidad con los artículos 709 y 776.2 de la Ley de Enjuiciamiento Civil. Así, podemos mencionar entre otros, <span className = "p-bold_su">el Auto de la Audiencia Provincial de Madrid 6981/2020 de 17 de julio de 2020, en el que impone una multa de 500€ si se incumple el periodo vacacional, o de 100€ por cada fin de semana. </span>
                        <br/><br/>
                        No obstante, en los supuestos más extremos de incumplimiento, <span className = "p-bold_su">se puede interponer una demanda de modificación de medidas relativas al régimen de visitas para suprimir aquellas que no se cumplen. O incluso para modificar la guardia y custodia ante una voluntad obstruccionista del progenitor custodio. 
                        </span>
                        <br/><br/>
                        No obstante, muchos de nuestros clientes nos platean la siguiente pregunta ¿se considera que existe un incumplimiento por el progenitor custodio cuando es el hijo quien no quiere ir a las visitas?
                        <br/><br/>
                        La respuesta es SI, el artículo 154 Código Civil establece que los padres y madres en ejercicio de su patria potestad tienen el derecho y el deber de relacionarse con sus hijos. Bajo esta premisa la jurisprudencia ha venido estableciendo que no es aceptable dejar en manos de los menores la decisión de cuando deben relacionar con sus progenitores. En consecuencia, se considera que existe un incumplimiento por parte del progenitor custodio. <span className = "p-bold_su">Además, debemos poner de manifiesto que si nos encontramos antes un caso grave de privación de las relaciones paternofiliales podremos exigir daños morales en base al artículo 1902 del Código Civil, y ello admitido en la Sentencia del Tribunal Supremo 512/2009 de 30 de junio.
                        </span>
                        <br/><br/>
                        Desde Garlaw Abogados siempre apostamos por un entendimiento entre las partes, pues no sólo supone menos costes económicos, sino emocionales. Si todavía te quedan dudas o requieres de un asesoramiento especializado, ponte en contacto con nosotros. 
                        <br/><br/>
                        Garlaw Abogados te pone las cosas fáciles. Te estamos esperando.
                        </p>
                    </div>
                </div>
            </div>
        </article>

        <section id="contact" className="contact">
					<div className="container" data-aos="fade-up">

						<div className="section-title-2">
						<h2>Comparte y comenta el articulo en nuestras redes sociales</h2>
						</div>
                        <div className="center-text-content">
						    <ul className="social-icons">
                            					<li><a title = "Garlaw Abogados Youtube" className= "youtube" href="https://youtu.be/Qeitte8uc0U"><i className="fa fa-youtube-play"></i></a></li>
												<li><a title = "Garlaw Abogados Linkedin" className= "linkedin" href="https://www.linkedin.com/feed/update/urn:li:ugcPost:7036287952091504640/"><i className="fa fa-linkedin"></i></a></li>
												<li><a title = "Garlaw Abogados Facebook" className= "facebook" href="https://www.facebook.com/GarlawAbogados"><i className="fa fa-facebook"></i></a></li>
												<li><a title = "Garlaw Abogados Twitter" className= "twitter" href="https://twitter.com/garlawabogados/status/1630525311287799809"><i className="fa-brands fa-x-twitter"></i></a></li>    
						    </ul>
                        </div>
					</div>
    			</section>

		</>
		)
	}
}
export default withRouter(Post_Incumplimiento_Visitas);